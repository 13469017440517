import type { BaseItem } from '../shared/types';

import { insertAtIndex, removeAtIndex } from './arrayUtils';

export const moveBetweenContainers = <T extends BaseItem>({
  containers,
  activeContainer,
  activeIndex,
  overContainer,
  overIndex,
  item,
}: {
  containers: Record<string, T[]>;
  activeContainer: BaseItem;
  activeIndex: number;
  overContainer: BaseItem;
  overIndex: number;
  item: T;
}): Record<string, T[]> => {
  return activeContainer
    ? {
        ...containers,
        [activeContainer]: removeAtIndex(containers[activeContainer], activeIndex),
        [overContainer]: insertAtIndex(containers[overContainer], overIndex, item),
      }
    : {
        ...containers,
        [overContainer]: insertAtIndex(containers[overContainer], overIndex, item),
      };
};
