import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { FetchFormsResponse } from '../queries/forms/forms.types';

import { QueryKey } from '../constants';
import { fetchForms } from '../queries/forms/forms.api';

type UseFormsParams = Readonly<{
  formType?: string;
  id?: string;
  enabled?: boolean;
  next?: boolean;
  env?: string;
}>;

export type UseForms = Readonly<{
  data?: FetchFormsResponse;
  isFetching: boolean;
  error?: Error | null;
}>;

export const useForms = ({ formType, id, enabled, next, env }: UseFormsParams): UseForms => {
  const queryFn = async (): Promise<FetchFormsResponse> => {
    return await fetchForms({ formType, id, next, limit: 10, nextLimit: 5, env });
  };

  const { data, isFetching, error } = useQuery<FetchFormsResponse, Error>({
    queryKey: [QueryKey.FETCH_FORMS, { formType, id }],
    queryFn,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    // TODO: refactor as  https://ghe.coxautoinc.com/MAN-Vintage/questions-engine-frontend/issues/40
    onError: (e) => {
      toast(e.message, { type: 'error' });
    },
  });

  return { isFetching, data, error };
};
