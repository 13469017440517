import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { TIRE_DEPTH_OPTIONS } from '../../shared/constants';

type UseGetTireDepthFieldConfigParams = {
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
};

const getTireDepthValue = (watch: UseFormWatch<CreateEditAnswer>): OptionType | string => {
  const tireLocation = watch(`tireInfo.tireLocation`);
  const tireDepth = watch(`tireInfo.tireDepth`);

  if (!tireLocation || !tireDepth) {
    return '';
  }

  return { label: `${tireLocation}: ${tireDepth}`, value: `${tireLocation}:${tireDepth}` };
};

export const useGetTireDepthFieldConfig = (params: UseGetTireDepthFieldConfigParams): Field => {
  const { setValue, watch, trigger } = params;

  const onChange = (option: OptionType | null) => {
    if (!option) {
      setValue(`tireInfo`, undefined);
      return;
    }
    const [tireLocation, tireDepth] = option.value.split(':');
    setValue(`tireInfo`, { tireLocation, tireDepth });
    void trigger();
  };

  const value = getTireDepthValue(watch);

  return {
    value,
    options: TIRE_DEPTH_OPTIONS,
    onChange,
    isClearable: true,
  };
};
