import type { FormType } from '../../../../api/queries/formTypes/formTypes.types';
import type { CopyFormType } from '../CopyFormTypeForm/CopyFormTypeForm';
import type { ReactElement } from 'react';

import { useCopyFormType } from '../../../../api/hooks/useCopyFormType';
import { useForms } from '../../../../api/hooks/useForms';
import { Spinner } from '../../../../components/Loaders/Spinner/Spinner';
import { Modal } from '../../../../components/Modal/Modal';
import { getSortedVersionAndGuidOptions } from '../../utils/getSortedVersionAndGuidOptions';
import { CopyFormTypeForm } from '../CopyFormTypeForm/CopyFormTypeForm';

export type CopyFormTypeModalWidgetParams = Readonly<{
  isOpen: boolean;
  formType: FormType | undefined;
  onCancel: () => void;
  onSubmitClose: () => void;
}>;

export const CopyFormTypeModalWidget = ({ isOpen, onCancel, onSubmitClose, formType }: CopyFormTypeModalWidgetParams): ReactElement => {
  const { mutate } = useCopyFormType({ onSuccess: onSubmitClose });
  const { data, isFetching } = useForms({ formType: formType?.name, enabled: isOpen });
  const versions = getSortedVersionAndGuidOptions(data?.items ?? []);

  const onSubmitHandler = (data: CopyFormType) => {
    mutate({
      name: data.name,
      description: data.description,
      sourceFormGuid: data.version,
    });
    onSubmitClose();
  };

  return (
    <Modal name="copy-form-type" isOpen={isOpen} onClose={onCancel}>
      {isFetching ? <Spinner /> : <CopyFormTypeForm versions={versions} onClose={onCancel} onSubmit={onSubmitHandler} />}
    </Modal>
  );
};
