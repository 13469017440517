import type { CreateEditAnswer } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { Control } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { QUESTION_ENGINE_FILTER_OPTIONS } from '../../../../modules/question-engine/shared/constants';

type FilterOptionsAnswerSectionProps = Readonly<{
  control: Control<CreateEditAnswer>;
  isSubmitting: boolean;
}>;

export const FilterOptionsAnswerSection: FC<FilterOptionsAnswerSectionProps> = ({
  control,
  isSubmitting,
}: FilterOptionsAnswerSectionProps): ReactElement => {
  return (
    <ExpandableFormSection title="Filter Options">
      <FormControl
        type="multi-select"
        isClearable={false}
        control={control}
        name="filterParameters"
        label="Filter Parameters"
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        options={QUESTION_ENGINE_FILTER_OPTIONS}
        placeholder="Select"
        required={true}
      />
    </ExpandableFormSection>
  );
};
