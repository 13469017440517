import { useState, useEffect } from 'react';

export const enum ScriptStatus {
  IDLE = 'idle',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

type UseScriptLoaderParams = Readonly<{
  url: string;
  id: string;
}>;

type UseScriptLoader = Readonly<{
  status: ScriptStatus;
}>;

export const useScriptLoader = ({ url, id }: UseScriptLoaderParams): UseScriptLoader => {
  const [status, setStatus] = useState<ScriptStatus>(ScriptStatus.IDLE);

  const onLoad = () => setStatus(ScriptStatus.LOADED);
  const onError = () => setStatus(ScriptStatus.ERROR);

  useEffect(() => {
    setStatus(ScriptStatus.LOADING);

    const script = document.createElement('script');
    script.async = true;
    script.src = url;
    script.id = id;

    script.addEventListener('load', onLoad);
    script.addEventListener('error', onError);

    document.body.appendChild(script);

    return () => {
      script.removeEventListener('load', onLoad);
      script.removeEventListener('error', onError);
      document.body.removeChild(script);
    };
  }, []);

  return { status };
};
