import { useDraggable } from '@dnd-kit/core';

import type { ReactElement, ReactNode } from 'react';

type SimpleDraggableProps = Readonly<{ id: string; children: ReactNode }>;

export const SimpleDraggable = ({ id, children }: SimpleDraggableProps): ReactElement => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
  });

  return (
    <div ref={setNodeRef} {...listeners} {...attributes}>
      {children}
    </div>
  );
};
