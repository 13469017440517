import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { deleteForm } from '../queries/forms/forms.api';

export type UseDeleteFormParams = Readonly<{
  onSuccess: () => void;
}>;

export type UseDeleteForm = Readonly<{
  mutate: (formId: string) => void;
  isLoading: boolean;
}>;

export const useDeleteForm = ({ onSuccess }: UseDeleteFormParams): UseDeleteForm => {
  const onRequestSuccess = () => {
    onSuccess();
    toast('Successfully deleted', { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Deletion failed';
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (formId: string) => deleteForm(formId),
    onSuccess: onRequestSuccess,
    onError: onErrorRequest,
  });

  return {
    mutate,
    isLoading,
  };
};
