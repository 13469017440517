import { createPortal } from 'react-dom';

import type { Form } from '../../../../api/queries/forms/forms.types';
import type { FC, ReactElement } from 'react';

import { Sidebar } from '../../../../components/Sidebar/Sidebar';
import { QuestionEngine } from '../QuestionEngine/QuestionEngine';

type QuestionEnginePreviewProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  formTypeName: string;
  form?: Form;
}>;

export const QuestionEnginePreview: FC<QuestionEnginePreviewProps> = ({
  isOpen,
  onClose,
  formTypeName,
  form,
}: QuestionEnginePreviewProps): ReactElement => {
  return createPortal(
    <div data-testid="question-engine-preview">
      <Sidebar isOpen={isOpen} onClose={onClose}>
        <h2 data-testid="qe-form-preview" className="prism-heading-1 mt-12">{`${formTypeName} ${form?.version ?? ''}`}</h2>
        <QuestionEngine href={form?.href ?? ''} />
      </Sidebar>
    </div>,
    document.body,
  );
};
