import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import type { Form } from '../../../api/queries/forms/forms.types';
import type { ColumnDef, SortingState, Table } from '@tanstack/react-table';

import { formColumns } from '../shared/columns';

type UseFormTableProps = Readonly<{
  formData: Array<Form>;
  openFormPreview: (form: Form) => void;
  openDeleteFormModal: (form: Form) => void;
  openCopyFormModal: (form: Form) => void;
}>;

export const useFormTable = ({ formData, openFormPreview, openDeleteFormModal, openCopyFormModal }: UseFormTableProps): Table<Form> => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo<ColumnDef<Form>[]>(() => formColumns({ openFormPreview, openDeleteFormModal, openCopyFormModal }), []);

  return useReactTable<Form>({
    data: formData,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
};
