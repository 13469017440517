import type { FieldPath, FieldPathValue, FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

export const setValues = <T extends FieldValues>(
  setValue: UseFormSetValue<T>,
  values: {
    [name in FieldPath<T>]?: FieldPathValue<T, name>;
  },
) => {
  Object.entries(values).forEach(([key, value]) => {
    setValue(key as Path<T>, value as PathValue<T, Path<T>>);
  });
};
