import type { FC, ReactElement, ReactNode } from 'react';

type FormButtonsProps = Readonly<{
  isSaveButtonDisabled: boolean;
  onCancelButtonClick: () => void;
  children?: ReactNode;
}>;

export const FormButtons: FC<FormButtonsProps> = ({
  isSaveButtonDisabled,
  onCancelButtonClick,
  children,
}: FormButtonsProps): ReactElement => {
  return (
    <div data-testid="button-actions" className="flex flex-wrap justify-end gap-4 mt-10">
      {children}
      <button onClick={onCancelButtonClick} type="reset" data-testid="cancel-btn" className="qe-btn w-20">
        Cancel
      </button>
      <button type="submit" data-testid="save-btn" className="qe-btn fill w-20 flex justify-center" disabled={isSaveButtonDisabled}>
        Save
      </button>
    </div>
  );
};
