import React from 'react';

import type { Answer, AnswerGroupsMap } from '../../shared/types';
import type { FC, ReactElement } from 'react';

import { answerGroupsNameAndColor } from '../../shared/constants';

type MultipleResponsesActionButtonsParams = {
  onClose: () => void;
  onSubmit: () => void;
  answers: Array<Answer>;
  onClickNewGroup: () => void;
  answersGroups: AnswerGroupsMap;
};

export const MultipleResponsesActionButtons: FC<MultipleResponsesActionButtonsParams> = ({
  onClose,
  onClickNewGroup,
  answersGroups,
  answers,
  onSubmit,
}: MultipleResponsesActionButtonsParams): ReactElement => {
  const isCreateNewGroupDisabled = Object.keys(answersGroups).length === answerGroupsNameAndColor.length || !answers.length;

  return (
    <div className="mx-10 flex justify-center sm:justify-between gap-2 flex-wrap my-2">
      <div className="flex gap-2">
        <button data-testid="cancel-answer-groups-btn" onClick={onClose} className="mx-auto qe-btn self-end">
          Cancel
        </button>
        <button
          data-testid="submit-answer-groups-btn"
          onClick={onSubmit}
          className="mx-auto qe-btn fill self-end"
          disabled={!answers.length}
        >
          Save Changes
        </button>
      </div>
      <button disabled={isCreateNewGroupDisabled} data-testid="create-new-answer-group-btn" onClick={onClickNewGroup} className="qe-btn">
        Create New Group
      </button>
    </div>
  );
};
