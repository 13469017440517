import type { config } from '../../../common/config';

type RouteTo = keyof typeof config.routes;

export type FormPageURLParams = Readonly<{
  form: string;
}>;

export type QuestionsPageURLParams = Readonly<{
  form: string;
  formId: string;
}>;

type RouteURLParams = {
  FORM_TYPE: undefined;
  FORM: FormPageURLParams;
  QUESTIONS: QuestionsPageURLParams;
  LOGIN_CALLBACK: undefined;
  NOT_EXIST: undefined;
  DEPLOYMENT_MANAGER: undefined;
};

export const getNavigationLink = <T extends RouteTo>(to: T, params: RouteURLParams[T]): string => {
  if (to === 'FORM') {
    const _params = params as FormPageURLParams;
    return `/${_params.form}`;
  }

  if (to === 'QUESTIONS') {
    const _params = params as QuestionsPageURLParams;
    return `/${_params.form}/${_params.formId}`;
  }

  return '/';
};
