import { useCallback, useEffect, useState } from 'react';

import { useNextUrl } from './useNextUrl';
import { DEFAULT_SCROLL_THROTTLE, FETCHING_THRESHOLD } from '../constants';
import { throttle } from '../utils/debounce-throttle';

type PaginatedData<Data> = Readonly<{
  items: Data[];
  next?: string;
}>;

type UseInfiniteScrollParams<Data> = Readonly<{
  isFetching: boolean;
  setNextId: (id: string) => void;
  data?: PaginatedData<Data>;
}>;

type UseInfiniteScroll<Data> = Readonly<{
  accumulatedData: Data[];
  resetData: () => void;
}>;

const checkForMoreContent = (isFetching: boolean, callback: () => void) => {
  if (
    window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - FETCHING_THRESHOLD &&
    !isFetching
  ) {
    callback();
  }
};

export const useInfiniteScroll = <Data>({ isFetching, setNextId, data }: UseInfiniteScrollParams<Data>): UseInfiniteScroll<Data> => {
  const [accumulatedData, setAccumulatedData] = useState<Data[]>([]);
  const { nextUrl, setNextUrl, getNextId } = useNextUrl('');
  const [isDataStale, setIsDataStale] = useState(false);

  const setIfExists = useCallback(() => {
    const id = getNextId();
    if (id) {
      setNextId(id);
    }
  }, [getNextId, setNextId]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (isDataStale) {
      setAccumulatedData(data.items);
      setIsDataStale(false);
      return;
    }

    setAccumulatedData((prev) => [...prev, ...data.items]);
    setNextUrl(data.next || '');
  }, [data]);

  useEffect(() => {
    checkForMoreContent(isFetching, setIfExists); // Check after accumulatedData has been updated
  }, [nextUrl]);

  const handleScroll = useCallback(
    throttle(() => {
      checkForMoreContent(isFetching, setIfExists);
    }, DEFAULT_SCROLL_THROTTLE),
    [isFetching],
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const resetData = () => {
    setIsDataStale(true);
    setNextUrl('');
    setNextId('');
  };

  return { accumulatedData, resetData };
};
