import { useContext } from 'react';

import type { AuthContextProps } from '../components/AuthContext';

import { AuthContext } from '../components/AuthContext';

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};
