import type { CommonQuestion } from './schema';
import type { Question } from '../shared/types';

type ParseTagsResult = {
  tagsValues: string[];
  clientPreferencesValues: string[];
};

export const parseTags = (tags: string[] | undefined): ParseTagsResult => {
  const parsedTags: ParseTagsResult = { tagsValues: [], clientPreferencesValues: ['', ''] };
  if (!tags || !tags.length) {
    return parsedTags;
  }
  const isPreferences = /^\d{7}:\d*$/.test(tags[0]);
  if (isPreferences) {
    parsedTags.clientPreferencesValues = tags[0].split(':');

    if (tags.length > 1) {
      parsedTags.tagsValues = tags.slice(1);
    }
  } else {
    parsedTags.tagsValues = tags;
  }
  return parsedTags;
};

export const mapTags = (question: CommonQuestion): { tags?: Question['tags'] } => {
  const { tags = [], clientAccountNumber, clientValue } = question;

  if (!clientAccountNumber && !clientValue && !tags.length) {
    return {};
  }

  if (clientValue) {
    tags.unshift(`${clientAccountNumber}:${clientValue}`);
  }

  return { tags };
};
