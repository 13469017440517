interface IEnvironment {
  environment: string;
}

export const updateEnvDropDown = (environments: string[], highestEnvironment: string) => {
  const environmentToLowerCase = highestEnvironment.toLowerCase();
  return environments.reduce((acc: IEnvironment[], item, index) => {
    if (index <= environments.indexOf(environmentToLowerCase) && item !== 'prod') {
      return [
        ...acc,
        {
          environment: item,
        },
      ];
    }
    return acc;
  }, []);
};

export const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);

export const getDataFromStorage = (key: string, defaultValue: string): string => window.localStorage.getItem(key) ?? defaultValue;
