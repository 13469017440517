import type { Question as APIQuestion } from '../../../api/queries/forms/forms.types';
import type { Question, MoveDirection, Answer } from '../shared/types';

export enum ActionType {
  SET_INIT_QUESTIONS_DATA = 'SET_INIT_QUESTIONS_DATA',
  EDIT_QUESTION = 'EDIT_QUESTION',
  ADD_QUESTION = 'ADD_QUESTION',
  CHANGE_QUESTION_ORDER = 'CHANGE_QUESTION_ORDER',
  CHANGE_ANSWER_ORDER = 'CHANGE_ANSWER_ORDER',
  MOVE_QUESTION = 'MOVE_QUESTION',
  EDIT_ANSWER = 'EDIT_ANSWER',
  ADD_ANSWER = 'ADD_ANSWER',
  DELETE_ANSWER = 'DELETE_ANSWER',
  DELETE_QUESTION = 'DELETE_QUESTION',
  COPY_QUESTION = 'COPY_QUESTION',
}

export type SetInitQuestionsPayload = Readonly<{ questions: Array<APIQuestion>; guid: string; isEdited: boolean }>;
export type SetEditQuestionsPayload = Readonly<{ questionId: string; updatedQuestion: Question }>;
export type SetAddQuestionsPayload = Readonly<{ createdQuestion: Question }>;
export type ChangeQuestionOrderPayload = Readonly<{ newQuestionsOrderIds: Array<string> }>;
export type ChangeAnswerOrderPayload = Readonly<{ newAnswersOrderIds: Array<string>; questionId: string }>;
export type MoveQuestionPayload = Readonly<{ questionId: string; direction: MoveDirection }>;
export type SetEditAnswersPayload = Readonly<{ questionId: string; answerId: string; updatedAnswer: Answer }>;
export type SetAddAnswersPayload = Readonly<{ questionId: string; answer: Answer }>;
export type DeleteAnswerPayload = Readonly<{ answerId: string }>;
export type DeleteQuestionPayload = Readonly<{ questionId: string }>;
export type CopyQuestionPayload = Readonly<{ copiedQuestion: Question; insertNearId: string }>;

type SetInitQuestionsDataAction = Readonly<{
  type: ActionType.SET_INIT_QUESTIONS_DATA;
  payload: SetInitQuestionsPayload;
}>;

type EditQuestionAction = Readonly<{
  type: ActionType.EDIT_QUESTION;
  payload: SetEditQuestionsPayload;
}>;

type AddQuestionAction = Readonly<{
  type: ActionType.ADD_QUESTION;
  payload: SetAddQuestionsPayload;
}>;

type ChangeQuestionOrderAction = Readonly<{
  type: ActionType.CHANGE_QUESTION_ORDER;
  payload: ChangeQuestionOrderPayload;
}>;

type ChangeAnswerOrderAction = Readonly<{
  type: ActionType.CHANGE_ANSWER_ORDER;
  payload: ChangeAnswerOrderPayload;
}>;

type MoveQuestionAction = Readonly<{
  type: ActionType.MOVE_QUESTION;
  payload: MoveQuestionPayload;
}>;

type AddAnswerAction = Readonly<{
  type: ActionType.ADD_ANSWER;
  payload: SetAddAnswersPayload;
}>;

type EditAnswerAction = Readonly<{
  type: ActionType.EDIT_ANSWER;
  payload: SetEditAnswersPayload;
}>;

type DeleteAnswerAction = Readonly<{
  type: ActionType.DELETE_ANSWER;
  payload: DeleteAnswerPayload;
}>;

type DeleteQuestionAction = Readonly<{
  type: ActionType.DELETE_QUESTION;
  payload: DeleteQuestionPayload;
}>;

type CopyQuestionAction = Readonly<{
  type: ActionType.COPY_QUESTION;
  payload: CopyQuestionPayload;
}>;

export type QuestionAction =
  | SetInitQuestionsDataAction
  | EditQuestionAction
  | AddQuestionAction
  | ChangeQuestionOrderAction
  | ChangeAnswerOrderAction
  | MoveQuestionAction
  | AddAnswerAction
  | EditAnswerAction
  | DeleteAnswerAction
  | DeleteQuestionAction
  | CopyQuestionAction;

export const setInitQuestionsData = (payload: SetInitQuestionsPayload): SetInitQuestionsDataAction => ({
  type: ActionType.SET_INIT_QUESTIONS_DATA,
  payload,
});

export const editQuestion = (payload: SetEditQuestionsPayload): EditQuestionAction => ({
  type: ActionType.EDIT_QUESTION,
  payload,
});

export const addQuestion = (payload: SetAddQuestionsPayload): AddQuestionAction => ({
  type: ActionType.ADD_QUESTION,
  payload,
});

export const changeQuestionOrder = (payload: ChangeQuestionOrderPayload): ChangeQuestionOrderAction => ({
  type: ActionType.CHANGE_QUESTION_ORDER,
  payload,
});

export const changeAnswerOrder = (payload: ChangeAnswerOrderPayload): ChangeAnswerOrderAction => ({
  type: ActionType.CHANGE_ANSWER_ORDER,
  payload,
});

export const moveQuestion = (payload: MoveQuestionPayload): MoveQuestionAction => ({
  type: ActionType.MOVE_QUESTION,
  payload,
});

export const addAnswer = (payload: SetAddAnswersPayload): AddAnswerAction => ({
  type: ActionType.ADD_ANSWER,
  payload,
});

export const editAnswer = (payload: SetEditAnswersPayload): EditAnswerAction => ({
  type: ActionType.EDIT_ANSWER,
  payload,
});

export const deleteAnswer = (payload: DeleteAnswerPayload): DeleteAnswerAction => ({
  type: ActionType.DELETE_ANSWER,
  payload,
});

export const deleteQuestion = (payload: DeleteQuestionPayload): DeleteQuestionAction => ({
  type: ActionType.DELETE_QUESTION,
  payload,
});

export const copyQuestion = (payload: CopyQuestionPayload): CopyQuestionAction => ({
  type: ActionType.COPY_QUESTION,
  payload,
});
