import { useMemo, useState } from 'react';

import type { Metadata } from '../shared/types';
import type { CreateEditAnswer } from '../utils/schema';
import type { UseFormSetValue } from 'react-hook-form';
import type { ZodType } from 'zod';

import { getTopLevelMetadataSchemaKeys } from '../utils/getMetadataSchemaKeys';
import { mergeMetaDataFields } from '../utils/mergeMetaDataFields';

type UseHandleMetadata = Readonly<{
  isMetaDataDirty: boolean;
  onMetaDataChange: (metaData?: Metadata) => void;
  setIsMetaDataDirty: (isDirty: boolean) => void;
}>;

type UseHandleMetadataParams = Readonly<{
  formValues: CreateEditAnswer;
  setValues: UseFormSetValue<CreateEditAnswer>;
  schema: ZodType;
}>;

export const useHandleMetadata = ({ formValues, setValues, schema }: UseHandleMetadataParams): UseHandleMetadata => {
  const [isMetaDataDirty, setIsMetaDataDirty] = useState(false);

  const topLevelSchemaProhibitedKeys = useMemo(() => getTopLevelMetadataSchemaKeys(schema), [schema]);

  const onMetaDataChange = (metaData?: Metadata) => {
    setValues('metaData', mergeMetaDataFields(metaData, formValues.metaData, topLevelSchemaProhibitedKeys));

    if (!isMetaDataDirty) {
      setIsMetaDataDirty(true);
    }
  };

  return { isMetaDataDirty, onMetaDataChange, setIsMetaDataDirty };
};
