import { CheckmarkIcon, CrossIcon } from '@prism2/icons-react/prism';

import type { FC, ReactElement } from 'react';

type QuestionsTableParentCellProps = Readonly<{
  value: boolean;
}>;

export const QuestionsTableParentCell: FC<QuestionsTableParentCellProps> = ({ value }: QuestionsTableParentCellProps): ReactElement => {
  return (
    <div className="flex justify-center items-center">
      {value ? (
        <CheckmarkIcon className="text-primary-dark w-5" data-testid="state:true" />
      ) : (
        <CrossIcon className="text-primary-dark w-5" data-testid="state:false" />
      )}
    </div>
  );
};
