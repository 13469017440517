import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import type { ArtItemDescription } from '../../../../api/queries/artProxy/artProxy.types';
import type { OptionType } from '../../../../common/types';
import type { QueryFunction } from '@tanstack/react-query';

import { ArtProxyAction, QueryKey } from '../../../../api/constants';
import { getItemDescription } from '../../../../api/queries/artProxy/artProxy.api';
import { useDebounceValue } from '../../../../common/hooks/useDebounceValue';

export type UseItemDescriptionSearchData = Readonly<{
  setSearchInput: (str: string) => void;
  data: OptionType[];
  isFetching: boolean;
  searchInput: string;
}>;

export const useItemDescriptionSearch = (): UseItemDescriptionSearchData => {
  const [searchInput, setSearchInput] = useState('a');
  const debouncedSearch = useDebounceValue(searchInput);

  const queryFn: QueryFunction<ArtItemDescription[]> = async ({ signal }): Promise<ArtItemDescription[]> => {
    const res = await getItemDescription({ description: debouncedSearch, signal });
    return res.items || [];
  };

  const { data, isFetching } = useQuery({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_SEARCH, debouncedSearch],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(debouncedSearch.length),
  });

  const options = useMemo(() => {
    if (data && !isFetching) {
      return data.map((item) => ({ label: item.description, value: `${item.itemCode}_${item.subItemCode}` }));
    }

    return [] as OptionType[];
  }, [data, isFetching]);

  return {
    setSearchInput,
    data: options,
    isFetching,
    searchInput,
  };
};
