import { useEffect } from 'react';

import type { Question } from '../../shared/types';
import type { FieldValues, Path, UseFormGetValues, UseFormSetValue, UseFormWatch } from 'react-hook-form';

type Context = {
  question: Question;
};

type HandlersMap<TFieldValues extends FieldValues> = Record<
  UseFormGetValues<TFieldValues>['name'],
  (props: UseFormObserverProps<TFieldValues>) => void
>;

export type UseFormObserverProps<TFieldValues extends FieldValues> = Readonly<{
  handlersMap: HandlersMap<TFieldValues>;
  context?: Context;
  name?: Path<TFieldValues>;
  watch: UseFormWatch<TFieldValues>;
  setValue: UseFormSetValue<TFieldValues>;
}>;

export const useFormObserver = <TFieldValues extends FieldValues>({
  watch,
  setValue,
  handlersMap,
  context,
}: UseFormObserverProps<TFieldValues>) => {
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (!name) {
        return;
      }
      const handler = handlersMap[name as keyof typeof handlersMap];

      if (handler) {
        handler({ setValue, name, context, handlersMap, watch });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);
};
