import type { ZodType } from 'zod';

import { getPropertyPaths } from '../../../modules/json-editor/shared/utils';

export const getMetadataSchemaKeys = (schema: ZodType): string[] => {
  return getPropertyPaths(schema).filter((path) => path.startsWith('metaData.'));
};

export const getTopLevelMetadataSchemaKeys = (schema: ZodType): string[] => {
  return getMetadataSchemaKeys(schema).map((key) => key.split('.')[1]);
};
