import { useCallback, type FC, type ReactElement } from 'react';
import { useController } from 'react-hook-form';

import type { OptionType } from '../../../../common/types';
import type { GuideImageLabel } from '../../shared/types';
import type { CommonQuestion } from '../../utils/schema';
import type { Control, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types';

import { Option } from './Option';
import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { FormErrorNotification } from '../../../../modules/form/components/FormErrorNotifications/FormErrorNotification';
import { GUIDE_IMAGES_CATEGORIES } from '../../../../modules/question-engine/shared/constants';

type GuideImagesSectionProps = Readonly<{
  control: Control<CommonQuestion>;
  isSubmitting: boolean;
  watch: UseFormWatch<CommonQuestion>;
  setValue: UseFormSetValue<CommonQuestion>;
  trigger: UseFormTrigger<CommonQuestion>;
}>;

const categoriesOrder = Object.keys(GUIDE_IMAGES_CATEGORIES) as GuideImageLabel[];

export const GuideImagesSection: FC<GuideImagesSectionProps> = ({
  control,
  isSubmitting,
  watch,
  setValue,
  trigger,
}: GuideImagesSectionProps): ReactElement => {
  const guideImages = watch('guideImages');

  const { fieldState } = useController({ control, name: 'guideImages' });

  const getSelectedValues = useCallback(
    (category: GuideImageLabel): string[] | undefined => {
      const selectedImages = guideImages?.[category];

      if (!selectedImages?.length) {
        return;
      }

      const selectedOptions = GUIDE_IMAGES_CATEGORIES[category].options.filter((option) =>
        selectedImages.some((selectedImage) => option.value === selectedImage.url),
      );

      return selectedOptions.map(({ value }) => value);
    },
    [guideImages],
  );

  const onChangeHandler = (options: ReadonlyArray<OptionType>, category: GuideImageLabel) => {
    const isTags = category !== 'shared';
    const changedGuideImages = options.map((option) => ({
      url: option.value,
      ...(isTags && {
        tags: GUIDE_IMAGES_CATEGORIES[category].tags,
      }),
    }));

    setValue(`guideImages.${category}`, changedGuideImages);

    void trigger();
  };

  return (
    <ExpandableFormSection title="Guide Images">
      {categoriesOrder.map((categoryName: GuideImageLabel) => {
        const guideImagesCategory = GUIDE_IMAGES_CATEGORIES[categoryName];
        return (
          <FormControl
            options={guideImagesCategory.options}
            control={control}
            type="multi-select"
            name={`guideImages.${categoryName}`}
            placeholder="Select..."
            label={guideImagesCategory.label}
            isClearable={true}
            isSearchable={true}
            key={categoryName}
            isSubmitting={isSubmitting}
            components={{
              IndicatorSeparator: () => null,
              Option,
            }}
            value={getSelectedValues(categoryName)}
            onChange={(options: ReadonlyArray<OptionType>) => onChangeHandler(options, categoryName)}
          />
        );
      })}
      <FormErrorNotification fieldState={fieldState} isSubmitting={isSubmitting} />
    </ExpandableFormSection>
  );
};
