import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import type { ScheduleFormDataType } from '../queries/shared/types';

import { QueryKey } from '../constants';
import { resetScheduleForm, scheduleForm } from '../queries/forms/forms.api';

type ScheduleFormDataTypeWithReset = ScheduleFormDataType & {
  reset: boolean;
};

type UseScheduleFormReturn = Readonly<{
  mutate: (values: ScheduleFormDataTypeWithReset) => void;
  isLoading: boolean;
}>;

type UseScheduleFormParam = {
  onCompleteRequest: () => void;
};

export const useScheduleForm = ({ onCompleteRequest }: UseScheduleFormParam): UseScheduleFormReturn => {
  const queryClient = useQueryClient();

  const onRequestSuccess = (values: unknown, variables: ScheduleFormDataTypeWithReset) => {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_BY_ID_ALL_ENVS]);
    void queryClient.invalidateQueries([QueryKey.TEST_LATEST_VERSIONS]);
    onCompleteRequest();
    toast(`Production deployment scheduled for ${format(variables.deployDate, 'MM/dd/yyyy')} at 7pm EST.`, { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Failed to deploy form';
    onCompleteRequest();
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: async (values: ScheduleFormDataTypeWithReset) => {
      if (values.reset) {
        await resetScheduleForm({
          formGuid: values.formGuid,
          uatQuestionnaireGuid: values.uatQuestionnaireGuid,
        });
      }
      return scheduleForm(values);
    },
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
