import { useEffect, useState } from 'react';

import type { CommonQuestion } from '../../utils/schema';
import type { UseFormSetError, UseFormTrigger } from 'react-hook-form';

type UsePrepopulateBuyerTranslationField = Readonly<{
  isPropagateQuestionText: boolean;
  onChangePropagateQuestionText: () => void;
}>;

type UsePrepopulateBuyerTranslationFieldProps = Readonly<{
  trigger: UseFormTrigger<CommonQuestion>;
  setError: UseFormSetError<CommonQuestion>;
  setValue: (fieldName: 'buyerTranslation', text: string) => void;
  clearErrors: (fieldName: 'buyerTranslation') => void;
  text: string;
  buyerTranslation: string;
}>;

export const usePrepopulateBuyerTranslationField = ({
  trigger,
  setError,
  setValue,
  clearErrors,
  text,
  buyerTranslation,
}: UsePrepopulateBuyerTranslationFieldProps): UsePrepopulateBuyerTranslationField => {
  const [isPropagateQuestionText, setIsPropagateQuestionText] = useState(buyerTranslation === text);

  const onChangePropagateQuestionText = async () => {
    if (isPropagateQuestionText) {
      setValue('buyerTranslation', '');
      setError('buyerTranslation', {
        type: 'required',
        message: 'Buyer Translation is required',
      });
    } else {
      setValue('buyerTranslation', text);
      await trigger();
    }
    setIsPropagateQuestionText((prev) => !prev);
  };

  useEffect(() => {
    if (isPropagateQuestionText) {
      setValue('buyerTranslation', text);
      clearErrors('buyerTranslation');
    }
  }, [text, isPropagateQuestionText]);

  return { isPropagateQuestionText, onChangePropagateQuestionText: onChangePropagateQuestionText as () => void };
};
