import { useEffect } from 'react';

import type { Answer, RequiredQuestions } from '../../shared/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue } from 'react-hook-form';
import type { UseFormWatch } from 'react-hook-form/dist/types';

import { NESTED_QUESTIONS_FORM_SUB_GROUP_NAME } from '../../shared/constants';
import { NestedFieldName } from '../../shared/types';

type UseHandleNestQuestionsParams = {
  setValue: UseFormSetValue<CreateEditAnswer>;
  answerToEdit?: Answer;
  watch: UseFormWatch<CreateEditAnswer>;
};

export const useHandleNestQuestions = ({ setValue, watch }: UseHandleNestQuestionsParams) => {
  const nestingType = watch(`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.nestingType`);
  const isNestQuestion = watch('switchControls.isNestQuestion');

  useEffect(() => {
    if (nestingType === NestedFieldName.requiredQuestions) {
      setValue(`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.${NestedFieldName.nestedQuestions}`, '');
      return;
    }

    setValue(`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.${NestedFieldName.requiredQuestions}`, [] as unknown as RequiredQuestions);
  }, [nestingType]);

  useEffect(() => {
    if (!isNestQuestion) {
      setValue(NESTED_QUESTIONS_FORM_SUB_GROUP_NAME, undefined);
    }
  }, [isNestQuestion]);
};
