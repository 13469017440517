import { zodResolver } from '@hookform/resolvers/zod';
import { type FC, type ReactElement, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import type { Answer, QuestionsTableRowItem } from '../../shared/types';
import type { SyntheticEvent } from 'react';

import { useWatchFormErrors } from '../../../../common/hooks/useWatchFormErrors';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { Modal } from '../../../../components/Modal/Modal';
import { useCopyFormAction } from '../../hooks/useCopyQuestionFormAction/useCopyFormAction';
import { useFormObserver } from '../../hooks/useFormObserver/useFormObserver';
import { usePrepopulateBuyerTranslationField } from '../../hooks/usePrepopulateBuyerTranslationField/usePrepopulateBuyerTranslationField';
import { useShowAnswersGroupModal } from '../../hooks/useShowAnswersGroupModal/useShowAnswersGroupModal';
import { COPY_QUESTION_HANDLERS_MAP, COPY_QUESTION_INIT_FORM_VALUES } from '../../utils/constants';
import { mapQuestionStateToFormState } from '../../utils/mapQuestionStateToFormState';
import { type CommonQuestion, mergeCopyQuestionSchemas } from '../../utils/schema';
import { ArtMappingQuestion } from '../ArtMappingCopyQuestion/ArtMappingCopyQuestion';
import { ClientPreferencesFormSection } from '../ClientPreferencesFormSection/ClientPreferencesFormSection';
import { FilterOptionsFormSection } from '../FilterOptionsFormSection/FilterOptionsFormSection';
import { GeneralInfoFormSection } from '../GeneralInfoFormSection/GeneralInfoFormSection';
import { GuideImagesSection } from '../GuideImagesSection/GuideImagesSection';
import { MultipleResponsesModal } from '../MultipleResponsesModal/MultipleResponsesModal';
import { QuestionCopySettingsSection } from '../QuestionCopyAnswerSettingsSection/QuestionCopyAnswerSettingsSection';
import { QuestionOptionsFormSection } from '../QuestionOptionsFormSection/QuestionOptionsFormSection';

type CopyQuestionModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  setCopyQuestionModalData: (data: QuestionsTableRowItem | undefined) => void;
  questionToCopy?: QuestionsTableRowItem;
}>;

// TODO: refactor if copy modal will not much different from Create/Edit modal in future
export const CopyQuestionModal: FC<CopyQuestionModalProps> = ({
  isOpen,
  questionToCopy,
  onClose,
  setCopyQuestionModalData,
}: CopyQuestionModalProps): ReactElement => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const submitBtnText = 'Copy Question';

  const defaultValues = useMemo(() => {
    if (!questionToCopy) {
      return COPY_QUESTION_INIT_FORM_VALUES;
    }

    const mappedQuestion = mapQuestionStateToFormState(questionToCopy, { isCopyAnswers: true });

    return mappedQuestion;
  }, [questionToCopy]);

  const {
    formState: { errors, isValid, isDirty, isSubmitting },
    setValue,
    clearErrors,
    getFieldState,
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    setError,
  } = useForm<CommonQuestion>({
    resolver: (data, context, options) => {
      const switchControls = data.switchControls;

      return zodResolver(
        mergeCopyQuestionSchemas({
          isGuideImages: switchControls?.isGuideImages ?? false,
          isClientPreferences: switchControls?.isClientPreferences ?? false,
          isFilters: switchControls?.isFilters ?? false,
          isCopyAnswers: switchControls?.isCopyAnswers ?? false,
        }),
      )(data, context, options);
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues,
    shouldFocusError: false,
  });

  const switchControls = watch('switchControls');
  const text = watch('text');
  const buyerTranslation = watch('buyerTranslation');
  const answers = watch('answers');

  useWatchFormErrors(errors);

  useFormObserver<CommonQuestion>({
    setValue,
    watch,
    handlersMap: COPY_QUESTION_HANDLERS_MAP,
  });

  const { showMultipleResponsesModal, setShowMultipleResponsesModal } = useShowAnswersGroupModal(
    switchControls?.isMultipleResponses ?? false,
    answers,
  );

  const { isPropagateQuestionText, onChangePropagateQuestionText } = usePrepopulateBuyerTranslationField({
    trigger,
    setError,
    setValue,
    clearErrors,
    text,
    buyerTranslation,
  });

  useWatchFormErrors(errors);

  const resetForm = () => {
    reset(questionToCopy as CommonQuestion);
    setCopyQuestionModalData(undefined);
    onClose();
  };

  const { copyQuestion } = useCopyFormAction({
    questionToCopy,
    callback: resetForm,
    isCopyAnswers: switchControls?.isCopyAnswers,
  });

  const onCloseHandler = (): void => {
    if (isDirty) {
      setIsConfirmationModalOpen(true);
      return;
    }
    resetForm();
  };

  const onSubmitHandler = (formData: CommonQuestion): void => {
    if (questionToCopy) {
      copyQuestion(formData);
    }
  };

  const onCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    onCloseHandler();
  };

  const onDiscardCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const onDiscard = () => {
    setIsConfirmationModalOpen(false);
    resetForm();
  };

  return (
    <Modal modalTitle="Copy Question" isOpen={isOpen} onClose={onCloseHandler}>
      <>
        <DialogModal
          isOpen={isConfirmationModalOpen}
          onClose={onDiscardCancel}
          onCancelButton={onDiscardCancel}
          onSubmitButton={onDiscard}
          submitButtonText="Discard changes"
          cancelButtonText="Continue editing"
        >
          <p data-testid="confirmation-text" className="font-bold text-blue-800 p-5">
            You have unsaved changes. Do you want to continue editing?
          </p>
        </DialogModal>
        <h3 className="text-xm font-bold my-4">Copy From - {questionToCopy?.text}</h3>
        <form
          className="flex flex-row justify-center flex-wrap gap-10 mt-4"
          data-testid="copy-question-modal-form"
          onSubmit={(event) => void handleSubmit(onSubmitHandler)(event)}
        >
          <div className="flex flex-col items-end w-80">
            <button type="reset" data-testid="cancel-btn" className="qe-btn w-full mb-3" onClick={onCancel}>
              Cancel
            </button>

            <GeneralInfoFormSection
              control={control}
              getFieldState={getFieldState}
              isSubmitting={isSubmitting}
              setValue={setValue}
              isPropagateQuestionText={isPropagateQuestionText}
              onChangePropagateQuestionText={onChangePropagateQuestionText}
            />
          </div>

          <div className="flex flex-col items-end w-80">
            <button data-testid="copy-question-btn" className="qe-btn w-full mb-3" type="submit" disabled={!isValid}>
              {submitBtnText}
            </button>
            <QuestionOptionsFormSection title="Copy Question Options" control={control} />
            <QuestionCopySettingsSection control={control} />
            {switchControls?.isFilters && <FilterOptionsFormSection control={control} isSubmitting={isSubmitting} />}
            {switchControls?.isGuideImages && (
              <GuideImagesSection control={control} isSubmitting={isSubmitting} watch={watch} setValue={setValue} trigger={trigger} />
            )}
            {switchControls?.isClientPreferences && (
              <ClientPreferencesFormSection control={control} isSubmitting={isSubmitting}></ClientPreferencesFormSection>
            )}
            {switchControls?.isMultipleResponses && (
              <button
                data-testid="group-answers-btn"
                className="qe-btn w-full fill mb-2"
                disabled={!answers?.length}
                onClick={(e) => {
                  e.preventDefault();
                  setShowMultipleResponsesModal(true);
                }}
              >
                Group Answers
              </button>
            )}
            {showMultipleResponsesModal && (
              <MultipleResponsesModal
                answers={answers}
                isOpen={showMultipleResponsesModal}
                onChange={(answers: Answer[]) => setValue('answers', answers, { shouldValidate: true, shouldDirty: true })}
                onClose={() => setShowMultipleResponsesModal(false)}
              />
            )}
            {switchControls?.isCopyAnswers && (
              <ArtMappingQuestion trigger={trigger} watch={watch} setValue={setValue} control={control} getFieldState={getFieldState} />
            )}
          </div>
        </form>
      </>
    </Modal>
  );
};
