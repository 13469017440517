import type { FC, ReactElement } from 'react';

import { QuestionsPage } from '../../QuestionsPage';
import { QuestionsProvider } from '../QuestionsProvider/QuestionsProvider';

export const QuestionPageWithProvider: FC = (): ReactElement => {
  return (
    <QuestionsProvider>
      <QuestionsPage />
    </QuestionsProvider>
  );
};
