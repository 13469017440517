import { formatISO } from 'date-fns';

import type { CopyFormParams, FetchFormsParams, Form, FormByIdAllEnvs, FetchFormsResponse } from './forms.types';
import type {
  UpdateFormData,
  UpdateFormDataNoGuid,
  DeployFormDataType,
  ScheduleFormDataType,
  ResetScheduleFormDataType,
} from '../shared/types';

import { config } from '../../../common/config';
import { appendToQueryIfExists } from '../../utils/appendToQueryIfExists';
import { fetchJson } from '../../utils/fetchJson';

export const fetchForms = ({ formType, id, next, limit, nextLimit, env }: FetchFormsParams): Promise<FetchFormsResponse> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/search`);

  appendToQueryIfExists(url, 'formType', formType);
  appendToQueryIfExists(url, 'id', id);
  appendToQueryIfExists(url, 'showMetaData', true);
  appendToQueryIfExists(url, 'limit', next ? nextLimit : limit);
  appendToQueryIfExists(url, 'env', env);

  if (next) {
    appendToQueryIfExists(url, 'direction', 'next');
  }

  return fetchJson(url, 'GET');
};

export const deleteForm = (formId: string): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/id/${formId}`);

  return fetchJson(url, 'DELETE');
};

export const copyForm = ({ sourceFormGuid, formTypeName }: CopyFormParams): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/copy`);

  return fetchJson(url, 'POST', { sourceFormGuid, formTypeName });
};

export const updateForm = (updateFormData: UpdateFormData): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/id/${updateFormData.guid}`);
  const updateFormDataNoGuid: UpdateFormDataNoGuid = { ...updateFormData };
  delete updateFormDataNoGuid.guid;
  return fetchJson(url, 'POST', { ...updateFormDataNoGuid });
};

export const createFormVersion = (formName: string): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms`);

  return fetchJson(url, 'POST', { formType: { name: formName } });
};

export const fetchForm = (formId: string): Promise<Form> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/id/${formId}`);

  return fetchJson(url, 'GET');
};

export const syncFormsData = (formId: string, highestEnvironment: string): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/sync-form-all-envs/id/${formId}`);

  return fetchJson(url, 'POST', { highestEnvironment });
};

export const fetchFormByIDAllEnvs = (formId: string): Promise<FormByIdAllEnvs> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/id/${formId}?envAll=true`);

  return fetchJson(url, 'GET');
};

export const fetchTestLatestVersions = (params: {
  formType: string;
}): Promise<{ [key: string]: { latestVersion: string; testVersion: string } }> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/${params.formType}/test-latest-versions`);

  return fetchJson(url, 'GET');
};

export const deployForm = (formData: DeployFormDataType): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/deploy-form`);

  return fetchJson(url, 'POST', formData);
};

export const scheduleForm = (formData: ScheduleFormDataType): Promise<{ message: string }> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/schedule-deploy`);

  return fetchJson(url, 'POST', {
    ...formData,
    deployDate: formatISO(formData.deployDate, { representation: 'date' }),
  });
};

export const resetScheduleForm = async (data: ResetScheduleFormDataType): Promise<void> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/reset-form-deploy-data`);

  const response = await fetchJson<{ status: { status: string }[] }>(url, 'POST', data);

  if (!response.status.length || response.status[0].status !== 'success') {
    throw new Error('Error while resetting the schedule date');
  }
};
