import classnames from 'classnames';

import type { ColorTheme } from '../../common/types/ColorTheme';
import type { FC, ReactElement } from 'react';

import { ColorThemes } from '../../common/types/ColorTheme';

type LabelProps = Readonly<{
  label: string;
  name: string;
  disabled?: boolean;
  colorTheme?: ColorTheme;
  required?: boolean;
  className?: string;
}>;

export const Label: FC<LabelProps> = ({ label, name, disabled, colorTheme, required, className = '' }: LabelProps): ReactElement => {
  const classNames = classnames('prism-label', className, {
    'text-gray-500': disabled,
    'text-black': !disabled && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': !disabled && colorTheme === ColorThemes.GREEN,
    'after:content-["*"] after:ml-0.5 after:text-red-500 block': required && !disabled,
  });
  return (
    <label data-testid={`${name}-label`} key={name} className={classNames}>
      {label}
    </label>
  );
};
