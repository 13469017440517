import { useRef, useState } from 'react';

export type UseModalState<T> = Readonly<{
  isOpen: boolean;
  modalData: T | undefined;
  open: (data?: T) => void;
  close: () => void;
  setModalData: (modalData: T | undefined) => void;
  modalName?: string;
}>;

export const useModalState = <T>(name?: string): UseModalState<T> => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<T>();
  const modalName = useRef(name);

  const open = (data?: T) => {
    setIsOpen(true);
    data && setModalData(data);
  };
  const close = () => {
    setIsOpen(false);
  };

  return {
    isOpen,
    modalData,
    open,
    close,
    setModalData,
    modalName: modalName.current,
  };
};
