import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Fragment } from 'react';

import type { ReactNode, ReactElement } from 'react';

type DroppableProps<T extends string> = Readonly<{
  id: string;
  renderItem(item: T): ReactNode;
  items: T[];
  className?: string;
}>;

export const Droppable = <T extends string>({ id, items, renderItem, className }: DroppableProps<T>): ReactElement => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext id={id} items={items}>
      <div data-testid={`droppable-container-${id}`} ref={setNodeRef} className={className}>
        {items.map((item) => (
          <Fragment key={item}>{renderItem(item)}</Fragment>
        ))}
      </div>
    </SortableContext>
  );
};
