import { useState } from 'react';

import type { DeployModalDataType } from '../shared/types';
import type { FC, ReactElement } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { SwitchField } from '../../../components/SwitchField/SwitchField';

type DeploymentModalProps = Readonly<{
  isOpen: boolean;
  closeModal: () => void;
  onChange: (DeployModalDataType: DeployModalDataType) => void;
  selectedFormData?: DeployModalDataType;
}>;

export const DeployFormModal: FC<DeploymentModalProps> = ({ isOpen, closeModal, selectedFormData, onChange }): ReactElement | null => {
  const deployEnvironment = selectedFormData?.deployEnvironment;
  const version = selectedFormData?.selectedVersion || '';
  const name = selectedFormData?.formName || '';
  const formName = `${name} ${version} - Deploy form to ${deployEnvironment ?? ''}`;
  const [isLatest, updateIsLatest] = useState<boolean>(true);
  const [isTest, updateIsTest] = useState<boolean>(true);
  const statusText = { active: 'ON', inactive: 'OFF' };

  const closeUpdateModal = () => {
    submitChange();
    closeModal();
  };

  const submitChange = () => {
    if (selectedFormData) {
      const copyDeployFormData = {
        ...selectedFormData,
        isTest,
        isLatest,
      };
      onChange(copyDeployFormData);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} modalTitle={formName}>
      <>
        <div className="grid grid-cols-auto gap-4" data-testid="deploy-modal-form">
          <div className="col-start-1 col-span-5">
            <div className="flex flex-row">
              <div className="basis-2/3 py-2 px-2">Is Test</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isTestField"
                  name="isTestField"
                  value={isTest}
                  onChange={(value) => updateIsTest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>

          <div className="col-start-6 col-span-4">
            <div className="flex flex-row">
              <div className="basis-2/3 py-2 px-3">Is Latest</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isLatestField"
                  name="isLatestField"
                  value={isLatest}
                  onChange={(value) => updateIsLatest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-auto gap-4 mt-20">
          <div className="col-start-1 col-span-5 p-2">
            <button className="qe-btn" onClick={closeModal}>
              CANCEL
            </button>
          </div>
          <div className="col-start-7 col-span-3 p-2">
            <button className="qe-btn fill w-full" type="button" onClick={closeUpdateModal}>
              DEPLOY
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
