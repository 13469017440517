import { ChevronDownIcon, ChevronRightIcon } from '@prism2/icons-react/prism';

import type { QuestionsTableRowItem } from '../../shared/types';
import type { CellContext } from '@tanstack/react-table';
import type { FC, ReactElement } from 'react';

type QuestionsTableExpandCellProps = Readonly<{
  info: CellContext<QuestionsTableRowItem, unknown>;
}>;

export const QuestionsTableExpandCell: FC<QuestionsTableExpandCellProps> = ({ info }: QuestionsTableExpandCellProps): ReactElement => {
  const icon = info.row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />;
  const onToggle = () => info.row.toggleExpanded();

  return (
    <button data-testid="expand-btn" className="qe-btn w-8" onClick={onToggle}>
      {icon}
    </button>
  );
};
