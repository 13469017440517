import { Dialog, Transition } from '@headlessui/react';
import { CrossIcon } from '@prism2/icons-react/prism';
import { Fragment } from 'react';

import type { FC, ReactElement } from 'react';

import { ModalTitle } from '../ModalTitle/ModalTitle';

type ModalProps = Readonly<{
  children?: ReactElement;
  isOpen: boolean;
  sizeClass?: string;
  onClose: () => void;
  modalTitle?: string;
  name?: string;
}>;

export const Modal: FC<ModalProps> = ({ isOpen, children, sizeClass, onClose, modalTitle, name }: ModalProps): ReactElement => {
  const panelClass = sizeClass ?? 'max-w-md';
  const prefix = name ? `${name}-` : '';

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div data-testid={`${prefix}${'modal'}`} className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={`${panelClass} w-full transform rounded bg-white p-6 align-middle shadow-xl transition-all mt-12`}>
                  <Fragment>
                    <CrossIcon
                      data-testid="modal-close-icon"
                      onClick={onClose}
                      className="absolute top-2 right-2 text-gray-300 font-thin w-5 cursor-pointer"
                    />
                    {modalTitle && <ModalTitle modalTitle={modalTitle} />}
                    {children}
                  </Fragment>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
