import type { CommonQuestion } from './schema';
import type { GuideImage } from '../../../api/queries/forms/forms.types';
import type { GuideImageLabel, Question } from '../shared/types';

export const parseGuideImages = (guideImages?: GuideImage[]): CommonQuestion['guideImages'] => {
  const images = {
    van: [],
    car: [],
    truck: [],
    shared: [],
    suv: [],
  } as Record<GuideImageLabel, GuideImage[]>;

  if (!guideImages) return images;

  guideImages.forEach((image) => {
    const { tags } = image;
    const label = (tags?.length ? tags[0]?.split(':')?.[1] : 'shared') as GuideImageLabel;
    images[label].push(image);
  });

  return images;
};

export const mapGuideImages = (question?: CommonQuestion): { guideImages?: Question['guideImages'] } => {
  const { guideImages } = question ?? {};

  if (!guideImages) return {};

  return {
    guideImages: Object.values(guideImages)
      .filter((image) => image !== undefined)
      .flat(),
  };
};
