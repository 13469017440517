import { useEffect, useRef } from 'react';

/* react-hook-form does not rerender by default so you need to destructure the formState object to force rerender
  see https://react-hook-form.com/docs/useform/formstate
  this hook make use of the destructured errors you can ignore the returned value
  or use is instead invalid status
 */
export const useWatchFormErrors = <T extends Record<string, unknown>>(errors: T): boolean => {
  const isError = useRef(false);

  useEffect(() => {
    isError.current = Object.keys(errors).length > 0;
  }, [errors]);

  return isError.current;
};
