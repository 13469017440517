import classnames from 'classnames';
import Select from 'react-select';

import type { OptionType } from '../../../../common/types';
import type { FormField, SelectFormControlConfig } from '../../shared/types';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';
import type { SingleValue } from 'react-select';

import { isOptionType } from '../../../../common/types';
import { noOptionsMessage } from '../../../../common/utils/noOptionsMessage';
import { Loading } from '../../../../components/Loaders/Loading/Loading';
import { getSelectClassNamesConfig } from '../../utils/getSelectClassnamesConfig';
import { getSelectValue } from '../../utils/getSelectValue';

type SelectFormControlProps<TValues extends FieldValues> = SelectFormControlConfig<TValues> & FormField<TValues>;

export const SelectFormControl = <TValues extends FieldValues>({
  field,
  name,
  isClearable,
  options,
  placeholder,
  disabled,
  controlClassName,
  menuClassName,
  inputValue,
  onInputChange,
  onChange,
  isFetching,
  hideArrow,
  value,
}: SelectFormControlProps<TValues>): ReactElement => {
  const fieldValue = value || field?.value;

  const onChangeHandler = (option: SingleValue<OptionType>) => {
    field?.onChange(option?.value);
    onChange?.(option);
  };

  return (
    <div data-testid={`${name}-select`} className="relative">
      <Select
        options={options}
        isDisabled={disabled}
        menuPlacement="auto"
        inputId={`select-${name}`}
        classNames={getSelectClassNamesConfig(menuClassName)}
        inputValue={inputValue}
        classNamePrefix="react-select"
        placeholder={placeholder}
        components={{ IndicatorSeparator: () => null }}
        className={classnames('h-10 focus:border-blue-200 rounded text-black font-normal', controlClassName)}
        onChange={onChangeHandler}
        onInputChange={onInputChange}
        noOptionsMessage={noOptionsMessage}
        onBlur={field?.onBlur}
        value={isOptionType(value) ? value : getSelectValue(options, fieldValue as string, isFetching)}
        isSearchable={true}
        isClearable={(isClearable && !isFetching) ?? false}
        key={name}
      />
      {isFetching && <div className={`absolute top-2 ${hideArrow ? 'right-2' : ' right-11'}`}>{<Loading />}</div>}
    </div>
  );
};
