import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { Fragment, useMemo, useState } from 'react';

import type { Active, Over, Modifier } from '@dnd-kit/core';
import type { ReactElement, ReactHTML, ReactNode } from 'react';

import { DraggableItem } from '../DraggableItem/DraggableItem';
import { DraggableOverlay } from '../DraggableOverlay/DraggableOverlay';
import { DragHandle } from '../DragHandle/DragHandle';

type BaseItem = string;

type DraggableListProps<T extends BaseItem> = {
  items: T[];
  as?: keyof ReactHTML;
  className?: string;
  axis?: 'x' | 'y' | 'not-set';
  onChange(items: T[]): void;
  renderItem(item: T): ReactNode;
  onDragStart?(id: string): void;
};

export const calculateOnDragEnd = <T extends BaseItem>(items: T[], active: Active, over: Over): T[] => {
  const activeIndex = items.findIndex((item) => item === active.id);
  const overIndex = items.findIndex((item) => item === over.id);
  return arrayMove(items, activeIndex, overIndex);
};

export const DraggableList = <T extends BaseItem>({
  items,
  as: Component = 'ul',
  className,
  onChange,
  renderItem,
  onDragStart,
  axis = 'y',
}: DraggableListProps<T>): ReactElement => {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(() => items.find((item) => item === active?.id), [active, items]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  let modifiers: Modifier[] = [];

  if (axis === 'y') {
    modifiers = [restrictToVerticalAxis];
  }

  if (axis === 'x') {
    modifiers = [restrictToHorizontalAxis];
  }

  return (
    <DndContext
      sensors={sensors}
      modifiers={modifiers}
      onDragStart={({ active }) => {
        setActive(active);
        onDragStart?.(String(active.id));
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          onChange(calculateOnDragEnd(items, active, over));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <Component className={className} role="application">
          {items.map((item) => (
            <Fragment key={item}>{renderItem(item)}</Fragment>
          ))}
        </Component>
      </SortableContext>
      <DraggableOverlay>{activeItem ? renderItem(activeItem) : null}</DraggableOverlay>
    </DndContext>
  );
};

DraggableList.Item = DraggableItem;
DraggableList.DragHandle = DragHandle;
