import 'jsoneditor/dist/jsoneditor.css';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { Metadata } from '../../shared/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { EditableNode, ValidationError } from 'jsoneditor';
import type { ReactElement } from 'react';
import type { ZodType } from 'zod';

import { clearNullishValues } from '../../../../common/utils/clearNullValues';
import { deepCompare } from '../../../../common/utils/deepCompare';
import { Editor } from '../../../../modules/json-editor/components/Editor/Editor';
import { getAnswerCleanedMetaData } from '../../utils/getAnswerCleanedMetaData';
import { getMetadataSchemaKeys, getTopLevelMetadataSchemaKeys } from '../../utils/getMetadataSchemaKeys';
import { isEditable } from '../../utils/IsEditable';
import { processKeys } from '../../utils/processKeys';

type MetadataEditorProps = Readonly<{
  answer: CreateEditAnswer;
  schema: ZodType;
  onChange?: (metaData: Metadata) => void;
}>;

export const MetadataEditor = ({ answer, schema, onChange }: MetadataEditorProps): ReactElement => {
  const prohibitedKeys = useMemo(() => processKeys(getMetadataSchemaKeys(schema)), [schema]);
  const topLevelSchemaProhibitedKeys = useMemo(() => getTopLevelMetadataSchemaKeys(schema), [schema]);
  const updaterFnRef = useRef<(metadata: Metadata) => void>(() => ({}));
  const validatorFnRef = useRef<() => void>(() => ({}));

  const cleanMetaData = getAnswerCleanedMetaData(answer);

  const [jsonData, setJsonData] = useState(() => cleanMetaData);
  const alreadyIncludedAtMetadataRef = useRef<Array<keyof Metadata>>(Object.keys(cleanMetaData));

  useEffect(() => {
    const metadata = getAnswerCleanedMetaData(answer);
    const newJsonData = clearNullishValues(jsonData);
    setJsonData(clearNullishValues({ ...newJsonData, ...metadata }));

    updaterFnRef.current(newJsonData);

    alreadyIncludedAtMetadataRef.current = Object.keys(clearNullishValues(metadata));
    validatorFnRef.current();
  }, [answer]);

  const onEditable = (node: EditableNode | object) => {
    if (!isEditable(node) || !Array.isArray(node.path)) {
      return true;
    }

    const nodePathString = node.path.join('.');
    if (!nodePathString) {
      return true;
    }

    const allProhibitedKeys = prohibitedKeys.concat(topLevelSchemaProhibitedKeys);
    const isNodePathProhibited = allProhibitedKeys.includes(node.field);
    return !isNodePathProhibited;
  };

  const handleOnChange = (newData: Metadata) => {
    if (deepCompare(newData, jsonData)) {
      return;
    }

    setJsonData(newData);
    onChange?.(newData);
  };

  const onValidate = (json: Metadata) => {
    const errors: ValidationError[] = [];

    const alreadyIncludedMetadataSet = new Set([...alreadyIncludedAtMetadataRef.current]);

    for (const key in json) {
      if (!topLevelSchemaProhibitedKeys.includes(key)) {
        continue;
      }
      if (alreadyIncludedMetadataSet.has(key)) {
        alreadyIncludedMetadataSet.delete(key);
        continue;
      }
      errors.push({
        path: [key],
        message: `The key "${key}" is not allowed.`,
      });
    }
    return errors;
  };

  return (
    <Editor
      className="w-full"
      value={jsonData}
      onChange={handleOnChange}
      onEditable={onEditable}
      onValidate={onValidate}
      onSetUpdate={(updater) => {
        updaterFnRef.current = updater;
      }}
      onSetReValidate={(reValidate) => {
        validatorFnRef.current = reValidate;
      }}
    />
  );
};
