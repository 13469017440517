import type { FormType } from '../../../../api/queries/formTypes/formTypes.types';
import type { FC, ReactElement } from 'react';

import { useDeleteFormType } from '../../../../api/hooks/useDeleteFormType';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';

type DeleteFormTypeModalProps = Readonly<{
  isOpen: boolean;
  onCancel: () => void;
  onSubmitClose: () => void;
  formTypeToDelete?: FormType;
}>;

export const DeleteFormTypeModal: FC<DeleteFormTypeModalProps> = ({ isOpen, formTypeToDelete, onCancel }): ReactElement | null => {
  const { mutate } = useDeleteFormType({ onSuccess: onCancel });

  return (
    <DialogModal
      name="delete-form-type"
      isOpen={isOpen}
      onClose={onCancel}
      onCancelButton={onCancel}
      submitButtonText="Delete"
      onSubmitButton={() => mutate(formTypeToDelete?.id as string)}
    >
      <p data-testid="delete-form-type-msg" className="font-bold text-blue-800 p-5">
        Delete Form Type - {formTypeToDelete?.name}
      </p>
    </DialogModal>
  );
};
