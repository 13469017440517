import type { CreateEditAnswer } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

export const onSetIsFreeFormTextHandler = ({
  watch,
  name,
  setValue,
}: Omit<UseFormObserverProps<CreateEditAnswer>, 'handlersMap'>): void => {
  const newNameValue = watch(name!);
  if (!newNameValue) {
    setValue('notesLabel', '');
    setValue('showNotes', false);
  }
};
