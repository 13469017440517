import { useEffect, useMemo } from 'react';

import type { Answer } from '../../shared/types';
import type { CreateEditAnswer } from '../../utils/schema';

import { CREATE_ANSWER_INIT_FORM_VALUES } from '../../utils/constants';
import { mapAnswerStateToFormState } from '../../utils/mapAnswerStateToFormState';
import { useGetPrepopulatedItemSearch } from '../useGetPrepopulatedItemSearch/useGetPrepopulatedItemSearch';

type UseGetDefaultFormData = Readonly<{
  defaultValues: CreateEditAnswer;
  isFetching: boolean;
}>;

type UseGetDefaultFormDataParams = {
  answerToEdit?: Answer;
  defaultAnswers?: string;
  onChange: (defaultValues: CreateEditAnswer) => void;
};

export const useGetDefaultFormData = ({ answerToEdit, defaultAnswers, onChange }: UseGetDefaultFormDataParams): UseGetDefaultFormData => {
  const { itemSearch, itemSearchFetching } = useGetPrepopulatedItemSearch({
    itemCode: answerToEdit?.metaData?.artCode?.itemCode,
    subItemCode: answerToEdit?.metaData?.artCode?.itemSubCode,
  });

  const defaultValues = useMemo(() => {
    return answerToEdit ? mapAnswerStateToFormState({ answer: answerToEdit, itemSearch, defaultAnswers }) : CREATE_ANSWER_INIT_FORM_VALUES;
  }, [answerToEdit, itemSearch]);

  useEffect(() => {
    onChange(defaultValues);
  }, [defaultValues]);

  return { defaultValues, isFetching: itemSearchFetching };
};
