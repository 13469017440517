import { Dialog } from '@headlessui/react';
import { CrossIcon } from '@prism2/icons-react/prism';
import { Fragment } from 'react';

import type { FC, ReactElement } from 'react';

import { ModalTitle } from '../ModalTitle/ModalTitle';

type ModalProps = Readonly<{
  children?: ReactElement;
  isOpen: boolean;
  sizeClass?: string;
  onClose: () => void;
  modalTitle?: string;
}>;

export const SimpleModal: FC<ModalProps> = ({ isOpen, children, sizeClass, onClose, modalTitle }: ModalProps): ReactElement => {
  const panelClass = sizeClass ?? 'max-w-md';
  return (
    <Dialog as="div" open={isOpen} className="relative z-10" onClose={onClose}>
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <Dialog.Panel className={`${panelClass} w-full rounded bg-white p-6 align-middle shadow-xl relative`}>
            <Fragment>
              <CrossIcon
                data-testid="modal-close-icon"
                onClick={onClose}
                className="absolute top-2 right-2 text-gray-300 font-thin w-5 cursor-pointer"
              />
              {modalTitle && <ModalTitle modalTitle={modalTitle} />}
              {children}
            </Fragment>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};
