import type { Answer } from '../../shared/types';
import type { FC, ReactElement } from 'react';

type GroupedAnswersHeaderParams = { answers: Array<Answer> };

export const GroupedAnswersHeader: FC<GroupedAnswersHeaderParams> = (props: GroupedAnswersHeaderParams): ReactElement =>
  props.answers.length ? (
    <p data-testid="grouped-answers-msg" className="text-gray-600 text-sm mb-4">
      Drag and drop Answers from the left to Groups on the right. All answers must be in at least one group, but can be assigned to multiple
      groups
    </p>
  ) : (
    <p data-testid="empty-answers-msg" className="text-gray-600 text-sm mb-4">
      Please add Answers to this question in order to assign Groups
    </p>
  );
