import { useState } from 'react';
import Select from 'react-select';

import type { EnvironmentType, UpdateModalDataType } from '../shared/types';
import type { FC, ReactElement } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { SwitchField } from '../../../components/SwitchField/SwitchField';

type UpdateFormModalProps = Readonly<{
  isOpen: boolean;
  closeModal: () => void;
  onChange: (UpdateModalDataType?: UpdateModalDataType) => void;
  selectedFormData?: UpdateModalDataType;
}>;

export const UpdateFormModal: FC<UpdateFormModalProps> = ({ isOpen, closeModal, selectedFormData, onChange }): ReactElement | null => {
  const version = selectedFormData?.selectedVersion || '';
  const name = selectedFormData?.formName || '';
  const formName = `${name} ${version} - Update Form`;
  const [isLatest, updateIsLatest] = useState<boolean>(true);
  const [isTest, updateIsTest] = useState<boolean>(true);
  const [updateBtnDisable, setUpdateBtnDisable] = useState<boolean>(true);
  const [environment, updateEnvironment] = useState<string>();
  const items = selectedFormData?.environments.flatMap((env: EnvironmentType) => ({ value: env.environment, label: env.environment }));
  const statusText = { active: 'ON', inactive: 'OFF' };

  const closeUpdateModal = () => {
    submitChange();
    closeModal();
    resetModalState();
  };

  const resetModalState = () => {
    setUpdateBtnDisable(true);
    updateIsTest(true);
    updateIsLatest(true);
  };

  const submitChange = () => {
    if (selectedFormData) {
      const copyDeployFormData = {
        ...selectedFormData,
        isTest,
        isLatest,
        selectedEnvironment: environment,
      };
      onChange(copyDeployFormData);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        closeModal(), resetModalState();
      }}
      modalTitle={formName}
    >
      <>
        <div className="grid grid-cols-auto gap-4" data-testid="update-modal-form">
          <div className="col-start-1 col-span-5">
            <div className="flex flex-row">
              <div className="basis-2/3 py-3 px-3">Is Test</div>
              <div className="basis-1/4">
                <SwitchField name="isTestField" value={isTest} onChange={(value) => updateIsTest(value)} statusText={statusText} />
              </div>
            </div>
          </div>
          <div className="col-start-6 col-span-4">
            <div className="flex flex-row">
              <div className="basis-2/3 py-2 px-2">Is Latest</div>
              <div className="basis-1/4">
                <SwitchField name="isLatestField" value={isLatest} onChange={(value) => updateIsLatest(value)} statusText={statusText} />
              </div>
            </div>
          </div>
          <div className="col-start-6 col-span-4 p-3" data-testid="environment-select">
            <span>Environment</span>
            <Select
              name="environmentSelect"
              inputId="environmentSelect"
              placeholder="Select one..."
              options={items}
              onChange={(value) => {
                updateEnvironment(value?.value), setUpdateBtnDisable(false);
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-auto gap-4 mt-8">
          <div className="col-start-1 col-span-5 p-2">
            <button
              className="qe-btn"
              onClick={() => {
                closeModal(), resetModalState();
              }}
            >
              CANCEL
            </button>
          </div>
          <div className="col-start-7 col-span-3 p-2">
            <button className="qe-btn fill w-full" type="button" onClick={closeUpdateModal} disabled={updateBtnDisable}>
              UPDATE
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
