import { useState } from 'react';

import type { ColumnFilter, ColumnSort, SortingState, Updater } from '@tanstack/react-table';

import { useTableFilters } from '../../../../common/hooks/useTableFilters';

type UseEditModeWithTableState = Readonly<{
  isEditMode: boolean;
  toggleFilter: () => void;
  setFilters: (updaterOrValue: Updater<ColumnFilter[]>, skipOnChange?: boolean) => void;
  isFilter: boolean;
  setSorting?: (value: Updater<ColumnSort[]>) => void;
  sorting: ColumnSort[];
  filters: ColumnFilter[];
  toggleEditMode: () => void;
}>;

export const useEditModeWithTableState = (): UseEditModeWithTableState => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isEditMode, setIsEditMode] = useState(true);

  const { isFilter, filters, setFilters, toggleFilter, closeFilters } = useTableFilters();

  const checkToggleFilter = () => {
    if (isEditMode) {
      toggleFilter();
    }
  };

  const toggleEditMode = () => {
    setIsEditMode((prev) => !prev);
    setFilters([]);
    setSorting([]);
    closeFilters();
  };

  const setSortingFn = isEditMode ? setSorting : undefined;

  return { isEditMode, toggleEditMode, toggleFilter: checkToggleFilter, isFilter, filters, setFilters, sorting, setSorting: setSortingFn };
};
