import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { CreateFormTypeParams } from '../queries/formTypes/formTypes.types';

import { QueryKey } from '../constants';
import { createFormType } from '../queries/formTypes/formTypes.api';

export type UseCreateFormTypeParams = Readonly<{
  onClose: () => void;
}>;

export type UseCreateFormType = Readonly<{
  mutate: (values: CreateFormTypeParams) => void;
  isLoading: boolean;
}>;

export const useCreateFormTypeMutation = ({ onClose }: UseCreateFormTypeParams): UseCreateFormType => {
  const queryClient = useQueryClient();

  const onRequestSuccess = () => {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_TYPE]);
    toast(`Successfully created`, { type: 'success' });
    onClose();
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || `Failed to create`;
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: CreateFormTypeParams) => createFormType(values),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
