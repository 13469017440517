import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { createFormVersion } from '../queries/forms/forms.api';

type UseCreateForm = Readonly<{
  mutate: (formName: string) => void;
  isLoading: boolean;
}>;

type UseCreateFormVersionParams = { onSuccess: () => void };

export const useCreateFormVersion = ({ onSuccess }: UseCreateFormVersionParams): UseCreateForm => {
  const onRequestSuccess = () => {
    onSuccess();
    toast(`Successfully created`, { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || `Failed to create`;
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (formName: string) => createFormVersion(formName),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
