import jwtDecode from 'jwt-decode';

import type { DecodedJWT } from './types';

import { startCase } from '../../../common/utils/startCase';

const toSnakeCase = (str: string): string => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

export const toUrlEncoded = (obj: Record<string, string>): string => {
  return Object.keys(obj)
    .map((k) => encodeURIComponent(toSnakeCase(k)) + '=' + encodeURIComponent(obj[k]))
    .join('&');
};

export const setAuthHeaders = (headers?: Record<string, string>): Record<string, string> | undefined => {
  const token = localStorage.getItem('auth');

  if (token) {
    return {
      ...headers,
      Authorization: token,
    };
  }

  return headers;
};

export const getMasheryToken = (): string | undefined => {
  const json = localStorage.getItem('auth');

  if (!json) {
    return undefined;
  }

  return jwtDecode<DecodedJWT>(json)?.masheryToken;
};

type DeriveNameFromEmail = {
  firstName: string;
  secondName: string;
  userId: string;
};

export const deriveNameFromEmail = (email: string): DeriveNameFromEmail => {
  const [namePart] = email.split('@');
  const [firstName, secondName = ''] = startCase(namePart).split(' ');

  return {
    firstName,
    secondName,
    userId: secondName ? `${firstName.charAt(0)}${secondName}`.toLowerCase() : firstName.toLowerCase(),
  };
};
