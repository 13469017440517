import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { CopyFormParams } from '../queries/forms/forms.types';

import { QueryKey } from '../constants';
import { copyForm } from '../queries/forms/forms.api';

type UseCopyFormParams = Readonly<{
  onSuccess: () => void;
}>;

export type UseCopyForm = Readonly<{
  mutate: (params: CopyFormParams) => void;
  isLoading: boolean;
}>;

export const useCopyForm = ({ onSuccess }: UseCopyFormParams): UseCopyForm => {
  const queryClient = useQueryClient();

  const onRequestSuccess = () => {
    onSuccess();
    void queryClient.invalidateQueries([QueryKey.FETCH_FORMS]);
    toast('Successfully copied', { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Copying failed';
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: copyForm,
    onSuccess: onRequestSuccess,
    onError: onErrorRequest,
  });

  return {
    mutate,
    isLoading,
  };
};
