import { useQuery } from '@tanstack/react-query';

import type { OptionType } from '../../../../common/types';

import { ArtProxyAction, QueryKey } from '../../../../api/constants';
import { getSeverityOptions } from '../../../../api/queries/artProxy/artProxy.api';

export type UseDamageSeverityData = Readonly<{
  data: OptionType[] | undefined;
  isFetching: boolean;
}>;

type UseDamageDataSeverityParams = Readonly<{
  itemCode?: string;
  subItemCode?: string;
  damageCode?: string;
}>;

export const useDamageSeverityData = ({ itemCode, subItemCode, damageCode }: UseDamageDataSeverityParams): UseDamageSeverityData => {
  const queryFn = async (): Promise<OptionType[]> => {
    const { items } = await getSeverityOptions({ itemCode, subItemCode, damageCode });
    return items.map((item) => ({ value: item.code, label: item.description }));
  };
  return useQuery({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_DAMAGE_SEVERITIES, itemCode, subItemCode, damageCode],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(itemCode && subItemCode && damageCode),
  });
};
