import type {
  CopyFormTypeParams,
  CopyFormTypeResponse,
  CreateFormTypeParams,
  CreateFormTypeResponse,
  FetchFormTypeParams,
  GetFormTypeResponse,
} from './formTypes.types';

import { config } from '../../../common/config';
import { appendToQueryIfExists } from '../../utils/appendToQueryIfExists';
import { fetchJson } from '../../utils/fetchJson';

export const fetchFormTypes = ({ formTypeName, id, next, nextLimit, limit }: FetchFormTypeParams = {}): Promise<GetFormTypeResponse> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/types/search`);

  appendToQueryIfExists(url, 'name', formTypeName);

  appendToQueryIfExists(url, 'id', id);
  appendToQueryIfExists(url, 'limit', next ? nextLimit : limit);
  appendToQueryIfExists(url, 'includeCurrentVersion', true);

  if (next) {
    appendToQueryIfExists(url, 'direction', 'next');
  }

  return fetchJson(url, 'GET');
};

export const createFormType = async ({ name, description }: CreateFormTypeParams): Promise<CreateFormTypeResponse> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/types`);

  return fetchJson(url, 'POST', { name, description });
};

export const copyFormType = async ({ name, description, sourceFormGuid }: CopyFormTypeParams): Promise<CopyFormTypeResponse> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/types/copy`);

  return fetchJson(url, 'POST', { name, description, sourceFormGuid });
};

export const deleteFormType = (formId: string): Promise<undefined> => {
  const url = new URL(`${config.API_URL}/forms-proxy/forms/types/id/${formId}`);

  return fetchJson(url, 'DELETE');
};
