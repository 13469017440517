import { AuthService } from '../../modules/auth/services/AuthService';

export const catchApiError = async (response: Response): Promise<never> => {
  const authService = AuthService.getInstance();

  if (response.status === 401) {
    authService.reAuthorize();
  }

  if (response.status === 403) {
    authService.reAuthorize();
  }

  const exception: unknown = await response.json();
  throw new Error((exception as Error).message || response.statusText);
};
