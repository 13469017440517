import type { CommonQuestion } from '../../utils/schema';
import type { ReactElement } from 'react';
import type { Control } from 'react-hook-form';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';

type QuestionCopyAnswerSettingsSection = Readonly<{
  control: Control<CommonQuestion>;
}>;

export const QuestionCopySettingsSection = ({ control }: QuestionCopyAnswerSettingsSection): ReactElement => {
  return (
    <ExpandableFormSection title="Copy Answer Settings">
      <p className="text-xs font-bold text-gray-500 mb-3">Select Answer Settings To Be Copied</p>
      <FormControl
        type="switch"
        label="Copy Answers"
        name="switchControls.isCopyAnswers"
        control={control}
        className="flex justify-between items-center"
      />
    </ExpandableFormSection>
  );
};
