import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import type { Answer, Question, QuestionSetSettings, QuestionsTableRowItem } from './shared/types';
import type { Form } from '../../api/queries/forms/forms.types';
import type { QuestionsPageURLParams } from '../../modules/navigation/utils/getNavigationLink';
import type { FC, ReactElement } from 'react';

import { CopyQuestionModal } from './components/CopyQuestionModal/CopyQuestionModal';
import { CreateEditAnswerModal } from './components/CreateEditAnswerModal/CreateEditAnswerModal';
import { CreateEditQuestionModal } from './components/CreateEditQuestionModal/CreateEditQuestionModal';
import { DeleteQuestionModal } from './components/DeleteQuestionModal/DeleteQuestionModal';
import { QuestionPageControls } from './components/QuestionPageControls/QuestionPageControls';
import { QuestionSetSettingsModal } from './components/QuestionSetSettingsModal/QuestionSetSettingsModal';
import { QuestionsTable } from './components/QuestionsTable/QuestionsTable';
import { useDeleteQuestionModalState } from './hooks/useDeleteQuestionModalState/useDeleteQuestionModalState';
import { useFormById } from './hooks/useFormById/useFormById';
import { useQuestionsContext } from './hooks/useQuestionsContext/useQuestionsContext';
import { useQuestionsTable } from './hooks/useQuestionsTable/useQuestionsTable';
import { deleteQuestion, setInitQuestionsData } from './store/actions';
import { getPersistedEntry, removePersistedEntry } from './utils/persisted';
import { transformQuestions } from './utils/transformQuestions';
import { useUpdateForm } from '../../api/hooks/useUpdateForm';
import { clearNullishValues } from '../../common/utils/clearNullValues';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { DialogModal } from '../../components/DialogModal/DialogModal';
import { Header } from '../../components/Header/Header';
import { Page } from '../../components/Page/Page';
import { useModalState } from '../../modules/form/hooks/useModalState';
import { QuestionEnginePreview } from '../../modules/question-engine';

export const QuestionsPage: FC = (): ReactElement => {
  const navigate = useNavigate();

  const [questionId, setQuestionId] = useState('');
  const [path, setPath] = useState<string | undefined>();
  const [questionSetSettings, setQuestionSetSettings] = useState<QuestionSetSettings>({
    asIs: false,
    autoTag: false,
    metaData: {},
  });
  const { form: formTypeName, formId } = useParams() as QuestionsPageURLParams;
  const { state, dispatch } = useQuestionsContext();
  const { isEdited } = state;
  const { isFetching, data: form } = useFormById({ formTypeName, formId });
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const { mutate, isLoading } = useUpdateForm();

  const {
    open: createEditQuestionOpen,
    close: createEditQuestionClose,
    isOpen: isCreateEditQuestionOpen,
    modalData: createEditQuestionModalData,
    setModalData: setCreateEditQuestionModalData,
  } = useModalState<QuestionsTableRowItem>();

  const {
    open: createEditAnswerOpen,
    close: createEditAnswerClose,
    isOpen: isCreateEditAnswerOpen,
    setModalData: setCreateEditAnswerModalData,
  } = useModalState<Answer>();

  const {
    open: openCopyQuestionModal,
    close: closeCopyQuestionModal,
    isOpen: isCopyQuestionModalOpen,
    modalData: copyQuestionModalData,
    setModalData: setCopyQuestionModalData,
  } = useModalState<QuestionsTableRowItem>();

  const {
    open: openDeleteQuestionModal,
    close: closeDeleteQuestionModal,
    isOpen: isDeleteQuestionModalOpen,
    modalData: deleteModalData,
  } = useDeleteQuestionModalState();

  const { open: formPreviewOpen, close: formPreviewClose, isOpen: formPreviewIsOpen } = useModalState<Form>();

  const {
    open: openQuestionSetSettingsModal,
    close: closeQuestionSetSettingsModal,
    isOpen: isQuestionSetSettingsModalOpen,
  } = useModalState<QuestionSetSettings>();

  useEffect(() => {
    if (!form) {
      return;
    }
    const savedData = getPersistedEntry<Question[]>(form.questionSet.guid);

    if (savedData) {
      dispatch(setInitQuestionsData({ questions: savedData, guid: form.questionSet.guid, isEdited: true }));
      toggleEditMode();
      setIsConfirmationModalOpen(true);
      return;
    }

    setQuestionSetSettings({
      asIs: form.questionSet.asIs || false,
      autoTag: form.questionSet.autoTag || false,
      metaData: clearNullishValues(form.questionSet.metaData),
    });

    dispatch(setInitQuestionsData({ questions: form?.questionSet.items, guid: form.questionSet.guid, isEdited: false }));
  }, [form?.questionSet?.items]);

  const { table, isFilter, toggleFilter, toggleEditMode, isEditMode } = useQuestionsTable({
    questionsData: state.questions,
    openCreateEditQuestionModal: createEditQuestionOpen,
    openDeleteQuestionModal,
    openCopyQuestionModal,
  });

  const onCancelButtonClick = () => {
    if (isEdited) {
      setIsConfirmationModalOpen(true);
    }
  };

  const onDiscard = () => {
    if (!form) {
      return;
    }
    removePersistedEntry(form.questionSet.guid);
    dispatch(setInitQuestionsData({ questions: form.questionSet?.items ?? [], guid: form.questionSet.guid, isEdited: false }));

    if (path) {
      navigate(path);
      setPath(undefined);
    }
  };

  const onDiscardCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const onRedirect = (path: string) => {
    if (isEdited) {
      setIsConfirmationModalOpen(true);
      setPath(path);
    } else {
      navigate(path);
    }
  };

  const onSaveButtonCLick = () => {
    if (!form) {
      return;
    }

    const items = transformQuestions(state.questions);

    mutate({
      guid: formId,
      questionSet: {
        count: state.questions.length,
        formName: formTypeName,
        guid: formId,
        items,
        ...questionSetSettings,
      },
    });
    removePersistedEntry(form.questionSet.guid);
    dispatch(setInitQuestionsData({ questions: state.questions, guid: form.questionSet.guid, isEdited: false }));
  };

  const onDeleteQuestion = (): void => {
    const questionId = (deleteModalData as QuestionsTableRowItem).guid;
    table.toggleAllRowsExpanded(false);
    dispatch(deleteQuestion({ questionId }));
  };

  const onCreateAnswer = (questionId: string) => {
    setQuestionId(questionId);
    createEditAnswerOpen();
  };

  const onSaveQuestionSetSettings = (questionSetSettings: QuestionSetSettings) => {
    if (!form) {
      return;
    }
    mutate({
      guid: formId,
      questionSet: {
        count: form.questionSet.items.length,
        formName: formTypeName,
        guid: formId,
        items: form.questionSet.items,
        ...questionSetSettings,
      },
    });
    setQuestionSetSettings(questionSetSettings);
    closeQuestionSetSettingsModal();
  };

  const crumbs = [{ label: 'Forms', path: '/' }, { label: formTypeName, path: `/${formTypeName}` }, { label: form?.version ?? '' }];
  const title = `${formTypeName} - ${form?.version ?? ''} Questions`;

  return (
    <Page id="questions" isLoading={isFetching || isLoading}>
      <Header title={title} isEdited={isEdited} setIsConfirmationModalOpen={setIsConfirmationModalOpen} setPath={setPath} />
      <div className="px-8 mt-8">
        <div data-testid="questions-table" className="w-full md:w-9/10 lg:w-4/5 xl:w-[1300px] mx-auto flex flex-col mb-12">
          <div className="flex sticky top-12 bg-white z-10 flex-col gap-y-2 pb-2">
            <Breadcrumb crumbs={crumbs} onRedirect={onRedirect} />
            <QuestionPageControls
              onToggleEditMode={toggleEditMode}
              onSaveButtonCLick={onSaveButtonCLick}
              onCancelButtonClick={onCancelButtonClick}
              onToggleFiltering={toggleFilter}
              onPreviewButtonClick={formPreviewOpen}
              onCreateButtonClick={createEditQuestionOpen}
              onEditButtonClick={toggleEditMode}
              onQuestionSetSettingsButtonClick={openQuestionSetSettingsModal}
              isEditMode={isEditMode}
              isEdited={state.isEdited}
            />
          </div>

          <QuestionsTable isEditMode={isEditMode} isFilter={isFilter} table={table} />
        </div>
        <DialogModal
          isOpen={isConfirmationModalOpen}
          onClose={onDiscardCancel}
          onCancelButton={onDiscardCancel}
          onSubmitButton={onDiscard}
          submitButtonText="Discard changes"
          cancelButtonText="Continue editing"
          name="questions"
        >
          <p className="font-bold text-blue-800 p-5">You have unsaved changes. Do you want to continue editing?</p>
        </DialogModal>

        {isCreateEditQuestionOpen && (
          <CreateEditQuestionModal
            isOpen={isCreateEditQuestionOpen}
            onClose={createEditQuestionClose}
            questionToEdit={createEditQuestionModalData}
            onCreateAnswer={onCreateAnswer}
            setCreateEditQuestionModalData={setCreateEditQuestionModalData}
          />
        )}

        {isCreateEditAnswerOpen && (
          <CreateEditAnswerModal
            setCreateEditAnswerModalData={setCreateEditAnswerModalData}
            isOpen={isCreateEditAnswerOpen}
            onClose={createEditAnswerClose}
            questionId={questionId}
          />
        )}

        {isCopyQuestionModalOpen && (
          <CopyQuestionModal
            setCopyQuestionModalData={setCopyQuestionModalData}
            isOpen={isCopyQuestionModalOpen}
            onClose={closeCopyQuestionModal}
            questionToCopy={copyQuestionModalData}
          />
        )}

        {isQuestionSetSettingsModalOpen && (
          <QuestionSetSettingsModal
            isOpen={isQuestionSetSettingsModalOpen}
            onClose={closeQuestionSetSettingsModal}
            onSubmit={onSaveQuestionSetSettings}
            questionSetSettings={questionSetSettings}
          />
        )}

        <DeleteQuestionModal
          isOpen={isDeleteQuestionModalOpen}
          onSubmit={onDeleteQuestion}
          onClose={closeDeleteQuestionModal}
          question={deleteModalData!}
        />

        <QuestionEnginePreview isOpen={formPreviewIsOpen} onClose={formPreviewClose} form={form} formTypeName={formTypeName} />
      </div>
    </Page>
  );
};
