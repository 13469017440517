import type { CommonQuestion } from '../../utils/schema';
import type { Control } from 'react-hook-form';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';

type QuestionOptionsFormSectionProps = Readonly<{
  control: Control<CommonQuestion>;
  title?: string;
}>;

export const QuestionOptionsFormSection = ({ control, title }: QuestionOptionsFormSectionProps) => {
  return (
    <>
      <ExpandableFormSection title={title ?? 'Question Options'}>
        <FormControl
          type="switch"
          label="Apply Filters"
          name="switchControls.isFilters"
          control={control}
          className="flex justify-between items-center"
        />
        <FormControl
          type="switch"
          label="Guide Images"
          name="switchControls.isGuideImages"
          control={control}
          className="flex justify-between items-center"
        />
        <FormControl
          type="switch"
          label="Allow Multiple Responses"
          name="switchControls.isMultipleResponses"
          control={control}
          className="flex justify-between items-center"
        />
        <FormControl
          type="switch"
          label="Client Preferences"
          name="switchControls.isClientPreferences"
          control={control}
          className="flex justify-between items-center"
        />
      </ExpandableFormSection>
    </>
  );
};
