import { isFunction } from '@tanstack/react-table';
import { useCallback, useState } from 'react';

import type { ColumnFilter, Updater } from '@tanstack/react-table';

import { useDebounceValue } from './useDebounceValue';
import { usePreviousValue } from './usePreviousValue';

type UseTableFilter = {
  isFilter: boolean;
  filters: ColumnFilter[];
  setFilters: (updaterOrValue: Updater<ColumnFilter[]>, skipOnChange?: boolean) => void;
  closeFilters: () => void;
  openFilters: () => void;
  toggleFilter: () => void;
  debouncedFilters: ColumnFilter[];
};

export const useTableFilters = (): UseTableFilter => {
  const [isFilter, setIsFilter] = useState(false);
  const [filters, setFiltersState] = useState<ColumnFilter[]>([]);
  const prevFilters = usePreviousValue(filters);
  const debouncedFilters = useDebounceValue(filters);

  const setFilters = useCallback(
    (newFilters: Updater<ColumnFilter[]>) => {
      const filtersData = isFunction(newFilters) ? newFilters(prevFilters || []) : newFilters;
      setFiltersState(filtersData);
    },
    [setFiltersState, prevFilters],
  );

  const resetFilters = useCallback((): void => {
    setFilters([]);
  }, [setFilters]);

  const closeFilters = useCallback((): void => {
    setIsFilter(false);
    resetFilters();
  }, [resetFilters]);

  const openFilters = useCallback((): void => {
    setIsFilter(true);
  }, []);

  const toggleFilter = useCallback((): void => {
    setIsFilter((old) => !old);
    resetFilters();
  }, [resetFilters]);

  return {
    isFilter,
    filters,
    setFilters,
    closeFilters,
    toggleFilter,
    openFilters,
    debouncedFilters,
  };
};
