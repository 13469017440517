import type {
  ARTGetDamagesBody,
  ARTGetDamagesParams,
  ARTGetDamagesResponse,
  ARTGetDescriptionBody,
  ARTGetDescriptionParams,
  ARTGetItemDescriptionBody,
  ARTGetItemDescriptionParams,
  ARTGetItemDescriptionResponse,
  ARTGetSeveritiesBody,
  ARTGetSeveritiesParams,
  ARTGetSeveritiesResponse,
  ArtItemDescription,
} from './artProxy.types';

import { config } from '../../../common/config';
import { ArtProxyAction } from '../../constants';
import { fetchJson } from '../../utils/fetchJson';

const artProxyUrl = new URL(`${config.API_URL}/art-proxy`);

export const getItemDescription = (body: ARTGetItemDescriptionParams): Promise<ARTGetItemDescriptionResponse> => {
  const { signal, ...rest } = body;
  const requestBody: ARTGetItemDescriptionBody = {
    action: ArtProxyAction.ITEM_SEARCH,
    limit: 100,
    offset: 0,
    ...rest,
  };

  return fetchJson(artProxyUrl, 'POST', requestBody, signal);
};

export const getDamageOptions = (body: ARTGetDamagesParams): Promise<ARTGetDamagesResponse> => {
  const requestBody: ARTGetDamagesBody = {
    action: ArtProxyAction.ITEM_DAMAGES,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getSeverityOptions = (body: ARTGetSeveritiesParams): Promise<ARTGetSeveritiesResponse> => {
  const requestBody: ARTGetSeveritiesBody = {
    action: ArtProxyAction.ITEM_DAMAGE_SEVERITIES,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};

export const getDescription = (body: ARTGetDescriptionParams): Promise<ArtItemDescription> => {
  const requestBody: ARTGetDescriptionBody = {
    action: ArtProxyAction.ITEM_BY_ID,
    ...body,
  };
  return fetchJson(artProxyUrl, 'POST', requestBody);
};
