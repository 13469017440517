import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { FormByIdAllEnvs } from '../../../../api/queries/forms/forms.types';

import { QueryKey } from '../../../../api/constants';
import { fetchFormByIDAllEnvs } from '../../../../api/queries/forms/forms.api';

type UseFormByIdParams = Readonly<{
  formTypeName?: string;
  formId: string;
  envAll?: string;
}>;

type UseFormById = Readonly<{
  data?: FormByIdAllEnvs;
  isFetching: boolean;
  error?: Error | null;
}>;

export const useFormByIdAllEnvs = ({ formId }: UseFormByIdParams): UseFormById => {
  const queryFn = async (): Promise<FormByIdAllEnvs> => {
    return await fetchFormByIDAllEnvs(formId);
  };

  const { data, isFetching, error } = useQuery<FormByIdAllEnvs, Error>({
    queryKey: [QueryKey.FETCH_FORM_BY_ID_ALL_ENVS, { formId: formId }],
    queryFn,
    retry: false,
    enabled: !!formId,
    refetchOnWindowFocus: false,
    // TODO: refactor as  https://ghe.coxautoinc.com/MAN-Vintage/questions-engine-frontend/issues/40
    onError: (e) => {
      toast(e.message, { type: 'error' });
    },
  });

  return { isFetching, data, error };
};
