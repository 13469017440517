import classnames from 'classnames';
import CreatableSelect from 'react-select/creatable';

import type { OptionType } from '../../../../common/types';
import type { CreateSelectFormControlConfig, FormField } from '../../shared/types';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';
import type { SingleValue } from 'react-select';

import { noOptionsMessage } from '../../../../common/utils/noOptionsMessage';
import { Loading } from '../../../../components/Loaders/Loading/Loading';
import { getSelectClassNamesConfig } from '../../utils/getSelectClassnamesConfig';
import { getSelectValue } from '../../utils/getSelectValue';

type CreateSelectFormControlProps<TValues extends FieldValues> = CreateSelectFormControlConfig<TValues> & FormField<TValues>;

export const CreateSelectFormControl = <TValues extends FieldValues>({
  field,
  name,
  isClearable,
  options,
  placeholder,
  disabled,
  controlClassName,
  menuClassName,
  onChange,
  isFetching,
  hideArrow,
  components,
}: CreateSelectFormControlProps<TValues>): ReactElement => {
  const onChangeHandler = (option: SingleValue<OptionType>) => {
    field?.onChange(option?.value || '');
    onChange?.(option);
  };

  return (
    <div data-testid={`${name}-select`} className="relative">
      <CreatableSelect
        options={options}
        isDisabled={disabled}
        menuPlacement="auto"
        inputId={`select-${name}`}
        classNames={getSelectClassNamesConfig(menuClassName)}
        classNamePrefix="react-select"
        placeholder={placeholder}
        components={{ IndicatorSeparator: () => null, ...components }}
        className={classnames('h-10 focus:border-blue-200 rounded text-black font-normal', controlClassName)}
        onChange={onChangeHandler}
        noOptionsMessage={noOptionsMessage}
        onBlur={field?.onBlur}
        value={getSelectValue(options, field?.value) ?? undefined}
        isClearable={(isClearable && !isFetching) ?? false}
      />
      {isFetching && <div className={`absolute top-2 ${hideArrow ? 'right-2' : ' right-11'}`}>{<Loading />}</div>}
    </div>
  );
};
