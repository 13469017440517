import type { FetchAnnouncementsResponse } from './announcements.types';

import { config } from '../../../common/config';
import { fetchJson } from '../../utils/fetchJson';

export const fetchAnnouncements = (): Promise<FetchAnnouncementsResponse> => {
  const url = new URL(`${config.API_URL}/announcements-proxy/inventory/announcements/dictionary`);

  return fetchJson(url, 'GET');
};
