import { useEffect } from 'react';

import type { RefObject } from 'react';

type UseMouseClickOutRefParams = Readonly<{
  ref: RefObject<HTMLDivElement>;
  callback: () => void;
  isActive: boolean;
}>;

export const useMouseClickOutRef = ({ ref, callback, isActive }: UseMouseClickOutRefParams): void => {
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      callback();
    }
  };

  useEffect(() => {
    if (isActive) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isActive]);
};
