import type { QuestionAction } from './actions';
import type { QuestionsState } from './reducer';

import { ActionType } from './actions';
import { persistEntry } from '../utils/persisted';

const EXCLUDED_ACTIONS = [ActionType.SET_INIT_QUESTIONS_DATA];

export const persistQuestionsMiddleware = (state: QuestionsState, action: QuestionAction): void => {
  if (EXCLUDED_ACTIONS.includes(action.type)) {
    return;
  }

  const questions = state.questions;
  const guid = state.guid;

  persistEntry(guid, questions);
};
