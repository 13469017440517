import { useRef } from 'react';

import type { Metadata } from '../../shared/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { ZodType } from 'zod';

import { Modal } from '../../../../components/Modal/Modal';
import { MetadataEditor } from '../MetadataEditor/MetadataEditor';

type MetadataModalParams = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onChange: (metaData?: Metadata) => void;
  answer: CreateEditAnswer;
  schema: ZodType;
}>;

export const MetadataModal: FC<MetadataModalParams> = ({
  isOpen,
  onClose,
  onChange,
  answer,
  schema,
}: MetadataModalParams): ReactElement => {
  const metadataStored = useRef(answer.metaData);

  const handleOnChange = (metaData: Metadata) => {
    metadataStored.current = metaData;
  };

  const handleOnClose = () => {
    onClose();
    onChange(metadataStored.current);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} modalTitle="Metadata" sizeClass="w-full md:w-4/5 lg:w-2/3">
      <>
        <MetadataEditor answer={answer} schema={schema} onChange={handleOnChange} />
        <div data-testid="button-actions" className="w-full flex justify-end mt-3">
          <button type="reset" data-testid="cancel-btn" className="qe-btn w-20" onClick={handleOnClose}>
            Close
          </button>
        </div>
      </>
    </Modal>
  );
};
