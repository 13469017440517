import { useContext } from 'react';

import type { QuestionAction } from '../../store/actions';
import type { QuestionsState } from '../../store/reducer';
import type { Dispatch } from 'react';

import { QuestionsContext } from '../../components/QuestionsProvider/QuestionsProvider';

type UseQuestionsContext = Readonly<{
  state: QuestionsState;
  dispatch: Dispatch<QuestionAction>;
}>;

export const useQuestionsContext = (): UseQuestionsContext => {
  const context = useContext(QuestionsContext);
  if (!context) {
    throw new Error('QuestionsContext must be used within a QuestionsProvider');
  }
  return context;
};
