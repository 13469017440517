import type { Form } from '../../../../api/queries/forms/forms.types';
import type { FC, ReactElement } from 'react';

import { useCopyForm } from '../../../../api/hooks/useCopyForm';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';

type CopyFormModalProps = Readonly<{
  isOpen: boolean;
  formTypeName: string;
  onSuccess: () => void;
  onCancel: () => void;
  formToCopy?: Form;
  modalName?: string;
}>;

export const CopyFormModal: FC<CopyFormModalProps> = ({
  isOpen,
  formToCopy,
  formTypeName,
  onSuccess,
  onCancel,
  modalName,
}): ReactElement | null => {
  const { mutate } = useCopyForm({ onSuccess });

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onCancel}
      onCancelButton={onCancel}
      submitButtonText="Copy"
      onSubmitButton={() => mutate({ formTypeName, sourceFormGuid: formToCopy?.questionSet?.guid as string })}
      name={modalName}
    >
      <p className="font-bold text-blue-800 p-5">Copy Question Set - {`${formTypeName} - ${formToCopy?.version as string}`}</p>
    </DialogModal>
  );
};
