import type { MoveDirection } from '../shared/types';

export const moveElementAtArray = <T>(arr: T[], index: number, direction: MoveDirection): T[] => {
  const newArr = [...arr];
  if (index < 0 || index >= newArr.length) {
    return arr;
  }
  const newIndex = direction === 'up' ? index - 1 : index + 1;
  if (newIndex < 0 || newIndex >= newArr.length) {
    return newArr;
  }
  [newArr[index], newArr[newIndex]] = [newArr[newIndex], newArr[index]];
  return newArr;
};
