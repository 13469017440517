import classnames from 'classnames';

import type { FormField, TextAreaFormControlConfig } from '../../shared/types';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';

type TextAreaFormControlProps<TValues extends FieldValues> = TextAreaFormControlConfig<TValues> & FormField<TValues>;

export const TextAreaFormControl = <TValues extends FieldValues>({
  field,
  name,
  placeholder,
  disabled,
  controlClassName,
}: TextAreaFormControlProps<TValues>): ReactElement => {
  return (
    <textarea
      {...field}
      id={name}
      data-testid={`${name}-textarea`}
      placeholder={placeholder}
      disabled={disabled}
      className={classnames(
        'focus:border-blue-200 border rounded font-normal',
        {
          'text-gray-400 border-gray-400 bg-gray-50': disabled,
          'text-black': !disabled,
        },
        controlClassName,
      )}
    />
  );
};
