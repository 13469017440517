import { components } from 'react-select';

import type { OptionType } from '../../../../common/types';
import type { MenuListProps } from 'react-select';

import { MAX_VISIBLE_DROPDOWN_OPTIONS } from '../../shared/constants';

// Need for react-select to customize behavior of the dropdown: limit options number
export const MenuList = ({ children, isMulti, ...props }: MenuListProps<OptionType>) => {
  return (
    <components.MenuList isMulti={false} {...props}>
      {Array.isArray(children) && children.slice(0, MAX_VISIBLE_DROPDOWN_OPTIONS)}
    </components.MenuList>
  );
};
