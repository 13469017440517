import { InfoIcon } from '@prism2/icons-react/prism';
import { Controller, type FieldValues } from 'react-hook-form';

import type {
  CreateSelectFormControlConfig,
  InputFormControlConfig,
  MultiSelectFormControlConfig,
  NumberInputFormControlConfig,
  SelectFormControlConfig,
  SwitchFormControlConfig,
  TextAreaFormControlConfig,
} from '../../shared/types';
import type { ReactElement } from 'react';

import { IconTooltip } from '../../../../components/InfoTip/IconTooltip';
import { Label } from '../../../../components/Label/Label';
import { CreateSelectFormControl } from '../CreateSelectFormControl/CreateSelectFormControl';
import { FormErrorNotification } from '../FormErrorNotifications/FormErrorNotification';
import { InputFormControl } from '../InputFormControl/InputFormControl';
import { MultiSelectFormControl } from '../MultiSelectFormControl/MultiSelectFormControl';
import { NumberInputFormControl } from '../NumberInputFormControl/NumberInputFormControl';
import { SelectFormControl } from '../SelectFormControl/SelectFormControl';
import { SwitchFormControl } from '../SwitchFormControl/SwitchFormControl';
import { TextAreaFormControl } from '../TextAreaFormControl/TextAreaFormControl';

type FormControlProps<TValues extends FieldValues> =
  | InputFormControlConfig<TValues>
  | NumberInputFormControlConfig<TValues>
  | TextAreaFormControlConfig<TValues>
  | SelectFormControlConfig<TValues>
  | CreateSelectFormControlConfig<TValues>
  | MultiSelectFormControlConfig<TValues>
  | SwitchFormControlConfig<TValues>;

export const FormControl = <T extends FieldValues>(props: FormControlProps<T>): ReactElement => {
  const { tooltip, type, className, control, name, label, required, isSubmitting, fieldConfig, labelClass, showErrorMessage } = props;
  return (
    <div className={className}>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => (
          <>
            <div data-testid={`${name}-${type}-box`} className="flex">
              <Label name={name} label={label} required={required} className={labelClass} />
              {tooltip && (
                <IconTooltip text={tooltip}>
                  <InfoIcon data-testid="info-tip-icon" className="h-3 text-blue-800 cursor-pointer ml-2" />
                </IconTooltip>
              )}
            </div>
            {type === 'input' && <InputFormControl {...props} field={field} />}
            {type === 'number-input' && <NumberInputFormControl {...props} field={field} />}
            {type === 'textarea' && <TextAreaFormControl {...props} field={field} />}
            {type === 'create-select' && <CreateSelectFormControl {...props} {...fieldConfig} field={field} />}
            {type === 'multi-select' && <MultiSelectFormControl {...props} field={field} />}
            {type === 'select' && <SelectFormControl {...props} {...fieldConfig} field={field} />}
            {type === 'switch' && <SwitchFormControl {...props} field={field} />}
            {showErrorMessage && (
              <FormErrorNotification fieldState={fieldState} isSubmitting={isSubmitting} disabled={fieldConfig?.disabled} />
            )}
          </>
        )}
      />
    </div>
  );
};
