import type { CommonQuestion } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

import { setValues } from '../../../modules/form/utils/setValues';

export const onSetIsGuideImagesHandler = ({ watch, name, setValue }: Omit<UseFormObserverProps<CommonQuestion>, 'handlersMap'>): void => {
  const newNameValue = watch(name!);

  if (!newNameValue) {
    setValues(setValue, {
      'guideImages.van': [],
      'guideImages.car': [],
      'guideImages.truck': [],
      'guideImages.shared': [],
      'guideImages.suv': [],
    });
  }
};
