import { useContext } from 'react';

import { DraggableItemContext } from '../shared/DraggableItemContext';

export const useDraggableItemContext = () => {
  const context = useContext(DraggableItemContext);

  if (!context) {
    throw new Error('useSortableItemContext must be used within a SortableItemContextProvider');
  }

  return context;
};
