import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { QuestionSetParams, UpdateFormData } from '../queries/shared/types';

import { QueryKey } from '../constants';
import { updateForm } from '../queries/forms/forms.api';

type UseUpdateForm = Readonly<{
  mutate: (values: UpdateFormData) => void;
  isLoading: boolean;
}>;

export const useUpdateForm = (): UseUpdateForm => {
  const queryClient = useQueryClient();

  const onRequestSuccess = () => {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM]);
    toast(`Successfully updated`, { type: 'success' });
  };

  const onErrorRequest = () => {
    toast('Failed to update form', { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: UpdateFormData) => {
      if (values.questionSet) {
        const questionSubmission = values.questionSet.items.map((question) => {
          return {
            ...question,
            order: undefined,
            parent: undefined,
            clientValue: undefined,
            clientAccountNumber: undefined,
            answers: question.answers.map((answer) => {
              return { ...answer, id: undefined };
            }),
          };
        });
        const questionnaireSubmission = {
          ...values.questionSet,
          items: questionSubmission,
          guid: undefined,
          count: undefined,
          formName: undefined,
        };
        const questionnaireSubmissionNoUndefined = JSON.parse(JSON.stringify(questionnaireSubmission)) as QuestionSetParams;
        return updateForm({ ...values, questionSet: questionnaireSubmissionNoUndefined });
      }
      return updateForm(values);
    },
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
