import type { QuestionAction } from './actions';
import type { Question } from '../shared/types';

import { ActionType } from './actions';
import { moveElementAtArray } from '../utils/arrayUtils';
import { deleteQuestionAndUpdateReferences } from '../utils/deleteQuestionAndUpdateReferences';
import { getNewQuestionsSet } from '../utils/getNewQuestionsSet';
import { getNewQuestionsSetFromAnswers } from '../utils/getNewQuestionsSetFromAnswers';
import { mapAPIQuestionToDomainQuestion } from '../utils/mapApiToDomain';

export type QuestionsState = Readonly<{
  questions: Array<Question>;
  isEdited: boolean;
  guid: string;
}>;

export const initialState: QuestionsState = {
  questions: [],
  isEdited: false,
  guid: '',
};

export const questionsReducer = (state: QuestionsState = initialState, action: QuestionAction): QuestionsState => {
  switch (action.type) {
    case ActionType.SET_INIT_QUESTIONS_DATA: {
      const { questions, guid, isEdited } = action.payload;
      return {
        ...state,
        guid,
        isEdited,
        questions: mapAPIQuestionToDomainQuestion(questions),
      };
    }

    case ActionType.EDIT_QUESTION: {
      const { questionId, updatedQuestion } = action.payload;
      const questions = state.questions.map((question) => (question.id === questionId ? { ...updatedQuestion } : question));
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.ADD_QUESTION: {
      const { createdQuestion } = action.payload;
      const questions = [...state.questions, createdQuestion];
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.CHANGE_QUESTION_ORDER: {
      const { newQuestionsOrderIds } = action.payload;
      const questions = getNewQuestionsSet({ newQuestionsOrderIds, questionsData: state.questions });
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.CHANGE_ANSWER_ORDER: {
      const { newAnswersOrderIds, questionId } = action.payload;
      const questions = getNewQuestionsSetFromAnswers({
        newAnswersIds: newAnswersOrderIds,
        questionId,
        prevQuestions: state.questions,
      });
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.MOVE_QUESTION: {
      const { direction, questionId } = action.payload;
      const index = state.questions.findIndex((item) => item.id === questionId);
      const questions = moveElementAtArray(state.questions, index, direction);
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.EDIT_ANSWER: {
      const { questionId, answerId, updatedAnswer } = action.payload;
      const questions = state.questions.map((question) =>
        question.id === questionId
          ? {
              ...question,
              answers: question.answers.map((answer) => (answer.id === answerId ? updatedAnswer : answer)),
            }
          : question,
      );
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.ADD_ANSWER: {
      const { questionId, answer } = action.payload;
      const questions = state.questions.map((question) =>
        question.id === questionId ? { ...question, answers: question.answers.concat([answer]) } : question,
      );
      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.DELETE_ANSWER: {
      const { answerId } = action.payload;
      const questions = state.questions.map((question) => {
        return { ...question, answers: question.answers.filter((answer) => answer.id !== answerId) };
      });

      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    case ActionType.DELETE_QUESTION: {
      const { questionId } = action.payload;
      return {
        ...state,
        isEdited: true,
        questions: deleteQuestionAndUpdateReferences(state.questions, questionId),
      };
    }

    case ActionType.COPY_QUESTION: {
      const { copiedQuestion, insertNearId } = action.payload;

      const insertNearIdIndex = state.questions.findIndex((item) => item.id === insertNearId);
      const questions = [
        ...state.questions.slice(0, insertNearIdIndex + 1),
        copiedQuestion,
        ...state.questions.slice(insertNearIdIndex + 1),
      ];

      return {
        ...state,
        isEdited: true,
        questions,
      };
    }

    default:
      return state;
  }
};
