import type { CreateEditAnswer } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

export const onSetIsClientPreferencesHandler = ({
  watch,
  setValue,
  name,
  context,
}: Omit<UseFormObserverProps<CreateEditAnswer>, 'handlersMap'>): void => {
  const { question } = context ?? {};
  const newNameValue = watch(name!);
  if (newNameValue && question?.tags) {
    setValue('tags', question.tags.slice(0, 1));
    return;
  }

  setValue('clientDamageItem', '');
  setValue('modelFilter', '');
};
