import { type Control } from 'react-hook-form';

import type { CreateEditAnswer } from '../../utils/schema';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { Label } from '../../../../components/Label/Label';
import { SwitchField } from '../../../../components/SwitchField/SwitchField';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useConnotationField } from '../../hooks/useConnotationField/useConnotationField';

type AnswerOptionsFormSectionParams = Readonly<{
  control: Control<CreateEditAnswer>;
  setIsRequiresPhoto: (value: boolean) => void;
  onIsNumericEntryChange: (value: boolean) => void;
  isQuestionWithClientPreferences?: boolean;
}>;

export const AnswerOptionsFormSection = ({
  control,
  setIsRequiresPhoto,
  onIsNumericEntryChange,
  isQuestionWithClientPreferences = false,
}: AnswerOptionsFormSectionParams) => {
  const { value, onConnotationChange } = useConnotationField(control);

  return (
    <ExpandableFormSection title="Answer Options">
      <FormControl
        type="switch"
        label="Default Answer(1 per question)"
        name="switchControls.isDefaultAnswer"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Hide Buyer Display"
        name="hideBuyerDisplay"
        control={control}
        className="flex justify-between items-center"
      />
      <div className="flex justify-between items-center">
        <Label label="Highlight Issue on CR" name="connotation" />
        <SwitchField name="connotation" value={Boolean(value)} onChange={onConnotationChange} />
      </div>
      <FormControl
        type="switch"
        label="Allow Free-Form Text"
        name="switchControls.isFreeFormText"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Grade Adjust"
        name="switchControls.isGradeAdjust"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Requires Photo"
        name="switchControls.isRequiresPhoto"
        control={control}
        className="flex justify-between items-center"
        onChange={setIsRequiresPhoto}
      />
      <FormControl
        type="switch"
        label="Nest Question"
        name="switchControls.isNestQuestion"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Numeric Entry"
        name="switchControls.isNumericEntry"
        control={control}
        className="flex justify-between items-center"
        onChange={onIsNumericEntryChange}
      />
      <FormControl
        type="switch"
        label="Map to Announcement"
        name="switchControls.isAnnouncements"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Client Preferences"
        name="switchControls.isClientPreferences"
        control={control}
        className="flex justify-between items-center"
        isDisabled={!isQuestionWithClientPreferences}
      />
      <FormControl
        type="switch"
        label="Metadata"
        name="switchControls.isMetadata"
        control={control}
        className="flex justify-between items-center"
      />
      <FormControl
        type="switch"
        label="Apply Filters"
        name="switchControls.isFilters"
        control={control}
        className="flex justify-between items-center"
      />
    </ExpandableFormSection>
  );
};
