import { useQuery } from '@tanstack/react-query';

import type { ArtItemDescription } from '../../../../api/queries/artProxy/artProxy.types';
import type { QueryFunction } from '@tanstack/react-query';

import { ArtProxyAction, QueryKey } from '../../../../api/constants';
import { getDescription } from '../../../../api/queries/artProxy/artProxy.api';

type UseGetPrepopulatedItemSearchParams = Readonly<{ itemCode?: string; subItemCode?: string }>;
type UseGetPrepopulatedItemSearch = Readonly<{ itemSearch?: string; itemSearchFetching: boolean }>;

export const useGetPrepopulatedItemSearch = ({
  itemCode,
  subItemCode,
}: UseGetPrepopulatedItemSearchParams): UseGetPrepopulatedItemSearch => {
  const queryFn: QueryFunction<ArtItemDescription> = async ({ signal }): Promise<ArtItemDescription> => {
    const res = await getDescription({ subItemCode, itemCode, signal });
    return res;
  };

  const { data: itemSearch, isFetching: itemSearchFetching } = useQuery({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_BY_ID, itemCode, subItemCode],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(itemCode && subItemCode),
    staleTime: Infinity,
    retry: false,
  });

  return { itemSearch: itemSearch?.description, itemSearchFetching };
};
