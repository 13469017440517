import type { OptionType } from '../../../common/types/OptionType';

import { config } from '../../../common/config';

export const QE_DOM_ELEMENT_ID = 'questions-and-announcements-root';

export const QUESTION_ENGINE_FILTER_OPTIONS: Array<OptionType> = [
  { label: 'Electric', value: 'engineType:electric' },
  { label: 'Truck', value: 'vehicleType:truck' },
  { label: 'Convertible', value: 'roof:convertible' },
  { label: 'Sunroof', value: 'roof:sunroof' },
  { label: '3rdRowSeats', value: 'seats:3rdRowSeats' },
  { label: 'Panoramic', value: 'roof:panoramic' },
  { label: 'Tesla', value: 'vehicleMake:tesla' },
  { label: 'Car', value: 'vehicleType:car' },
  { label: 'Dealer', value: 'user:dealer' },
  { label: 'BMW: Non-iSeries', value: 'vehicleModel:Non-iSeries' },
  { label: 'BMW: iSeries', value: 'vehicleModel:iSeries' },
  { label: 'Van', value: 'vehicleType:van' },
  { label: 'Suv', value: 'vehicleType:suv ' },
];

const QUESTION_ENGINE_GUIDE_IMAGES_SHARED = {
  label: 'Shared',
  options: [
    { label: 'Center Console', value: `${config.GUIDE_IMAGES_URL}/shared/center-console.svg` },
    { label: 'Tire Thread', value: `${config.GUIDE_IMAGES_URL}/shared/checkingtiretread.jpg` },
    { label: 'Infotainment', value: `${config.GUIDE_IMAGES_URL}/shared/infotainment.svg` },
    { label: 'Instrumental Gauge', value: `${config.GUIDE_IMAGES_URL}/shared/instrumental-gauge.svg` },
    { label: 'Dashboard Highlight', value: `${config.GUIDE_IMAGES_URL}/shared/interior-dashboard-highlight.svg` },
    { label: 'Quarter Tread Gauge', value: `${config.GUIDE_IMAGES_URL}/shared/quarter-tread-gauge.jpg` },
    { label: 'Steering Wheel', value: `${config.GUIDE_IMAGES_URL}/shared/steering-wheel.svg` },
  ],
};

const QUESTION_ENGINE_GUIDE_IMAGES_CAR = {
  label: 'Car',
  tags: ['vehicleType:car'],
  options: [
    { label: 'Driver Side Alt 1', value: `${config.GUIDE_IMAGES_URL}/car/driver-side-alt-1.svg` },
    { label: 'Driver Side', value: `${config.GUIDE_IMAGES_URL}/car/driver-side.svg` },
    { label: 'Front Alt 1', value: `${config.GUIDE_IMAGES_URL}/car/front-alt-1.svg` },
    { label: 'Front', value: `${config.GUIDE_IMAGES_URL}/car/front.svg` },
    { label: 'Passenger Side Alt', value: `${config.GUIDE_IMAGES_URL}/car/passenger-side-alt-1.svg` },
    { label: 'Passenger Side', value: `${config.GUIDE_IMAGES_URL}/car/passenger-side.svg` },
    { label: 'Rear Alt 1', value: `${config.GUIDE_IMAGES_URL}/car/rear-alt-1.svg` },
    { label: 'Rear', value: `${config.GUIDE_IMAGES_URL}/car/rear.svg` },
    { label: 'Tires', value: `${config.GUIDE_IMAGES_URL}/car/tires.svg` },
    { label: 'Windows', value: `${config.GUIDE_IMAGES_URL}/car/windows.svg` },
  ],
};

const QUESTION_ENGINE_GUIDE_IMAGES_SUV = {
  label: 'SUV',
  tags: ['vehicleType:suv'],
  options: [
    { label: 'Driver Side Alt', value: `${config.GUIDE_IMAGES_URL}/suv/driver-side-alt-1.svg` },
    { label: 'Driver Side', value: `${config.GUIDE_IMAGES_URL}/suv/driver-side.svg` },
    { label: 'Front Alt 1', value: `${config.GUIDE_IMAGES_URL}/suv/front-alt-1.svg` },
    { label: 'Front', value: `${config.GUIDE_IMAGES_URL}/suv/front.svg` },
    { label: 'Passenger Side Alt 1', value: `${config.GUIDE_IMAGES_URL}/suv/passenger-side-alt-1.svg` },
    { label: 'Passenger Side', value: `${config.GUIDE_IMAGES_URL}/suv/passenger-side.svg` },
    { label: 'Rear Alt 1', value: `${config.GUIDE_IMAGES_URL}/suv/rear-alt-1.svg` },
    { label: 'Rear', value: `${config.GUIDE_IMAGES_URL}/suv/rear.svg` },
    { label: 'Tires', value: `${config.GUIDE_IMAGES_URL}/suv/tires.svg` },
    { label: 'Windows', value: `${config.GUIDE_IMAGES_URL}/suv/windows.svg` },
  ],
};

const QUESTION_ENGINE_GUIDE_IMAGES_TRUCK = {
  label: 'Truck',
  tags: ['vehicleType:truck'],
  options: [
    {
      label: 'Driver Side Four Door Alt',
      value: `${config.GUIDE_IMAGES_URL}/truck/driver-side-four-door-alt-1.svg`,
    },
    { label: 'Driver Side Four Door', value: `${config.GUIDE_IMAGES_URL}/truck/driver-side-four-door.svg` },
    { label: 'Front Alt 1', value: `${config.GUIDE_IMAGES_URL}/truck/front-alt-1.svg` },
    { label: 'Front', value: `${config.GUIDE_IMAGES_URL}/truck/front.svg` },
    {
      label: 'Passenger Side Front Door Alt',
      value: `${config.GUIDE_IMAGES_URL}/truck/passenger-side-four-door.svg`,
    },
    {
      label: 'Passenger Side Four Door',
      value: `${config.GUIDE_IMAGES_URL}/truck/passenger-side-four-door.svg`,
    },
    { label: 'Rear Alt 1', value: `${config.GUIDE_IMAGES_URL}/truck/rear-alt-1.svg` },
    { label: 'Rear', value: `${config.GUIDE_IMAGES_URL}/truck/rear.svg` },
    { label: 'Tires Four Door', value: `${config.GUIDE_IMAGES_URL}/truck/truck-tires-four-door.svg` },
    { label: 'Windows', value: `${config.GUIDE_IMAGES_URL}/truck/windows.svg` },
  ],
};

const QUESTION_ENGINE_GUIDE_IMAGES_VAN = {
  label: 'Van',
  tags: ['vehicleType:van'],
  options: [
    { label: 'Driver Side Alt 1', value: `${config.GUIDE_IMAGES_URL}/van/driver-side-alt-1.svg` },
    { label: 'Driver Side', value: `${config.GUIDE_IMAGES_URL}/van/driver-side.svg` },
    { label: 'Front Alt 1', value: `${config.GUIDE_IMAGES_URL}/van/front-alt-1.svg` },
    { label: 'Front', value: `${config.GUIDE_IMAGES_URL}/van/front.svg` },
    { label: 'Passenger Side Alt 1', value: `${config.GUIDE_IMAGES_URL}/van/passenger-side-alt-1.svg` },
    { label: 'Passenger Side', value: `${config.GUIDE_IMAGES_URL}/van/passenger-side.svg` },
    { label: 'Rear Alt 1', value: `${config.GUIDE_IMAGES_URL}/van/rear-alt-1.svg` },
    { label: 'Rear', value: `${config.GUIDE_IMAGES_URL}/van/rear.svg` },
    { label: 'Tires', value: `${config.GUIDE_IMAGES_URL}/van/tires.svg` },
    { label: 'Windows', value: `${config.GUIDE_IMAGES_URL}/van/windows.svg` },
  ],
};

export const GUIDE_IMAGES_CATEGORIES = {
  shared: QUESTION_ENGINE_GUIDE_IMAGES_SHARED,
  car: QUESTION_ENGINE_GUIDE_IMAGES_CAR,
  suv: QUESTION_ENGINE_GUIDE_IMAGES_SUV,
  truck: QUESTION_ENGINE_GUIDE_IMAGES_TRUCK,
  van: QUESTION_ENGINE_GUIDE_IMAGES_VAN,
};
