import type { Form } from '../../../api/queries/forms/forms.types';
import type { OptionType } from '../../../common/types';

export const getSortedVersionAndGuidOptions = (forms: Form[]): OptionType[] => {
  return forms
    .reduce((acc, form) => {
      if (form.version === 'Unknown') return acc;

      acc.push({ label: form.version, value: form.questionSet.guid });
      return acc;
    }, [] as OptionType[])
    .sort((a, b) => parseFloat(b.label) - parseFloat(a.label));
};
