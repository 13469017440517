import type { MoveDirection, QuestionsTableRowItem } from './types';
import type { CellContext, ColumnDef } from '@tanstack/react-table';

import { DragHandle } from '../../../modules/draggable';
import { QuestionsTableExpandCell } from '../components/QuestionsTableExpandCell/QuestionsTableExpandCell';
import { QuestionsTableOrderCell } from '../components/QuestionsTableOrderCell/QuestionsTableOrderCell';
import { QuestionsTableParentCell } from '../components/QuestionsTableParentCell/QuestionsTableParentCell';
import { QuestionsTableParentHeader } from '../components/QuestionsTableParentHeader/QuestionsTableParentHeader';
import { QuestionsTableRowActionButtons } from '../components/QuestionsTableRowActionButtons/QuestionsTableRowActionButtons';
import { TextCell } from '../components/TextCell/TextCell';

type QuestionsColumnsParams = Readonly<{
  isEditMode: boolean;
  onMovingRow: (id: string, direction: MoveDirection) => void;
  openCreateEditQuestionModal: (questionData: QuestionsTableRowItem) => void;
  openDeleteQuestionModal: (questionData: QuestionsTableRowItem) => void;
  openCopyQuestionModal: (questionData: QuestionsTableRowItem) => void;
  isRowDraggable?: boolean;
}>;

export const questionsColumns = ({
  onMovingRow,
  openCreateEditQuestionModal,
  openDeleteQuestionModal,
  openCopyQuestionModal,
  isEditMode,
}: QuestionsColumnsParams): ColumnDef<QuestionsTableRowItem>[] => {
  return [
    {
      accessorKey: 'drag',
      header: '',
      cell: (props) => !isEditMode && <DragHandle disabled={props.table.getIsSomeRowsExpanded()} />,
      footer: (props) => props.column.id,
      minSize: 20,
      maxSize: 20,
      meta: { headerClass: 'sticky top-[80px] z-10 rounded-l', isPlaceholder: true },
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'order',
      header: 'Questions Order',
      cell: (info: CellContext<QuestionsTableRowItem, unknown>) => (
        <QuestionsTableOrderCell info={info} onMovingRow={onMovingRow} isEditMode={isEditMode} />
      ),
      footer: (props) => props.column.id,
      minSize: 75,
      maxSize: 95,
      meta: { headerClass: 'sticky top-[80px] z-10 rounded-l' },
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'answers',
      header: 'Answers',
      cell: (info) => <QuestionsTableExpandCell info={info} />,
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 90,
      meta: { headerClass: 'sticky top-[80px] z-10 text-center', cellClass: 'justify-center' },
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: 'text',
      header: 'Question Text',
      cell: TextCell,
      footer: (props) => props.column.id,
      minSize: 90,
      maxSize: 120,
      meta: { headerClass: 'sticky top-[80px] z-10 text-left' },
      enableColumnFilter: true,
      enableSorting: true,
    },
    {
      accessorKey: 'section',
      header: 'Section',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      minSize: 90,
      maxSize: 120,
      meta: { headerClass: 'sticky top-[80px] z-10 text-left' },
      enableColumnFilter: true,
      enableSorting: true,
    },
    {
      accessorKey: 'subSection',
      header: 'Sub-Section',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      minSize: 90,
      maxSize: 120,
      meta: { headerClass: 'sticky top-[80px] z-10 text-left' },
      enableColumnFilter: true,
      enableSorting: true,
    },
    {
      accessorKey: 'parent',
      header: 'Parent',
      cell: (info) => <QuestionsTableParentCell value={info.getValue() as boolean} />,
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 90,
      meta: {
        headerCell: () => <QuestionsTableParentHeader />,
        headerClass: 'sticky top-[80px] z-10',
        cellClass: 'justify-center',
      },
      enableColumnFilter: false,
      enableSorting: false,
    },
    {
      accessorKey: 'actions',
      header: '',
      minSize: 90,
      maxSize: 120,
      cell: (info) => (
        <QuestionsTableRowActionButtons
          info={info}
          openCreateEditQuestionModal={openCreateEditQuestionModal}
          openDeleteQuestionModal={openDeleteQuestionModal}
          openCopyQuestionModal={openCopyQuestionModal}
        />
      ),
      meta: { headerClass: 'sticky top-[80px] rounded-r' },
      enableSorting: false,
      enableColumnFilter: false,
    },
  ];
};
