import type { CommonQuestion } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { UseFormTrigger, Control, UseFormGetFieldState, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useCopyQuestionFormFieldsConfig } from '../../hooks/useCopyQuestionArtMapping/useCopyQuestionArtMapping';

type ArtMappingQuestionProps = {
  control: Control<CommonQuestion>;
  setValue: UseFormSetValue<CommonQuestion>;
  getFieldState: UseFormGetFieldState<CommonQuestion>;
  watch: UseFormWatch<CommonQuestion>;
  trigger: UseFormTrigger<CommonQuestion>;
};

export const ArtMappingQuestion: FC<ArtMappingQuestionProps> = ({ control, setValue, watch, trigger }): ReactElement => {
  const { itemDescriptionConfig } = useCopyQuestionFormFieldsConfig({ watch, setValue, trigger });

  return (
    <ExpandableFormSection title="ART Mapping">
      <p className="text-xs font-bold text-gray-500 mb-3">Damage and Severity will match copied answers</p>
      <FormControl
        type="select"
        name="artMapping.artCode"
        label="ART Item Description"
        fieldConfig={itemDescriptionConfig}
        control={control}
      />
    </ExpandableFormSection>
  );
};
