import type { QuestionsTableRowItem } from '../../shared/types';
import type { Table } from '@tanstack/react-table';
import type { FC, ReactElement } from 'react';
import './grid.css';

import { HeaderCell } from '../../../../components/CommonTable/HeaderCell/HeaderCell';
import { DraggableRows } from '../QuestionDraggableRows/DraggableRows';

type QuestionsTableProps = Readonly<{
  table: Table<QuestionsTableRowItem>;
  isFilter: boolean;
  isEditMode: boolean;
}>;

export const QuestionsTable: FC<QuestionsTableProps> = ({ table, isFilter, isEditMode }: QuestionsTableProps): ReactElement => (
  <div
    data-testid="common-table"
    className="grid grid-cols-1 gap-x-4 prism-table w-full table-fixed border-separate border-spacing-0 relative"
  >
    {table.getHeaderGroups().map((headerGroup) => (
      <div
        key={headerGroup.id}
        className="questions-grid bg-blue-800 w-full sticky top-32 z-10 font-bold text-white p-3 items-center"
        data-testid="table-head"
      >
        {headerGroup.headers.map((header) => (
          <div
            key={header.id}
            data-testid="title-th"
            style={{ width: header.getSize() }}
            className={`${(header.column.columnDef.meta as Record<string, string>)?.headerClass}`}
          >
            <HeaderCell header={header} isFilter={isFilter} />
          </div>
        ))}
      </div>
    ))}
    <DraggableRows isEditMode={isEditMode} table={table} />
  </div>
);
