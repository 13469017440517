import { flexRender } from '@tanstack/react-table';
import classnames from 'classnames';
import { useEffect, type ReactElement, useState } from 'react';
import { useLocation } from 'react-router-dom';

import type { QuestionsTableRowItem } from '../../shared/types';
import type { Row } from '@tanstack/react-table';

import { DraggableList } from '../../../../modules/draggable';
import { Answers } from '../Answers/Answers';

const defaultRowClassName =
  'questions-grid bg-white border-b-gray-300 border-b hover:bg-neutral-100 transition-colors duration-75 rounded p-3';
const highlightedRowClassName = 'questions-grid transition-colors duration-75 rounded p-3 border-2 border-blue-800 bg-neutral-100';

type QuestionRowProps = Readonly<{
  row: Row<QuestionsTableRowItem>;
  isEditMode: boolean;
}>;

export const QuestionRow = ({ row, isEditMode }: QuestionRowProps): ReactElement => {
  const isExpanded = row.getIsExpanded();
  const { hash, key } = useLocation();
  const [isRowHighlighted, setIsRowHighlighted] = useState(false);

  useEffect(() => {
    const removeAnchor = function (this: Document, ev: MouseEvent) {
      if ((ev.target as HTMLAnchorElement).href === location.href) {
        setIsRowHighlighted(true);
        return;
      }

      history.pushState('', document.title, window.location.pathname + window.location.search);
      setIsRowHighlighted(false);
      document.removeEventListener('click', removeAnchor);
    };

    if (hash === `#${row.original.guid}`) {
      document.addEventListener('click', removeAnchor);
      setIsRowHighlighted(true);
    }

    return () => {
      document.removeEventListener('click', removeAnchor);
    };
  }, [key]);

  const dataRow = isExpanded ? row.original : undefined;
  const rowClassName = isRowHighlighted ? highlightedRowClassName : defaultRowClassName;

  return (
    <>
      <DraggableList.Item as="div" dragControl id={row.original.id} className={rowClassName}>
        {row.getVisibleCells().map((cell) => (
          <div
            key={cell.id}
            data-testid={`${cell.id}-cell`}
            className={classnames('flex items-center', (cell.column.columnDef.meta as Record<string, string>)?.cellClass)}
          >
            {flexRender(cell.column.columnDef.cell as string, cell.getContext())}
          </div>
        ))}
      </DraggableList.Item>

      {isExpanded && dataRow && <Answers isEditMode={isEditMode} question={dataRow} />}
    </>
  );
};
