import { getCoreRowModel, getExpandedRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';

import type { Question, QuestionsTableRowItem } from '../../shared/types';
import type { ColumnDef, Table } from '@tanstack/react-table';

import { questionsColumns } from '../../shared/columns';
import { moveQuestion } from '../../store/actions';
import { useEditModeWithTableState } from '../useEditModeWithTableState/useEditModeWithTableState';
import { useQuestionsContext } from '../useQuestionsContext/useQuestionsContext';

type UseQuestionsTableParams = Readonly<{
  questionsData: Array<Question>;
  openCreateEditQuestionModal: (questionData?: QuestionsTableRowItem) => void;
  openDeleteQuestionModal: (questionData: QuestionsTableRowItem) => void;
  openCopyQuestionModal: (questionData: QuestionsTableRowItem) => void;
}>;

type UseQuestionsTable = Readonly<{
  toggleFilter: () => void;
  isFilter: boolean;
  table: Table<QuestionsTableRowItem>;
  toggleEditMode: () => void;
  isEditMode: boolean;
}>;

export const useQuestionsTable = ({
  questionsData,
  openCreateEditQuestionModal,
  openDeleteQuestionModal,
  openCopyQuestionModal,
}: UseQuestionsTableParams): UseQuestionsTable => {
  const { dispatch } = useQuestionsContext();

  const {
    isEditMode,
    toggleEditMode,
    toggleFilter: checkToggleFilter,
    isFilter,
    filters,
    setFilters,
    sorting,
    setSorting,
  } = useEditModeWithTableState();

  const isDraggable = !sorting.length && !isFilter && !isEditMode;

  const columns = useMemo<ColumnDef<QuestionsTableRowItem>[]>(
    () =>
      questionsColumns({
        isEditMode,
        onMovingRow: (id, direction) => dispatch(moveQuestion({ questionId: id, direction })),
        openCreateEditQuestionModal,
        openDeleteQuestionModal,
        openCopyQuestionModal,
      }),
    [isDraggable, isEditMode],
  );

  const rows = useMemo<QuestionsTableRowItem[]>(() => {
    return questionsData.map((question, index) => ({
      id: question.id,
      guid: question.guid,
      buyerTranslation: question.buyerTranslation,
      helpText: question.helpText,
      type: question.type,
      order: index + 1,
      answers: question.answers,
      defaultAnswers: question.defaultAnswers,
      text: question.text,
      section: question.section,
      subSection: question.subSection,
      parent: question.answers ? question.answers.some((answer) => answer.requiredQuestions || answer.nestedQuestions) : false,
      tags: question.tags,
      guideImages: question.guideImages,
      multipleResponses: question.type === 'multi',
    }));
  }, [questionsData]);

  const getRowCanExpand = () => true;

  const table = useReactTable({
    data: rows,
    columns,
    state: { sorting, columnFilters: filters },
    getRowCanExpand,
    onColumnFiltersChange: setFilters,
    getExpandedRowModel: getExpandedRowModel(),
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.toggleAllRowsExpanded(false);
  }, [isFilter, sorting]);

  return { table, isFilter, toggleFilter: checkToggleFilter, toggleEditMode, isEditMode };
};
