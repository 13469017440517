import type { Field, OptionType } from '../../../../common/types';
import type { CommonQuestion } from '../../utils/schema';
import type { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { useItemDescriptionSearch } from '../useItemDescriptionSearch/useItemDescriptionSearch';

type UseGetItemDescriptionFieldConfigParams = {
  watch: UseFormWatch<CommonQuestion>;
  setValue: UseFormSetValue<CommonQuestion>;
  trigger: UseFormTrigger<CommonQuestion>;
};

const getItemSearchValue = (watch: UseFormWatch<CommonQuestion>): string => {
  const itemCode = watch('artMapping.artCode.itemCode');
  const subItemCode = watch('artMapping.artCode.itemSubCode');

  if (!itemCode || !subItemCode) {
    return '';
  }
  return `${itemCode}_${subItemCode}`;
};

export const useCopyQuestionArtFieldConfig = (params: UseGetItemDescriptionFieldConfigParams): Field => {
  const { setValue, watch, trigger } = params;
  const { data: options, isFetching, setSearchInput: onInputChange } = useItemDescriptionSearch();

  const onChange = (option: OptionType | null) => {
    if (!option) {
      setValue(`artMapping.artCode`, undefined);
      return;
    }
    const { value, label: itemSearch } = option;
    const [itemCode, itemSubCode] = value.split('_');

    setValue(`artMapping.artCode`, { itemCode, itemSubCode, itemSearch });

    void trigger();
    watch();
  };
  const itemSearchValue = getItemSearchValue(watch);

  const value = itemSearchValue ? { label: watch('artMapping.artCode.itemSearch') || '', value: getItemSearchValue(watch) } : undefined;
  return {
    options,
    onChange,
    onInputChange,
    isFetching,
    isClearable: true,
    value,
  };
};
