import { CrossIcon } from '@prism2/icons-react/prism';
import { motion } from 'framer-motion';
import { useRef, Fragment } from 'react';

import type { ReactElement, FC } from 'react';

import { useMouseClickOutRef } from '../../common/hooks/useMouseClickOutRef';

type SidebarProps = Readonly<{
  children?: ReactElement | Array<ReactElement>;
  isOpen: boolean;
  onClose: () => void;
}>;

export const Sidebar: FC<SidebarProps> = ({ isOpen, onClose, children }: SidebarProps): ReactElement => {
  const ref = useRef<HTMLDivElement>(null);

  useMouseClickOutRef({ ref, callback: onClose, isActive: isOpen });

  const overlayVariants = { open: { opacity: 0.8 }, closed: { opacity: 0 } };
  const sidebarVariants = { open: { x: 0 }, closed: { x: '100%' } };

  return (
    <Fragment>
      <motion.div
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        variants={overlayVariants}
        exit="closed"
        className="fixed inset-0 bg-black transition-opacity z-50"
        onClick={onClose}
        transition={{ duration: 0.2 }}
        style={{ display: isOpen ? 'block' : 'none' }}
      ></motion.div>
      <motion.div
        ref={ref}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        exit="closed"
        variants={sidebarVariants}
        transition={{ duration: 0.2 }}
        className="fixed top-0 bottom-0 right-0 bg-white w-full sm:w-[484px] z-50 overflow-y-scroll px-4 py-8 shadow"
      >
        <CrossIcon
          data-testid="modal-close-icon"
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-300 font-thin w-5 cursor-pointer z-40"
        />
        {isOpen && children}
      </motion.div>
    </Fragment>
  );
};
