import { DragOverlay, defaultDropAnimationSideEffects } from '@dnd-kit/core';

import type { DropAnimation } from '@dnd-kit/core';
import type { PropsWithChildren, ReactElement } from 'react';

const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.4',
      },
    },
  }),
};

export const DraggableOverlay = ({ children }: PropsWithChildren): ReactElement => (
  <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
);
