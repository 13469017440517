import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { setValues } from '../../../../modules/form/utils/setValues';
import { useItemDescriptionSearch } from '../useItemDescriptionSearch/useItemDescriptionSearch';

type UseGetItemDescriptionFieldConfigParams = {
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
};

const getItemSearchValue = (watch: UseFormWatch<CreateEditAnswer>): string => {
  const itemCode = watch('metaData.artCode.itemCode');
  const subItemCode = watch('metaData.artCode.itemSubCode');

  if (!itemCode || !subItemCode) {
    return '';
  }
  return `${itemCode}_${subItemCode}`;
};

export const useGetItemDescriptionFieldConfig = (params: UseGetItemDescriptionFieldConfigParams): Field => {
  const { setValue, watch } = params;
  const { data: options, isFetching, setSearchInput: onInputChange } = useItemDescriptionSearch();

  const onChange = (option: OptionType | null) => {
    const { value, label } = option || {};
    const [itemCode, subItemCode] = value ? value.split('_') : ['', ''];
    setValues(setValue, {
      'metaData.artCode.itemCode': itemCode,
      'metaData.artCode.itemSubCode': subItemCode,
      'metaData.artCode.itemSearch': label,
      'metaData.artCode.damageCode': '',
      'metaData.artCode.severityCode': '',
      'damages.itemDesc': label,
      'damages.damageDesc': '',
      'damages.severityDesc': '',
    });
  };

  const itemSearchValue = getItemSearchValue(watch);

  const value = itemSearchValue ? { label: watch('metaData.artCode.itemSearch') || '', value: getItemSearchValue(watch) } : undefined;

  return {
    options,
    onChange,
    onInputChange,
    isFetching,
    value,
    isClearable: true,
  };
};
