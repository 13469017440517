import type { Question, Answer } from '../shared/types';

export const transformQuestions = (data: Question[]): Question[] => {
  const guidMap = new Map<string, Question>(data.map((item: Question) => [item.guid, { ...item, optional: undefined }]));

  data.forEach((item: Question) => {
    item.answers.forEach((answer: Answer) => {
      const optionalQuestionGuids = [...(answer.requiredQuestions || []), ...(answer.nestedQuestions || [])];

      optionalQuestionGuids.forEach((guid: string) => {
        const dataItem = guidMap.get(guid);
        if (dataItem) {
          guidMap.set(guid, { ...dataItem, optional: true });
        }
      });
    });
  });

  return Array.from(guidMap.values()).map((item) => {
    if (item.optional === undefined) {
      const { optional, ...rest } = item;
      return rest;
    }
    return item;
  });
};
