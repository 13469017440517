import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { Form } from '../../../../api/queries/forms/forms.types';

import { QueryKey } from '../../../../api/constants';
import { fetchForm } from '../../../../api/queries/forms/forms.api';

type UseFormByIdParams = Readonly<{
  formTypeName?: string;
  formId: string;
  envAll?: string;
}>;

type UseFormById = Readonly<{
  data?: Form;
  isFetching: boolean;
  error?: Error | null;
}>;

export const useFormById = ({ formTypeName, formId }: UseFormByIdParams): UseFormById => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const queryFn = async (): Promise<Form> => {
    return await fetchForm(formId);
  };

  const { data, isFetching, error } = useQuery<Form, Error>({
    queryKey: [QueryKey.FETCH_FORM, { formId: formId }],
    queryFn,
    retry: false,
    refetchOnWindowFocus: false,
    // TODO: refactor as  https://ghe.coxautoinc.com/MAN-Vintage/questions-engine-frontend/issues/40
    onError: (e) => {
      toast(e.message, { type: 'error' });
    },
  });

  if (formTypeName && !isFetching && !data && error) {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORMS]);
    navigate(`/${formTypeName}`);
  }

  return { isFetching, data, error };
};
