import { Navigate } from 'react-router-dom';

import type { RouteObject } from 'react-router-dom';

import { config } from './common/config';
import { AuthComponent } from './modules/auth/components/AuthComponent';
import { DeploymentManager } from './pages/DeploymentManager/DeploymentManager';
import { FormPage } from './pages/FormPage/FormPage';
import { FormTypePage } from './pages/FormTypePage/FormTypePage';
import { QuestionPageWithProvider } from './pages/QuestionsPage/components/QuestionPageWithProvider/QuestionPageWithProvider';

export const routesMap: RouteObject[] = [
  {
    path: '/',
    element: <AuthComponent />,
    children: [
      {
        path: config.routes.FORM_TYPE,
        element: <FormTypePage />,
      },
      {
        path: config.routes.FORM,
        element: <FormPage />,
      },
      {
        path: config.routes.QUESTIONS,
        element: <QuestionPageWithProvider />,
      },
      {
        path: config.routes.DEPLOYMENT_MANAGER,
        element: <DeploymentManager />,
      },
    ],
  },
  {
    path: config.routes.LOGIN_CALLBACK,
    element: <></>,
  },
  {
    path: config.routes.NOT_EXIST,
    element: <Navigate to="/" />,
  },
];
