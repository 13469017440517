import type { Form } from '../../../../api/queries/forms/forms.types';
import type { FC, ReactElement } from 'react';

import { useDeleteForm } from '../../../../api/hooks/useDeleteForm';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';

type DeleteFormModalProps = Readonly<{
  isOpen: boolean;
  formTypeName: string;
  onSuccess: () => void;
  onCancel: () => void;
  formToDelete?: Form;
  modalName?: string;
}>;

export const DeleteFormModal: FC<DeleteFormModalProps> = ({
  isOpen,
  formToDelete,
  formTypeName,
  onSuccess,
  onCancel,
  modalName,
}): ReactElement | null => {
  const { mutate } = useDeleteForm({ onSuccess });

  return (
    <DialogModal
      isOpen={isOpen}
      onClose={onCancel}
      onCancelButton={onCancel}
      submitButtonText="Delete"
      onSubmitButton={() => mutate(formToDelete?.questionSet?.guid as string)}
      name={modalName}
    >
      <p className="font-bold text-blue-800 p-5">Delete Question Set - {`${formTypeName} - ${formToDelete?.version as string}`}</p>
    </DialogModal>
  );
};
