import { createContext } from 'react';

import type { QuestionAction } from '../../store/actions';
import type { QuestionsState } from '../../store/reducer';
import type { Dispatch, FC, ReactElement } from 'react';

import { useReducerWithMiddleware } from '../../hooks/useReducerWithMiddleware/useReducerWithMiddleware';
import { persistQuestionsMiddleware } from '../../store/persistQuestionsMiddleware';
import { initialState, questionsReducer } from '../../store/reducer';

export const QuestionsContext = createContext<
  | {
      state: QuestionsState;
      dispatch: Dispatch<QuestionAction>;
    }
  | undefined
>(undefined);

type QuestionsProviderProps = Readonly<{
  children: ReactElement;
}>;

export const QuestionsProvider: FC<QuestionsProviderProps> = ({ children }: QuestionsProviderProps): ReactElement => {
  const [state, dispatch] = useReducerWithMiddleware(questionsReducer, initialState, persistQuestionsMiddleware);

  return <QuestionsContext.Provider value={{ state, dispatch }}>{children}</QuestionsContext.Provider>;
};
