import { useQueryClient, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { ResetScheduleFormDataType } from '../queries/shared/types';

import { QueryKey } from '../constants';
import { resetScheduleForm } from '../queries/forms/forms.api';

type UseResetScheduleForm = Readonly<{
  mutate: (data: ResetScheduleFormDataType) => void;
  isLoading: boolean;
}>;

export function useResetScheduleForm(): UseResetScheduleForm {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: ResetScheduleFormDataType) => resetScheduleForm(values),
    onSuccess: () => {
      void queryClient.invalidateQueries([QueryKey.FETCH_FORM_BY_ID_ALL_ENVS]);
      void queryClient.invalidateQueries([QueryKey.TEST_LATEST_VERSIONS]);
      toast('The deployment has been successfully canceled', { type: 'success' });
    },
    onError: (e: Error) => {
      const msg = e.message ?? 'Failed to cancel the scheduled deployment';
      toast(msg, { type: 'error' });
    },
  });

  return {
    mutate,
    isLoading,
  };
}
