import type { CreateEditAnswer } from './schema';
import type { Answer } from '../../../api/queries/forms/forms.types';

import { isClientPreferences } from './getClientPreferences';

type GetFilters = {
  filterParameters?: CreateEditAnswer['filterParameters'];
};

export const getFilters = (answer: Answer): GetFilters | undefined => {
  const { tags } = answer;

  if (!tags || !tags?.length) {
    return undefined;
  }

  const isClientPreferencesTag = isClientPreferences(tags[0]);

  if (isClientPreferencesTag && tags.length === 1) {
    return undefined;
  }

  const filterParameters = (isClientPreferencesTag ? tags?.slice(1) : tags) as CreateEditAnswer['filterParameters'];

  return { filterParameters };
};
