import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { FormType } from '../../../api/queries/formTypes/formTypes.types';

import { useFormTypes } from '../../../api/hooks/useFormTypes';
import { config } from '../../../common/config';

type UseFormTypeByName = {
  formType?: FormType;
  isFetching: boolean;
};

type UseFormTypeByNameParams = Readonly<{
  formTypeName?: string;
}>;

export const useFormTypeByName = ({ formTypeName }: UseFormTypeByNameParams): UseFormTypeByName => {
  const navigate = useNavigate();

  const { isFetching, data } = useFormTypes({ formTypeName });
  const formType = data?.items[0];

  useEffect(() => {
    if (Array.isArray(data?.items) && !formType && !isFetching) {
      navigate(config.routes.FORM_TYPE);
    }
  }, [formType, isFetching]);

  return { formType, isFetching };
};
