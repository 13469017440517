import type { CreateEditAnswer } from './schema';

type GetTags = {
  tags?: Array<string>;
};

type GetTagsParams = Pick<CreateEditAnswer, 'modelFilter' | 'tags' | 'clientDamageItem' | 'filterParameters'>;

export const getTags = ({ tags, clientDamageItem, modelFilter, filterParameters = [] }: GetTagsParams): GetTags => {
  if (!clientDamageItem && !modelFilter && !filterParameters.length && !tags.length) {
    return {};
  }

  if (!clientDamageItem && !modelFilter && !filterParameters.length) {
    return { tags };
  }

  const preferencesParts = [tags[0], clientDamageItem, modelFilter].filter(Boolean);
  const clientPreferences = preferencesParts.join(':');

  const mappedTags = [clientPreferences, ...filterParameters].filter(Boolean);

  return { tags: mappedTags };
};
