import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import type { FormType } from '../../../api/queries/formTypes/formTypes.types';
import type { ColumnDef, SortingState, Table } from '@tanstack/react-table';

import { formTypeColumns } from '../shared/columns';

type UseTableProps = Readonly<{
  formTypeData: Array<FormType>;
  onRemoveFormType: (formType: FormType) => void;
  onCopyFormType: (formType: FormType) => void;
}>;

export const useFormTypeTable = ({ formTypeData, onRemoveFormType, onCopyFormType }: UseTableProps): Table<FormType> => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo<ColumnDef<FormType>[]>(() => formTypeColumns(onRemoveFormType, onCopyFormType), []);

  return useReactTable<FormType>({
    data: formTypeData,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
};
