import type { CreateEditAnswer } from '../../utils/schema';
import type { Control } from 'react-hook-form';
import type { UseFormSetValue, UseFormWatch } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';

type NumericEntryFormSectionParams = Readonly<{
  control: Control<CreateEditAnswer>;
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
}>;

export const NumericEntryFormSection = ({ control, watch, setValue }: NumericEntryFormSectionParams) => {
  const maxQuantity = watch('maxQuantity');

  const changeMaxQuantityValue = (value: number) => {
    setValue('maxQuantity', maxQuantity + value, { shouldDirty: true });
  };

  return (
    <ExpandableFormSection title="Numeric Entry">
      <div className="flex items-center">
        <button
          onClick={() => changeMaxQuantityValue(-1)}
          type="button"
          data-testid="minus-btn"
          className="bg-blue-800  qe-btn white-text rounded-none rounded-s"
          disabled={maxQuantity === 0}
        >
          -
        </button>
        <FormControl
          type="number-input"
          name="maxQuantity"
          label="Max Number"
          placeholder="###"
          control={control}
          controlClassName="rounded-none"
          showErrorMessage={true}
          labelClass="-ml-10"
          required
        />
        <button
          onClick={() => changeMaxQuantityValue(1)}
          type="button"
          data-testid="plus-btn"
          className="bg-blue-800 white-text qe-btn rounded-none rounded-r"
        >
          +
        </button>
      </div>
    </ExpandableFormSection>
  );
};
