import type { Answer, AnswerGroupsMap } from '../shared/types';

import { SEPARATOR } from '../shared/constants';

export const getAnswersGroupsIdsFromAnswerId = (answerId: string): string[] => answerId.split(SEPARATOR).slice(1);

export const getAnswerIdWithoutAnswerGroupId = (answerId: string): string => answerId.split(SEPARATOR)[0];

export const createEncodedAnswerId = (answerId: string, answerGroupIds?: string[]): string =>
  answerGroupIds && answerGroupIds.length
    ? `${answerId}${SEPARATOR}${answerGroupIds.sort((a, b) => a.localeCompare(b)).join(SEPARATOR)}`
    : answerId;

export const addAnswerGroupIdToAnswerId = (answerId: string, answerGroupId: string): string => {
  const answerGroupIds = getAnswersGroupsIdsFromAnswerId(answerId);
  const pureAnswerId = getAnswerIdWithoutAnswerGroupId(answerId);

  return createEncodedAnswerId(pureAnswerId, [...answerGroupIds, answerGroupId]);
};

export const getAnswerIdsWithoutAnswerGroupId = (answerIds: string[]): string[] => answerIds.map(getAnswerIdWithoutAnswerGroupId);

export const filterUniqueIds = (arr: string[]): string[] => {
  const uniqueIdMap: Map<string, string> = new Map();

  arr.forEach((item) => {
    const uniqueId = getAnswerIdWithoutAnswerGroupId(item);

    if (uniqueIdMap.has(uniqueId)) {
      const existingItem = uniqueIdMap.get(uniqueId) || '';
      uniqueIdMap.set(uniqueId, item.length < existingItem.length ? item : existingItem);
    } else {
      uniqueIdMap.set(uniqueId, item);
    }
  });

  return Array.from(uniqueIdMap.values());
};

export const getAnswerById = (answerId: string, answers: Array<Answer>) => {
  return answers?.find((row) => row.id === answerId.split(SEPARATOR)[0]) as Answer;
};

export const distributeAnswersToAnswersGroups = (answers: Array<Answer>): AnswerGroupsMap => {
  const result: Record<string, Array<string>> = {};

  answers.forEach((answer) => {
    if (answer.answerGroups) {
      answer.answerGroups.forEach((groupId) => {
        /*
          we need to have the unique ids of the answers at the containers, so during the distribution
          process we add to the original answer id the ids of the other answer groups that it belongs to
         */
        const answerGroupsWithoutCurrentGroupId = (answer.answerGroups || []).filter((id) => id !== groupId);
        if (!result[groupId]) {
          result[groupId] = [];
        }
        result[groupId].push(createEncodedAnswerId(answer.id, answerGroupsWithoutCurrentGroupId));
      });
    }
  });

  return result;
};

export const prepareAnswersToSubmit = (oldAnswers: Array<Answer>, answersIdsState: Array<string>): Array<Answer> => {
  const answersMap = answersIdsState.reduce(
    (acc, answerId) => {
      const pureAnswerId = getAnswerIdWithoutAnswerGroupId(answerId);
      acc[pureAnswerId] = getAnswersGroupsIdsFromAnswerId(answerId);
      return acc;
    },
    {} as Record<string, string[]>,
  );

  return oldAnswers.map((answer) => {
    const answerGroups = answersMap[answer.id];
    return { ...answer, answerGroups };
  });
};
