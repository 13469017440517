import { Fragment, useEffect, useRef, useState } from 'react';

import type { OptionType } from '../../../../common/types';
import type { EnvironmentName, QuestionEngineFormState } from '../../shared/types';
import type { FC, ReactElement } from 'react';

import { config } from '../../../../common/config';
import { ScriptStatus, useScriptLoader } from '../../../../common/hooks/useScriptLoader';
import { Spinner } from '../../../../components/Loaders/Spinner/Spinner';
import { getMasheryToken } from '../../../auth/shared/utils';
import { QE_DOM_ELEMENT_ID } from '../../shared/constants';
import { QuestionEngineFilter } from '../QuestionEngineFilter/QuestionEngineFilter';

type QuestionEngineProps = Readonly<{
  href: string;
}>;

export const QuestionEngine: FC<QuestionEngineProps> = ({ href }: QuestionEngineProps): ReactElement => {
  const { status } = useScriptLoader({ url: config.questionEngine.script, id: 'question-engine-form' });

  const masheryToken = getMasheryToken();

  const ref = useRef(null);

  const [filters, setFilters] = useState<Array<OptionType>>([]);
  const [formState, setFormState] = useState<QuestionEngineFormState>({ questionResponses: [], announcements: [] });
  const [isFormLoaded, setIsFormLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (status !== ScriptStatus.LOADED) return;

    if (ref.current && window.QuestionsAndAnnouncements && masheryToken) {
      const tags = filters.map((i) => i.value);

      window.QuestionsAndAnnouncements.renderQuestions({
        onStateChange: (state: QuestionEngineFormState) => {
          if (!isFormLoaded) setIsFormLoaded(true);
          setFormState(state);
        },
        rootElementId: QE_DOM_ELEMENT_ID,
        clientApplicationName: config.questionEngine.applicationName,
        environment: config.questionEngine.environment as EnvironmentName,
        questionnaireHref: href,
        authToken: masheryToken,
        announcerSource: 'seller',
        username: 'barracuda',
        readonly: false,
        initialState: formState,
        tags,
      });
    }
  }, [ref, status, filters]);

  const isScriptLoaded = status === ScriptStatus.LOADED;

  return (
    <Fragment>
      {isScriptLoaded && (
        <div data-testid="question-engine-body" className="w-full">
          <Fragment>
            <div className="w-full my-5">
              <QuestionEngineFilter filters={filters} setFilters={setFilters} />
            </div>

            <div ref={ref} id={QE_DOM_ELEMENT_ID} />
            {!isFormLoaded && <Spinner />}
          </Fragment>
        </div>
      )}
    </Fragment>
  );
};
