import { memo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import type { FC, ReactElement, SyntheticEvent } from 'react';

type InterceptedLinkProps = Readonly<{
  id: string;
  to: string;
  label: string;
  onRedirect?: (to: string) => void;
}>;

export const InterceptedLink: FC<InterceptedLinkProps> = memo(({ id, to, label, onRedirect }: InterceptedLinkProps): ReactElement => {
  const navigate = useNavigate();

  const onClick = useCallback(
    (e: SyntheticEvent): void => {
      e.preventDefault();
      if (onRedirect) return onRedirect(to);
      navigate(to);
    },
    [to, onRedirect],
  );

  return (
    <Link data-testid={`link-${id}`} to={to} onClick={onClick} className="prism-link text-primary-dark">
      {label}
    </Link>
  );
});
