import type { Answer, Question } from '../shared/types';

type GetNewQuestionsSetFromAnswersParams = Readonly<{
  newAnswersIds: Array<string>;
  questionId: string;
  prevQuestions: Array<Question>;
}>;

export const getNewQuestionsSetFromAnswers = ({
  newAnswersIds,
  prevQuestions,
  questionId,
}: GetNewQuestionsSetFromAnswersParams): Question[] => {
  const question = prevQuestions.find((question) => question.id === questionId);

  if (!question) {
    return prevQuestions;
  }

  const prevAnswers = question.answers;

  const newAnswers: Answer[] = [];
  for (const newAnswerId of newAnswersIds) {
    const prevAnswer = prevAnswers.find((answer) => answer.id === newAnswerId);
    if (prevAnswer) {
      newAnswers.push(prevAnswer);
    }
  }

  const newQuestion: Question = { ...question, answers: newAnswers };

  return prevQuestions.map((question) => {
    if (question.id === newQuestion.id) {
      return newQuestion;
    }
    return question;
  });
};
