import classnames from 'classnames';
import { useEffect, useState } from 'react';

import type { Answer } from '../../shared/types';
import type { FC, ReactElement } from 'react';

import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';
import { Droppable } from '../../../../modules/draggable/components/Droppable/Droppable';
import { SimpleDraggable } from '../../../../modules/draggable/components/SimpleDraggable/SimpleDraggable';
import { SimpleMultipleContainers } from '../../../../modules/draggable/components/SimpleMultipleContainers/SimpleMultipleContainers';
import { useDragOver } from '../../hooks/useDragOver/useDragOver';
import { answerGroupsNameAndColor, MAIN_CONTAINER_NAME } from '../../shared/constants';
import {
  addAnswerGroupIdToAnswerId,
  createEncodedAnswerId,
  distributeAnswersToAnswersGroups,
  filterUniqueIds,
  getAnswerById,
  getAnswerIdWithoutAnswerGroupId,
  getAnswersGroupsIdsFromAnswerId,
  prepareAnswersToSubmit,
} from '../../utils/answerGroups.utils';
import { AnswerEntry } from '../AnswerEntry/AnswerEntry';
import { AnswerGroup } from '../AnswerGroup/AnswerGroup';
import { GroupedAnswer } from '../GroupedAnswer/GroupedAnswer';
import { GroupedAnswersHeader } from '../GroupedAnswersHeader/GroupedAnswersHeader';
import { MultipleResponsesActionButtons } from '../MultipleResponsesActionButtons/MultipleResponsesActionButtons';

const BASE_DROP_ZONE_CLASS = 'flex flex-col gap-2 p-3 min-h-[150px] border border-1 border-gray-300 rounded-sm';

const CALCULATED_HEIGHT = 'h-[calc(100%-theme(spacing.10))] max-h-[calc(100%-theme(spacing.10))]';

type CreateEditQuestionModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onChange: (answers: Array<Answer>) => void;
  answers?: Array<Answer>;
}>;

const DEFAULT_ANSWERS = [] as Array<Answer>;

/*
  The main idea of the solution here is to generate a unique id for each answer after
  drag and drop. The id is a string that contains the answer id and the answer group id
  where the answer is placed. The answer group id is separated from the answer id by a
  separator. The answer group id is a string just from A to F.

  When user submits the form, the answer group id is removed from the answer id and the
  answer group id is added to the answer object.
 */

export const MultipleResponsesModal: FC<CreateEditQuestionModalProps> = ({
  isOpen,
  onClose,
  onChange,
  answers = DEFAULT_ANSWERS,
}: CreateEditQuestionModalProps): ReactElement => {
  const [answersIdsState, setAnswersIdsState] = useState<Array<string>>([]);

  const [answersGroups, setAnswersGroups] = useState<Record<string, Array<string>>>({});

  const onCreateNewGroup = () => {
    setAnswersGroups((prev) => ({
      ...prev,
      [answerGroupsNameAndColor[Object.keys(prev).length].name]: [],
    }));
  };

  useEffect(() => {
    const initialAnswers = answers.map((answer) => createEncodedAnswerId(answer.id, answer.answerGroups));
    setAnswersIdsState(initialAnswers);
    const initialAnswersGroups = distributeAnswersToAnswersGroups(answers);

    setAnswersGroups(initialAnswersGroups);
  }, [answers]);

  const { overContainer, setOverContainer, onDragOver } = useDragOver({ answersGroups });

  const handleSubmitAnswerGroups = () => {
    const newAnswers = prepareAnswersToSubmit(answers, answersIdsState);

    onChange(newAnswers);
    onClose();
  };

  const onChangeItem = (overContainerId: string, item: string) => {
    if (overContainerId === MAIN_CONTAINER_NAME) {
      return;
    }

    const index = answersIdsState.indexOf(item);
    if (index === -1) {
      return;
    }

    const newAnswers = answersIdsState.map((id, i) => {
      if (i !== index) {
        return id;
      }
      const alreadyAdded = getAnswersGroupsIdsFromAnswerId(id).includes(overContainerId);
      return alreadyAdded ? id : addAnswerGroupIdToAnswerId(id, overContainerId);
    });

    setAnswersIdsState(newAnswers);
  };

  const handleChanges = (values: Record<string, string[]>) => {
    const result = {} as Record<string, string[]>;

    Object.entries(values).forEach(([answerGroupName, answersIds]) => {
      result[answerGroupName] = filterUniqueIds(answersIds);
    });

    setAnswersGroups(result);
    setOverContainer(null);
  };

  const onRemoveAnswerFromAnswerGroup = (answerId: string, answerGroupId: string) => {
    const pureAnswerId = getAnswerIdWithoutAnswerGroupId(answerId);

    const answerGroup = answersGroups[answerGroupId];
    const newAnswerGroup = answerGroup.filter((answer) => answer !== answerId);
    setAnswersGroups((prev) => ({
      ...prev,
      [answerGroupId]: newAnswerGroup,
    }));

    const newAnswers = answersIdsState.map((id) => {
      if (!id.startsWith(pureAnswerId)) {
        return id;
      }
      const ids = getAnswersGroupsIdsFromAnswerId(id).filter((id) => id !== answerGroupId);

      return createEncodedAnswerId(pureAnswerId, ids);
    });

    setAnswersIdsState(newAnswers);
  };

  return (
    <SimpleModal
      sizeClass="max-w-lg h-[calc(100vh-theme(spacing.20))] mt-12"
      modalTitle="Allow Multiple Responses"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div data-testid="answers-container" className={classnames('relative border-gray-300 flex flex-col', CALCULATED_HEIGHT)}>
        <GroupedAnswersHeader answers={answers} />
        <SimpleMultipleContainers
          onChangeItem={onChangeItem}
          onDragOver={onDragOver}
          onChange={handleChanges}
          renderActiveItem={(answerId) => (
            <SimpleDraggable id={answerId} key={answerId}>
              <GroupedAnswer answer={getAnswerById(answerId, answers)} />
            </SimpleDraggable>
          )}
          containers={answersGroups}
        >
          <div className="flex flex-row justify-between sm:gap-3 h-[calc(100%-170px)] sm:h-[calc(100%-110px)]">
            <div className="flex flex-col w-1/2 gap-2" data-testid="answers-section">
              <p className="text-gray-600 text-sm">Answers</p>
              <Droppable
                id="answers-list"
                className="flex flex-col gap-2 p-3 h-full border border-1 border-gray-300 overflow-auto rounded-sm"
                items={answersIdsState}
                renderItem={(answerId) => (
                  <SimpleDraggable id={answerId} key={answerId}>
                    <AnswerEntry key={answerId} answerById={getAnswerById(answerId, answers)} encodedAnswerId={answerId} />
                  </SimpleDraggable>
                )}
              />
            </div>

            <div className="flex flex-col w-1/2 gap-2" data-testid="groups-section">
              <p className="text-gray-600 text-sm">Answers Groups</p>
              <div className="overflow-auto p-4 h-full border border-1 border-gray-300 rounded-sm">
                <div className="flex flex-col gap-2">
                  {answerGroupsNameAndColor.map(({ name }) =>
                    answersGroups[name] ? (
                      <AnswerGroup
                        key={name}
                        name={name}
                        answers={answers}
                        answersIds={answersGroups[name]}
                        onRemoveAnswerFromAnswerGroup={onRemoveAnswerFromAnswerGroup}
                        className={classnames(BASE_DROP_ZONE_CLASS, {
                          'shadow-sm shadow-red-500': overContainer?.containerId === name && !overContainer?.canBeDropped,
                          'shadow-md shadow-blue-500': overContainer?.containerId === name && overContainer?.canBeDropped,
                        })}
                      />
                    ) : null,
                  )}
                </div>
              </div>
            </div>
          </div>
        </SimpleMultipleContainers>
        <MultipleResponsesActionButtons
          onClose={onClose}
          onSubmit={() => handleSubmitAnswerGroups()}
          answers={answers}
          answersGroups={answersGroups}
          onClickNewGroup={onCreateNewGroup}
        />
      </div>
    </SimpleModal>
  );
};
