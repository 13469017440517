import { Fragment, type FC, type ReactElement, memo } from 'react';

import type { QuestionsTableRowItem } from '../../shared/types';

import { DialogModal } from '../../../../components/DialogModal/DialogModal';

export type DeleteQuestionModalProps = Readonly<{
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  question: QuestionsTableRowItem;
}>;

export const DeleteQuestionModal: FC<DeleteQuestionModalProps> = memo(
  ({ isOpen, onSubmit, onClose, question }: DeleteQuestionModalProps): ReactElement => {
    return (
      <Fragment>
        {isOpen && (
          <DialogModal isOpen={isOpen} onSubmitButton={onSubmit} onClose={onClose} submitButtonText="Delete">
            <p className="font-bold text-blue-800 pt-5 px-5">Delete Question: "{question.text}"?</p>
            <p className="font-bold text-blue-800 pb-5 px-5 text-xs">
              Note: Deleting a question will also delete all corresponding answers
            </p>
          </DialogModal>
        )}
      </Fragment>
    );
  },
);
