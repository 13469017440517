import { zodResolver } from '@hookform/resolvers/zod';
import { type FC, type FormEvent, type ReactElement, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import type { Answer, Question } from '../../shared/types';
import type { DefaultValues } from 'react-hook-form';

import { useWatchFormErrors } from '../../../../common/hooks/useWatchFormErrors';
import { deepCompare } from '../../../../common/utils/deepCompare';
import { trimString } from '../../../../common/utils/trimString';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { Label } from '../../../../components/Label/Label';
import { BlockScreenLoading } from '../../../../components/Loaders/BlockScreenLoading/BlockScreenLoading';
import { Modal } from '../../../../components/Modal/Modal';
import { SwitchField } from '../../../../components/SwitchField/SwitchField';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useModalState } from '../../../../modules/form/hooks/useModalState';
import { useCreateEditAnswerAction } from '../../hooks/useCreateEditAnswerAction/useCreateEditAnswerAction';
import { useFormObserver } from '../../hooks/useFormObserver/useFormObserver';
import { useGetDefaultFormData } from '../../hooks/useGetDefaultFormData/useGetDefaultFormData';
import { useHandleMetadata } from '../../hooks/useHandleMetadata';
import { useHandleNestQuestions } from '../../hooks/useHandleNestQuestions/useHandleNestQuestions';
import { useQuestionsContext } from '../../hooks/useQuestionsContext/useQuestionsContext';
import { ANSWER_HANDLERS_MAP, CREATE_ANSWER_INIT_FORM_VALUES } from '../../utils/constants';
import { type CreateEditAnswer, createEditAnswerValidationSchema, mergeCreateEditAnswerSchemas } from '../../utils/schema';
import { AnnouncementsSection } from '../AnnouncementsSection/AnnouncementsSection';
import { AnswerOptionsFormSection } from '../AnswerOptionsFormSection/AnswerOptionsFormSection';
import { ClientPreferencesAnswerSection } from '../ClientPreferencesAnswerSection/ClientPreferencesAnswerSection';
import { FilterOptionsAnswerSection } from '../FilterOptionsAnswerSection/FilterOptionsAnswerSection';
import { FreeFormTextSection } from '../FreeFormTextSection/FreeFormTextSection';
import { GradeAdjustFormSection } from '../GradeAdjustFormSection/GradeAdjustFormSection';
import { MetadataModal } from '../MetadataModal/MetadataModal';
import { NestedQuestionsFormSection } from '../NestedQuestionsFormSection/NestedQuestionsFormSection';
import { NumericEntryFormSection } from '../NumericEntryFormSection/NumericEntryFormSection';

type CreateEditAnswerModalProps = Readonly<{
  isOpen: boolean;
  questionId: string;
  onClose: () => void;
  setCreateEditAnswerModalData: (data: Answer | undefined) => void;
  answerToEdit?: Answer;
}>;

export const CreateEditAnswerModal: FC<CreateEditAnswerModalProps> = ({
  isOpen,
  questionId,
  onClose,
  setCreateEditAnswerModalData,
  answerToEdit,
}: CreateEditAnswerModalProps): ReactElement => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const { state } = useQuestionsContext();
  const question = state.questions.find((q) => q.id === questionId) as Question;

  let isQuestionWithClientPreferences = false;

  if (question && question.tags && /^\d+:\d+$/.test(question.tags[0])) {
    isQuestionWithClientPreferences = true;
  }

  const takePhotoValueExpected = trimString(`${question?.text ?? ''}: ${answerToEdit?.value ?? ''}`, 64, '...');
  const takePhotoValue = answerToEdit?.metaData?.takePhoto?.description || answerToEdit?.metaData?.takePhoto?.title;
  const isAutoTakePhotoDefault = answerToEdit ? takePhotoValueExpected === takePhotoValue : false;
  const [isAutoTakePhoto, setIsAutoTakePhoto] = useState(isAutoTakePhotoDefault);
  const { open: metadataOpen, close: metadataClose, isOpen: isMetadataOpen } = useModalState();

  // TODO: move all the switch control handlers on the level of the section AnswerOptionsFormSection
  const onSetIsRequiresPhotoChange = (newValue: boolean): void => {
    if (newValue) {
      onIsAutoTakePhotoChange(true);
    } else {
      setValue('metaData.takePhoto.description', '');
      setValue('metaData.takePhoto.title', '');
    }
  };

  const onIsNumericEntryChange = (newValue: boolean): void => {
    setValue('chooseQuantity', newValue);
    if (!newValue) {
      setValue('maxQuantity', 0);
    }
  };

  const onIsAutoTakePhotoChange = (newValue: boolean): void => {
    if (newValue) {
      const takePhotoValue = trimString(`${question?.text ?? ''}: ${value ?? ''}`, 64, '...');

      setValue('metaData.takePhoto.description', takePhotoValue);
      setValue('metaData.takePhoto.title', takePhotoValue);
    } else {
      setValue('metaData.takePhoto.description', '');
      setValue('metaData.takePhoto.title', '');
    }

    setIsAutoTakePhoto(newValue);
    void trigger(['metaData.takePhoto.description', 'metaData.takePhoto.title']);
  };

  const { defaultValues, isFetching } = useGetDefaultFormData({
    answerToEdit,
    defaultAnswers: question?.defaultAnswers?.[0],
    onChange: (defaultValues) => reset(defaultValues),
  });

  const { formState, clearErrors, getValues, control, handleSubmit, reset, watch, setValue, trigger } = useForm<CreateEditAnswer>({
    resolver: (data, context, options) => {
      const switchControls = data.switchControls;
      if (!switchControls?.isGradeAdjust) {
        clearErrors('metaData.artCode');
        clearErrors('damages');
        clearErrors('gradeFlag');
        clearErrors('tireInfo');
      }

      return zodResolver(
        mergeCreateEditAnswerSchemas({
          isRequiresPhotos: switchControls?.isRequiresPhoto ?? false,
          isNestQuestion: switchControls?.isNestQuestion ?? false,
          isGradeAdjust: switchControls?.isGradeAdjust ?? false,
          isNumericEntry: switchControls?.isNumericEntry ?? false,
          isClientPreferences: switchControls?.isClientPreferences ?? false,
          isFilters: switchControls?.isFilters ?? false,
          isFreeFormText: switchControls?.isFreeFormText ?? false,
          isAnnouncements: switchControls?.isAnnouncements ?? false,
        }),
      )(data, context, options);
    },
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues as DefaultValues<CreateEditAnswer>,
    shouldFocusError: false,
  });

  const { errors, isValid, isDirty: isFormDirty, isSubmitting } = formState;
  const { value } = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useHandleNestQuestions({
    setValue,
    answerToEdit,
    watch,
  });

  useWatchFormErrors(errors);

  useEffect(() => {
    // TODO: highly likely this should be moved to the form observer as well
    if (isAutoTakePhoto) {
      const takePhotoValue = trimString(`${question?.text ?? ''}: ${value ?? ''}`, 64, '...');

      setValue('metaData.takePhoto', {
        type: 'damage',
        description: takePhotoValue,
        title: takePhotoValue,
      });

      void trigger();
    }
  }, [value]);

  useFormObserver<CreateEditAnswer>({
    setValue,
    watch,
    handlersMap: ANSWER_HANDLERS_MAP,
  });

  const resetForm = () => {
    reset(defaultValues);
    onClose();
    setCreateEditAnswerModalData(undefined);
  };

  const clearForm = () => {
    reset(CREATE_ANSWER_INIT_FORM_VALUES);
    setCreateEditAnswerModalData(undefined);
  };

  const { editAnswer, addAnswer } = useCreateEditAnswerAction({ answerToEdit, questionId, question });

  const onSubmitHandler = (): void => {
    const formData = {
      answerGroups: answerToEdit?.answerGroups,
      ...getValues(),
    };
    if (answerToEdit) {
      editAnswer(formData, resetForm);
    } else {
      addAnswer(formData, resetForm);
    }
  };

  const onCreateNewAnswerHandler = (): void => {
    const formData = {
      answerGroups: answerToEdit?.answerGroups,
      ...getValues(),
    };
    if (answerToEdit) {
      editAnswer(formData, clearForm);
    } else {
      addAnswer(formData, clearForm);
    }
  };

  const onCreateNewAnswer = (event: FormEvent<HTMLButtonElement>): void => {
    void handleSubmit(onCreateNewAnswerHandler)(event);
  };

  const submitBtnText = answerToEdit ? 'Save Answer' : 'Add Answer';

  const switchControls = getValues('switchControls');

  const values = getValues();

  const isFormChanged = useMemo(() => {
    return !deepCompare(defaultValues, values);
  }, [values]);

  const { isMetaDataDirty, onMetaDataChange, setIsMetaDataDirty } = useHandleMetadata({
    formValues: values,
    setValues: setValue,
    schema: createEditAnswerValidationSchema,
  });

  const isDirty = isFormDirty || isMetaDataDirty;
  const isSaveButtonDisabled = !isValid || !isDirty || !isFormChanged;
  const onCloseHandler = (): void => {
    if (isDirty) {
      setIsConfirmationModalOpen(true);
      return;
    }
    resetForm();
    setIsMetaDataDirty(false);
  };

  const onDiscardCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const onDiscard = () => {
    setIsConfirmationModalOpen(false);
    resetForm();
    setIsMetaDataDirty(false);
  };

  return (
    <Modal modalTitle="Add/Edit Answer" isOpen={isOpen} onClose={onCloseHandler}>
      <>
        <DialogModal
          isOpen={isConfirmationModalOpen}
          onClose={onDiscardCancel}
          onCancelButton={onDiscardCancel}
          onSubmitButton={onDiscard}
          submitButtonText="Discard changes"
          cancelButtonText="Continue editing"
        >
          <p data-testid="confirmation-text" className="font-bold text-blue-800 p-5">
            You have unsaved changes. Do you want to continue editing?
          </p>
        </DialogModal>

        <form
          className="flex flex-row justify-center flex-wrap gap-10 mt-4"
          data-testid="create-answer-modal-form"
          onSubmit={(event) => {
            void handleSubmit(onSubmitHandler)(event);
          }}
        >
          <div className="flex flex-col w-80">
            <button data-testid="add-answer-btn" className="qe-btn w-full mb-3" type="submit" disabled={isSaveButtonDisabled}>
              {submitBtnText}
            </button>

            <ExpandableFormSection title="General Info">
              <FormControl
                type="input"
                control={control}
                name="value"
                label="Answer Text"
                controlClassName="flex flex-col w-72"
                showErrorMessage={true}
                isSubmitting={isSubmitting}
                required={true}
              />

              <FormControl
                tooltip="Helper text is useful when users might need additional instructions or information."
                control={control}
                name="helpText"
                label="Answer Helper Text"
                controlClassName="flex flex-col w-72 rounded"
                type="textarea"
                showErrorMessage={true}
                isSubmitting={isSubmitting}
              />
            </ExpandableFormSection>

            {switchControls?.isRequiresPhoto && (
              <ExpandableFormSection title="Photos">
                <div className="flex justify-between items-center pb-4">
                  <Label label="Set Photo Title and Description as Question & Answer Text" name="isAutoTakePhoto" />
                  <SwitchField onChange={onIsAutoTakePhotoChange} name="isAutoTakePhoto" value={isAutoTakePhoto} />
                </div>

                <FormControl
                  type="input"
                  control={control}
                  name="metaData.takePhoto.title"
                  label="Photo Title"
                  controlClassName="flex flex-col w-72"
                  showErrorMessage={true}
                  isSubmitting={isSubmitting}
                  required={true}
                  readOnly={isAutoTakePhoto}
                  maxLength={64}
                />

                <FormControl
                  type="input"
                  control={control}
                  name="metaData.takePhoto.description"
                  label="Photo Description"
                  controlClassName="flex flex-col w-72"
                  showErrorMessage={true}
                  isSubmitting={isSubmitting}
                  required={true}
                  readOnly={isAutoTakePhoto}
                  maxLength={64}
                />
              </ExpandableFormSection>
            )}
            {switchControls?.isGradeAdjust && (
              <GradeAdjustFormSection trigger={trigger} control={control} setValue={setValue} questionGUID={questionId} watch={watch} />
            )}
            {switchControls?.isClientPreferences && (
              <ClientPreferencesAnswerSection control={control} isSubmitting={isSubmitting}></ClientPreferencesAnswerSection>
            )}
            {switchControls?.isFreeFormText && <FreeFormTextSection control={control} isSubmitting={isSubmitting} />}
            {switchControls?.isFilters && <FilterOptionsAnswerSection control={control} isSubmitting={isSubmitting} />}
            {switchControls?.isAnnouncements && (
              <AnnouncementsSection trigger={trigger} setValue={setValue} watch={watch} control={control} isSubmitting={isSubmitting} />
            )}
          </div>

          <div className="flex flex-col items-end w-80">
            <button
              onClick={onCreateNewAnswer}
              data-testid="add-answer-and-create-new-btn"
              className="qe-btn w-full mb-3"
              type="submit"
              disabled={!isValid || !isDirty}
            >
              Save And Add New Answer
            </button>
            <AnswerOptionsFormSection
              control={control}
              setIsRequiresPhoto={onSetIsRequiresPhotoChange}
              onIsNumericEntryChange={onIsNumericEntryChange}
              isQuestionWithClientPreferences={isQuestionWithClientPreferences}
            />
            {switchControls?.isNestQuestion && <NestedQuestionsFormSection control={control} questionGUID={questionId} watch={watch} />}
            {switchControls?.isNumericEntry && <NumericEntryFormSection control={control} watch={watch} setValue={setValue} />}
            {switchControls?.isMetadata && (
              <button data-testid="metadata-btn" className="qe-btn w-full mb-3 fill" type="button" onClick={metadataOpen}>
                Edit Metadata
              </button>
            )}
            {isMetadataOpen && (
              <MetadataModal
                isOpen={isMetadataOpen}
                onClose={metadataClose}
                onChange={onMetaDataChange}
                answer={values}
                schema={createEditAnswerValidationSchema}
              />
            )}
          </div>
          <div data-testid="button-actions" className="w-full flex justify-end mt-3">
            <button type="reset" data-testid="cancel-btn" className="qe-btn w-20" onClick={onCloseHandler}>
              Cancel
            </button>
          </div>
        </form>
        {isFetching && <BlockScreenLoading />}
      </>
    </Modal>
  );
};
