import type { CreateEditAnswer } from './schema';
import type { Metadata } from '../shared/types';

import { mapCreateEditFormStateToAnswerState } from './mapAnswerStateToFormState';
import { clearNullishValues } from '../../../common/utils/clearNullValues';

export const getAnswerCleanedMetaData = (answer: CreateEditAnswer): Metadata => {
  const { metaData } = mapCreateEditFormStateToAnswerState(answer);
  return clearNullishValues(metaData);
};
