import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { GRADE_FLAG_OPTIONS } from '../../shared/constants';

type UseGetGradeFlagFieldConfigParams = {
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
};

const getGradFlagValue = (watch: UseFormWatch<CreateEditAnswer>): OptionType | undefined => {
  const watchGradeFlag = watch('gradeFlag') ?? {};

  const gradeFlagKey = Object.keys(watchGradeFlag)[0];
  const gradeFlagValue = GRADE_FLAG_OPTIONS.find((item) => item.value === gradeFlagKey);

  return gradeFlagValue
    ? {
        label: gradeFlagValue?.label,
        value: gradeFlagValue.value,
      }
    : undefined;
};

export const useGetGradeFlagFieldConfig = (params: UseGetGradeFlagFieldConfigParams): Field => {
  const { setValue, watch, trigger } = params;

  const onChange = (option: OptionType | null) => {
    if (!option) {
      setValue(`gradeFlag`, undefined);
      return;
    }

    setValue(`gradeFlag`, { [option.value]: true });
    void trigger();
  };

  return {
    value: getGradFlagValue(watch),
    options: GRADE_FLAG_OPTIONS,
    onChange,
    isClearable: true,
  };
};
