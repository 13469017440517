import JSONEditor from 'jsoneditor';
import React, { useCallback, useEffect, useRef } from 'react';

import type { EditableNode, JSONEditorMode, MenuItem, ValidationError } from 'jsoneditor';
import './Editor.css';

const filteredActions = ['Transform', 'Sort', 'Extract'];

type EditorProps<T = unknown> = {
  value?: T;
  mode?: JSONEditorMode;
  name?: string;
  sortObjectKeys?: boolean;
  onChange?: (value: T) => void;
  onError?: (error: Error) => void;
  onBlur?: () => void;
  theme?: string;
  navigationBar?: boolean;
  statusBar?: boolean;
  search?: boolean;
  className?: string;
  onEditable?: (node: EditableNode | object) => boolean;
  onValidate?: (json: T) => ValidationError[];
  onSetUpdate?: (updater: (value: T) => void) => void;
  onSetReValidate?: (reValidate: () => void) => void;
  editorInstance?: JSONEditor;
};

export const Editor = <T,>({
  value,
  mode = 'tree',
  name,
  sortObjectKeys = false,
  onChange,
  onError,
  navigationBar = true,
  statusBar = true,
  search = true,
  className,
  onEditable,
  onBlur,
  onValidate,
  onSetUpdate,
  onSetReValidate,
}: EditorProps<T>) => {
  const htmlElementRef = useRef<HTMLDivElement>(null);
  const jsonEditorRef = useRef<JSONEditor | null>(null);

  const handleChange = useCallback(() => {
    try {
      const currentJson = jsonEditorRef.current?.get() as T;
      if (value !== currentJson) {
        onChange?.(currentJson);
      }
    } catch (err) {
      onError?.(err as Error);
    }
  }, [onChange, value, onError]);

  const onCreateMenu = (items: MenuItem[]): MenuItem[] => {
    return items.filter((item) => !filteredActions.includes(item.text));
  };

  const createEditor = useCallback(
    (editorValue?: T) => {
      if (jsonEditorRef.current) {
        jsonEditorRef.current.destroy();
      }

      jsonEditorRef.current = new JSONEditor(htmlElementRef.current!, {
        onChange: handleChange,
        mode,
        name,
        sortObjectKeys,
        navigationBar,
        statusBar,
        search,
        onEditable,
        onValidate,
        onCreateMenu,
      });

      jsonEditorRef?.current.set(editorValue);
    },
    [handleChange, mode, name, sortObjectKeys, navigationBar, statusBar, search],
  );

  const onUpdate = (value: T) => {
    jsonEditorRef.current?.set(value);
  };

  const reValidate = () => {
    void jsonEditorRef.current?.validate();
  };

  useEffect(() => {
    createEditor(value);

    onSetUpdate?.(onUpdate);
    onSetReValidate?.(reValidate);

    return () => {
      jsonEditorRef.current?.destroy();
    };
  }, []);

  return <div data-testid="json-editor" onBlur={onBlur} className={className} ref={htmlElementRef} />;
};
