import type { LabeledNestedFieldValue } from './types';
import type { OptionType } from '../../../common/types';

import { NestedFieldName } from './types';

export const SECTION_GROUPED_ON_CR_OPTIONS: Array<OptionType> = [
  { label: 'Diagnostic Trouble Codes & Mechanical Drivability', value: 'Diagnostic Trouble Codes & Mechanical Drivability' },
  { label: 'Drivability, Keys & History', value: 'Drivability, Keys & History' },
  { label: 'EV', value: 'EV' },
  { label: 'Exterior', value: 'Exterior' },
  { label: 'Glass', value: 'Glass' },
  { label: 'History', value: 'History' },
  { label: 'Interior', value: 'Interior' },
  { label: 'Mechanical', value: 'Mechanical' },
  { label: 'Overall', value: 'Overall' },
  { label: 'Tires', value: 'Tires' },
  { label: 'Warning Lights', value: 'Warning Lights' },
  { label: 'Wheels', value: 'Wheels' },
];

export const SUB_SECTION_IN_VIEW_OPTIONS: Array<OptionType> = [
  { label: 'Body', value: 'Body' },
  { label: 'Body Assessment', value: 'Body Assessment' },
  { label: 'Drivability, Keys & History', value: 'Drivability, Keys & History' },
  { label: 'EV', value: 'EV' },
  { label: 'Exterior', value: 'Exterior' },
  { label: 'History', value: 'History' },
  { label: 'Interior', value: 'Interior' },
  { label: 'Mechanical', value: 'Mechanical' },
  { label: 'Option Charges', value: 'Option Charges' },
  { label: 'Parts Charges', value: 'Parts Charges' },
  { label: 'Tires', value: 'Tires' },
  { label: 'Wear & Use', value: 'Wear & Use' },
  { label: 'Wheels', value: 'Wheels' },
];

export const TIRE_DEPTH_OPTIONS: Array<OptionType> = [
  { label: 'Left Rear: 2', value: 'Left Rear:2' },
  { label: 'Left Rear: 3', value: 'Left Rear:3' },
  { label: 'Left Rear: 4', value: 'Left Rear:4' },
  { label: 'Left Rear: 7', value: 'Left Rear:7' },
  { label: 'Left Rear: 8', value: 'Left Rear:8' },
  { label: 'Right Rear: 2', value: 'Right Rear:2' },
  { label: 'Right Rear: 3', value: 'Right Rear:3' },
  { label: 'Right Rear: 4', value: 'Right Rear:4' },
  { label: 'Right Rear: 7', value: 'Right Rear:7' },
  { label: 'Right Rear: 8', value: 'Right Rear:8' },
  { label: 'Left Front: 2', value: 'Left Front:2' },
  { label: 'Left Front: 3', value: 'Left Front:3' },
  { label: 'Left Front: 4', value: 'Left Front:4' },
  { label: 'Left Front: 7', value: 'Left Front:7' },
  { label: 'Left Front: 8', value: 'Left Front:8' },
  { label: 'Right Front: 2', value: 'Right Front:2' },
  { label: 'Right Front: 3', value: 'Right Front:3' },
  { label: 'Right Front: 4', value: 'Right Front:4' },
  { label: 'Right Front: 7', value: 'Right Front:7' },
  { label: 'Right Front: 8', value: 'Right Front:8' },
];

export const GRADE_FLAG_OPTIONS: Array<OptionType> = [
  { label: 'Salvage Unit', value: 'salvageUnit' },
  { label: 'Certified BioHazard', value: 'certifiedBioHazard' },
  { label: 'Fire Damage', value: 'fireDamage' },
  { label: 'Flood Damage', value: 'floodDamage' },
  { label: 'Frame Damage', value: 'frameDamage' },
  { label: 'Odometer Discrepancy', value: 'odometerDiscrepancy' },
  { label: 'Reassigned Public VIN', value: 'reassignedPublicVIN' },
  { label: 'Warranted Restricted', value: 'warrantyRestricted' },
  { label: 'Warranty Voided', value: 'warrantyVoided' },
  { label: 'Drivable', value: 'drivable' },
];

export const NESTED_QUESTION_OPTIONS: Array<LabeledNestedFieldValue> = [
  { label: 'Subsequent', value: NestedFieldName.requiredQuestions },
  { label: 'Dropdown', value: NestedFieldName.nestedQuestions },
];

export const answerGroupsNameAndColor = [
  { name: 'A', color: 'bg-red-200' },
  { name: 'B', color: 'bg-blue-200' },
  { name: 'C', color: 'bg-green-200' },
  { name: 'D', color: 'bg-yellow-200' },
  { name: 'E', color: 'bg-purple-200' },
  { name: 'F', color: 'bg-pink-200' },
];

export const MODEL_FILTER_OPTIONS = [
  { label: 'Non-iSeries', value: 'noniseries' },
  { label: 'iSeries', value: 'iseries' },
];

export const answerGroupsNameAndColorMap = answerGroupsNameAndColor.reduce(
  (acc, item) => {
    acc[item.name] = item.color;
    return acc;
  },
  {} as Record<string, string>,
);

export const SEPARATOR = ':::';

export const MAIN_CONTAINER_NAME = 'answers-list';

export const NESTED_QUESTIONS_FORM_SUB_GROUP_NAME = 'nestedQuestionDetails';
