import { useRef } from 'react';

export const useNextUrl = (initialNextUrl = '') => {
  const nextUrl = useRef(initialNextUrl);

  const setNextUrl = (newUrl: string) => {
    nextUrl.current = newUrl;
  };

  const getNextId = () => {
    if (nextUrl.current) {
      const url = new URL(nextUrl.current);
      return url.searchParams.get('id');
    }
    return null;
  };

  return { nextUrl: nextUrl.current, setNextUrl, getNextId };
};
