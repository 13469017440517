type Procedure = (...args: never[]) => void;

export const debounce = <F extends Procedure>(func: F, waitFor: number): ((...args: Parameters<F>) => ReturnType<F> | void) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>): ReturnType<F> | void => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => func(...args), waitFor);
  };
};

export const throttle = <F extends Procedure>(func: F, waitFor: number, timeProvider: () => number = Date.now): F => {
  let lastExecutionTime = timeProvider() - waitFor;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return ((...args: never[]) => {
    const doLater = () => {
      lastExecutionTime = timeProvider();
      timeoutId = null;
      func(...args);
    };

    const now = timeProvider();
    if (now - lastExecutionTime >= waitFor) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      doLater();
    } else if (!timeoutId) {
      timeoutId = setTimeout(doLater, waitFor - (now - lastExecutionTime));
    }
  }) as F;
};
