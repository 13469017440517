import type { CommonQuestion } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { UseFormGetFieldState } from 'react-hook-form';
import type { Control } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { Label } from '../../../../components/Label/Label';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { SECTION_GROUPED_ON_CR_OPTIONS, SUB_SECTION_IN_VIEW_OPTIONS } from '../../shared/constants';

type GeneralInfoFormSectionProps = Readonly<{
  control: Control<CommonQuestion>;
  getFieldState: UseFormGetFieldState<CommonQuestion>;
  setValue: (fieldName: 'buyerTranslation', text: string) => void;
  isPropagateQuestionText: boolean;
  onChangePropagateQuestionText: () => void;
  isSubmitting: boolean;
}>;

export const GeneralInfoFormSection: FC<GeneralInfoFormSectionProps> = ({
  control,
  isSubmitting,
  isPropagateQuestionText,
  onChangePropagateQuestionText,
}: GeneralInfoFormSectionProps): ReactElement => {
  return (
    <ExpandableFormSection title="General Info">
      <FormControl
        type="input"
        control={control}
        name="text"
        label="Question Text"
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        required={true}
      />

      <FormControl
        type="create-select"
        isClearable={false}
        control={control}
        name="section"
        label="Section (Grouped on CR)"
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        required={true}
        options={SECTION_GROUPED_ON_CR_OPTIONS}
      />

      <FormControl
        type="create-select"
        isClearable={false}
        control={control}
        name="subSection"
        label="Subsection (In View)"
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        required={false}
        options={SUB_SECTION_IN_VIEW_OPTIONS}
      />

      <FormControl
        control={control}
        name="helpText"
        label="Helper Text"
        controlClassName="flex flex-col w-72 rounded"
        type="textarea"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
      />

      <FormControl
        control={control}
        name="buyerTranslation"
        label="Buyer Translation"
        controlClassName="flex flex-col w-72 rounded"
        type="textarea"
        required={true}
        disabled={isPropagateQuestionText}
        showErrorMessage={true}
        isSubmitting={isSubmitting}
      />

      <div className="flex items-center mt-2">
        <Label name="isPropagateQuestionText" label="Set Buyer Translation as Question Text" />
        <input
          name="isPropagateQuestionText"
          data-testid="isPropagateQuestionText-checkbox"
          type="checkbox"
          className="-order-1 mr-3 ring-0 ring-inherit rounded-none prism-checkbox border border-gray-400 focus:border-blue-800
                focus:outline-none hover:border-2 hover:border-blue-800 cursor-pointer"
          checked={isPropagateQuestionText}
          onChange={onChangePropagateQuestionText}
        />
      </div>
    </ExpandableFormSection>
  );
};
