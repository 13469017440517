import { type FC, type ReactElement } from 'react';

import { InterceptedLink } from '../InterceptedLink/InterceptedLink';

type BreadcrumbProps = Readonly<{
  crumbs: Array<{ label: string; path?: string }>;
  onRedirect?: (to: string) => void;
}>;

export const Breadcrumb: FC<BreadcrumbProps> = ({ crumbs, onRedirect }: BreadcrumbProps): ReactElement => {
  return (
    <div data-testid="breadcrumbs">
      {crumbs.map(({ label, path }, index) => {
        return (
          <span key={label}>
            {path ? <InterceptedLink id={`breadcrumb-${index}`} to={path} label={label} onRedirect={onRedirect} /> : label}
            {index !== crumbs.length - 1 && ' / '}
          </span>
        );
      })}
    </div>
  );
};
