import { useMemo } from 'react';

import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { useAnnouncements } from '../../../../api/hooks/useAnnouncements';

type UseMapToAnnouncementsFieldConfigParams = {
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
};

export const useMapToAnnouncementsFieldConfig = (params: UseMapToAnnouncementsFieldConfigParams): Field => {
  const { setValue, watch, trigger } = params;
  const announcements = watch('announcements');
  const { data, isFetching, error } = useAnnouncements();
  const options = useMemo(() => {
    if (error) {
      return [];
    }

    return data
      ?.filter((item) => item.active)
      .map((item) => ({
        label: item.text,
        value: item.code,
      }));
  }, [data, error, isFetching]);
  const value = useMemo(() => {
    return options?.find((option) => option.value === announcements?.[0]);
  }, [announcements, options]);

  const onChange = (option: OptionType | null) => {
    const { value } = option || {};

    if (!value) {
      return;
    }

    setValue('announcements', [value]);
    void trigger();
  };

  return {
    options,
    onChange,
    isFetching,
    value,
  };
};
