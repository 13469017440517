import { useRoutes } from 'react-router-dom';

import type { FC, ReactElement } from 'react';

import { useScrollToAnchor } from './common/hooks/useScrollToAnchor';
import { routesMap } from './routes';

export const App: FC = (): ReactElement | null => {
  useScrollToAnchor();

  return useRoutes(routesMap);
};
