import type { CreateEditAnswer } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { Control, UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useMapToAnnouncementsFieldConfig } from '../../hooks/useMapToAnnouncementsFieldConfig/useMapToAnnouncementsFieldConfig';

type AnnouncementsSectionProps = Readonly<{
  control: Control<CreateEditAnswer>;
  isSubmitting: boolean;
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
}>;

export const AnnouncementsSection: FC<AnnouncementsSectionProps> = ({
  control,
  isSubmitting,
  setValue,
  watch,
  trigger,
}: AnnouncementsSectionProps): ReactElement => {
  const fieldsCOnfig = useMapToAnnouncementsFieldConfig({ trigger, setValue, watch });

  return (
    <ExpandableFormSection title="Announcements">
      <FormControl
        type="select"
        control={control}
        name="announcements"
        label="Maps to Announcement(s)"
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        placeholder="Select"
        required={true}
        fieldConfig={fieldsCOnfig}
      />
    </ExpandableFormSection>
  );
};
