import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import type { FC, ReactElement } from 'react';

import { config } from '../../../common/config';
import { BlockScreenLoading } from '../../../components/Loaders/BlockScreenLoading/BlockScreenLoading';
import { useAuth } from '../hooks/useAuth';

export const AuthComponent: FC = (): ReactElement | null => {
  const [loginError, setLoginError] = useState<string>();
  const [authLoading, setAuthLoading] = useState(true);
  const { authService } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    authService.setErrorHandler((e) => {
      setLoginError((e as Error).message || '');
      navigate(config.routes.FORM_TYPE);
    });
    if (!authService.isAuthenticated() && config.routes.LOGIN_CALLBACK !== location.pathname) {
      void authService.authorize();
      return;
    }
    setAuthLoading(false);
  }, []);

  if (loginError) {
    return <h1>{loginError}</h1>;
  }

  if (authLoading) {
    return <BlockScreenLoading />;
  }

  return <Outlet />;
};
