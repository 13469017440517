import { useMemo } from 'react';

import type { Answer, QuestionsTableRowItem } from '../../shared/types';
import type { ReactElement } from 'react';

import { DraggableList } from '../../../../modules/draggable';
import { useModalState } from '../../../../modules/form/hooks/useModalState';
import { useQuestionsContext } from '../../hooks/useQuestionsContext/useQuestionsContext';
import { changeAnswerOrder } from '../../store/actions';
import { AnswerRow } from '../AnswerRow/AnswerRow';
import { CreateEditAnswerModal } from '../CreateEditAnswerModal/CreateEditAnswerModal';

type AnswerListProps = Readonly<{
  question: QuestionsTableRowItem;
  isEditMode: boolean;
}>;

export const Answers = ({ question, isEditMode }: AnswerListProps): ReactElement => {
  const answers = question.answers;
  const {
    open: createEditAnswerOpen,
    close: createEditAnswerClose,
    isOpen: isCreateEditAnswerOpen,
    modalData: createEditAnswerModalData,
    setModalData: setCreateEditAnswerModalData,
  } = useModalState<Answer>();
  const { dispatch } = useQuestionsContext();

  const items = useMemo(() => answers.map((row) => row.id), [answers]);

  const getAnswerById = (originalId: string) => answers.find((row) => row.id === originalId);

  return (
    <>
      {isCreateEditAnswerOpen && (
        <CreateEditAnswerModal
          setCreateEditAnswerModalData={setCreateEditAnswerModalData}
          answerToEdit={createEditAnswerModalData}
          isOpen={isCreateEditAnswerOpen}
          onClose={createEditAnswerClose}
          questionId={question.id}
        />
      )}
      <div className="border-b border-b-gray-300 flex flex-col justify-center" data-testid={`${Number(question.id) - 1}-answers-container`}>
        <DraggableList
          items={items}
          as="div"
          onChange={(newAnswersIds) => dispatch(changeAnswerOrder({ questionId: question.id, newAnswersOrderIds: newAnswersIds }))}
          renderItem={(answerId) => (
            <AnswerRow
              onEdit={createEditAnswerOpen}
              question={question}
              answer={getAnswerById(answerId) as Answer}
              isEditMode={isEditMode}
            />
          )}
        />
        <button data-testid="create-new-answer-btn" onClick={() => createEditAnswerOpen()} className="mx-auto qe-btn my-2">
          Add New Answer
        </button>
      </div>
    </>
  );
};
