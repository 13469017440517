import classnames from 'classnames';
import Select from 'react-select';

import type { OptionType } from '../../../../common/types';
import type { FormField, MultiSelectFormControlConfig } from '../../shared/types';
import type { ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';

import { getSelectClassNamesConfig } from '../../utils/getSelectClassnamesConfig';

type MultiSelectFormControlProps<TValues extends FieldValues> = MultiSelectFormControlConfig<TValues> & FormField<TValues>;

export const MultiSelectFormControl = <TValues extends FieldValues>({
  field,
  name,
  isClearable,
  options = [],
  placeholder,
  controlClassName,
  menuClassName,
  isSearchable,
  value,
  components,
  onChange,
}: MultiSelectFormControlProps<TValues>): ReactElement => {
  const selectedOptionValues = value || field?.value || [];

  const selectedOptions = (selectedValues: string[]): OptionType[] => {
    return options?.filter((option) => selectedValues.includes(option.value));
  };

  const onChangeHandler = (selectedOptions: ReadonlyArray<OptionType>) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    field?.onChange(selectedValues);
    onChange?.(selectedOptions);
  };

  return (
    <div data-testid={`${name}-select`}>
      <Select
        {...field}
        isMulti
        isClearable={isClearable}
        isSearchable={isSearchable ?? true}
        options={options}
        menuPlacement="auto"
        classNamePrefix="multi-select"
        classNames={getSelectClassNamesConfig(menuClassName)}
        value={selectedOptions(selectedOptionValues)}
        onChange={onChangeHandler}
        placeholder={placeholder}
        components={{ IndicatorSeparator: () => null, ...components }}
        className={classnames('min-h-[40px] focus:border-blue-200 rounded text-black font-normal', controlClassName)}
      />
    </div>
  );
};
