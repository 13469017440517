import type { Answer as APIAnswer, GuideImage, Question as APIQuestion } from '../../../api/queries/forms/forms.types';
import type { OptionType } from '../../../common/types';
import type { CreateEditAnswer } from '../utils/schema';

export type Answer = APIAnswer & {
  id: string; // id that is necessary for drag and drop logic
};

export type Question = Omit<APIQuestion, 'answers'> & { answers: Array<Answer> };

export type QuestionsTableRowItem = Readonly<{
  order?: number;
  answers: Array<Answer>;
  text: string;
  section: string;
  subSection?: string;
  parent?: boolean;
  id: string;
  guid: string;
  buyerTranslation: string;
  helpText?: string;
  type: 'single' | 'multi';
  metaData?: unknown;
  multipleResponses?: boolean;
  tags?: Array<string>;
  guideImages?: Array<GuideImage>;
  clientValue?: string;
  clientAccountNumber?: string;
  defaultAnswers?: Array<string>;
}>;

export type MoveDirection = 'up' | 'down';

export enum NestedFieldName {
  requiredQuestions = 'requiredQuestions',
  nestedQuestions = 'nestedQuestions',
}

export type LabeledNestedFieldValue = OptionType<NestedFieldName>;

export type AnswerGroupsMap = Record<string, Array<string>>;

// this introduced because of the zod feature/bug
export type RequiredQuestions = [string, ...string[]];

export type GetNestedQuestionDetails = CreateEditAnswer['nestedQuestionDetails'];

export type MapAnswerStateToFormStateParams = { answer: Answer; itemSearch?: string; defaultAnswers?: string };

export type GetNestedQuestions = {
  requiredQuestions?: RequiredQuestions;
  nestedQuestions?: Array<string>;
};

export type GuideImageLabel = 'shared' | 'car' | 'van' | 'suv' | 'truck';

type InferredMetadata = CreateEditAnswer['metaData'];

export type Metadata = InferredMetadata & Record<string, unknown>;

export type QuestionSetSettings = Readonly<{
  asIs: boolean;
  autoTag: boolean;
  metaData: Record<string, unknown>;
}>;
