import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import type { EnvironmentType, StatusType } from '../shared/types';
import type { ColumnDef, SortingState, Table } from '@tanstack/react-table';

import { deploymentManagerStatusColumns } from '../shared/columns';

export const useDeploymentManagerStatusTable = (data: Array<StatusType>): Table<StatusType> => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo<ColumnDef<EnvironmentType>[]>(() => deploymentManagerStatusColumns(), []);

  return useReactTable({
    data,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
};
