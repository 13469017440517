import { SpinnerIcon } from '@prism2/icons-react/prism';

import type { FC, ReactElement } from 'react';

export const BlockScreenLoading: FC = (): ReactElement => {
  return (
    <div className="top-0 left-0 fixed w-full h-full bg-slate-300 flex items-center justify-center opacity-25 z-20" data-testid="loading">
      <SpinnerIcon className="animate-spin text-primary-dark !m-0 h-16" />
    </div>
  );
};
