import { zodResolver } from '@hookform/resolvers/zod';
import { type FC, type FormEvent, type ReactElement, useRef, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import type { Answer, QuestionsTableRowItem } from '../../shared/types';
import type { CommonQuestion } from '../../utils/schema';
import type { SyntheticEvent } from 'react';

import { useWatchFormErrors } from '../../../../common/hooks/useWatchFormErrors';
import { deepCompare } from '../../../../common/utils/deepCompare';
import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { Modal } from '../../../../components/Modal/Modal';
import { useCreateEditFormAction } from '../../hooks/useCreateEditFormAction/useCreateEditFormAction';
import { useFormObserver } from '../../hooks/useFormObserver/useFormObserver';
import { usePrepopulateBuyerTranslationField } from '../../hooks/usePrepopulateBuyerTranslationField/usePrepopulateBuyerTranslationField';
import { useShowAnswersGroupModal } from '../../hooks/useShowAnswersGroupModal/useShowAnswersGroupModal';
import { CREATE_QUESTION_INIT_FORM_VALUES, QUESTION_HANDLERS_MAP } from '../../utils/constants';
import { mapQuestionStateToFormState } from '../../utils/mapQuestionStateToFormState';
import { mergeCreateEditQuestionSchemas } from '../../utils/schema';
import { ClientPreferencesFormSection } from '../ClientPreferencesFormSection/ClientPreferencesFormSection';
import { FilterOptionsFormSection } from '../FilterOptionsFormSection/FilterOptionsFormSection';
import { GeneralInfoFormSection } from '../GeneralInfoFormSection/GeneralInfoFormSection';
import { GuideImagesSection } from '../GuideImagesSection/GuideImagesSection';
import { MultipleResponsesModal } from '../MultipleResponsesModal/MultipleResponsesModal';
import { QuestionOptionsFormSection } from '../QuestionOptionsFormSection/QuestionOptionsFormSection';

type CreateEditQuestionModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  onCreateAnswer: (questionId: string) => void;
  setCreateEditQuestionModalData: (data: QuestionsTableRowItem | undefined) => void;
  questionToEdit?: QuestionsTableRowItem;
}>;

export const CreateEditQuestionModal: FC<CreateEditQuestionModalProps> = ({
  isOpen,
  questionToEdit,
  onClose,
  onCreateAnswer,
  setCreateEditQuestionModalData,
}: CreateEditQuestionModalProps): ReactElement => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const submitBtnText = questionToEdit ? 'Save Question' : 'Add Question';

  const storedQuestionToEdit = useRef<QuestionsTableRowItem | undefined>(questionToEdit);

  const defaultValues = useMemo(() => {
    if (!questionToEdit) {
      return CREATE_QUESTION_INIT_FORM_VALUES;
    }

    return mapQuestionStateToFormState(questionToEdit);
  }, [questionToEdit]);

  const {
    formState: { errors, isValid, isDirty, isSubmitting },
    setValue,
    clearErrors,
    getFieldState,
    control,
    handleSubmit,
    reset,
    watch,
    trigger,
    setError,
    getValues,
  } = useForm<CommonQuestion>({
    resolver: (data, context, options) => {
      const switchControls = data.switchControls;

      return zodResolver(
        mergeCreateEditQuestionSchemas({
          isGuideImages: switchControls?.isGuideImages ?? false,
          isClientPreferences: switchControls?.isClientPreferences ?? false,
          isFilters: switchControls?.isFilters ?? false,
        }),
      )(data, context, options);
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues,
    shouldFocusError: false,
  });

  const values = getValues();

  const isFormChanged = useMemo(() => {
    return !deepCompare(defaultValues, values);
  }, [values]);

  const isSaveButtonDisabled = !isValid || !isDirty || !isFormChanged;

  const switchControls = watch('switchControls');

  useWatchFormErrors(errors);

  useFormObserver<CommonQuestion>({
    setValue,
    watch,
    handlersMap: QUESTION_HANDLERS_MAP,
  });

  const text = watch('text');
  const buyerTranslation = watch('buyerTranslation');
  const answers = watch('answers');

  const { showMultipleResponsesModal, setShowMultipleResponsesModal } = useShowAnswersGroupModal(
    Boolean(switchControls?.isMultipleResponses),
    answers,
  );

  const { isPropagateQuestionText, onChangePropagateQuestionText } = usePrepopulateBuyerTranslationField({
    trigger,
    setError,
    setValue,
    clearErrors,
    text,
    buyerTranslation,
  });

  const resetForm = (createdQuestion?: QuestionsTableRowItem) => {
    reset(questionToEdit as CommonQuestion);
    setCreateEditQuestionModalData(undefined);
    if (createdQuestion) {
      storedQuestionToEdit.current = createdQuestion;
    }
    onClose();
  };

  const { editQuestion, addQuestion } = useCreateEditFormAction({
    questionToEdit,
    callback: resetForm,
  });

  const onCloseHandler = (): void => {
    if (isDirty) {
      setIsConfirmationModalOpen(true);
      return;
    }
    resetForm();
  };

  const onSubmitHandler = (formData: CommonQuestion): void => {
    if (questionToEdit) {
      editQuestion(formData);
    } else {
      addQuestion(formData);
    }
  };

  const onCreateAnswerHandler = async (event: FormEvent<HTMLButtonElement>) => {
    await handleSubmit(onSubmitHandler)(event);
    onCreateAnswer(storedQuestionToEdit.current?.id || '');
  };

  const onCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    onCloseHandler();
  };

  const onDiscardCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const onDiscard = () => {
    setIsConfirmationModalOpen(false);
    resetForm();
  };

  return (
    <Modal modalTitle="Add/Edit Question" isOpen={isOpen} onClose={onCloseHandler}>
      <>
        <DialogModal
          isOpen={isConfirmationModalOpen}
          onClose={onDiscardCancel}
          onCancelButton={onDiscardCancel}
          onSubmitButton={onDiscard}
          submitButtonText="Discard changes"
          cancelButtonText="Continue editing"
        >
          <p data-testid="confirmation-text" className="font-bold text-blue-800 p-5">
            You have unsaved changes. Do you want to continue editing?
          </p>
        </DialogModal>

        <form
          className="flex flex-row justify-center flex-wrap gap-10 mt-4"
          data-testid="create-question-modal-form"
          onSubmit={(event) => {
            void handleSubmit(onSubmitHandler)(event);
          }}
        >
          <div className="flex flex-col w-80">
            <button data-testid="add-question-btn" className="qe-btn w-full mb-3" type="submit" disabled={isSaveButtonDisabled}>
              {submitBtnText}
            </button>

            <GeneralInfoFormSection
              control={control}
              getFieldState={getFieldState}
              isSubmitting={isSubmitting}
              setValue={setValue}
              isPropagateQuestionText={isPropagateQuestionText}
              onChangePropagateQuestionText={onChangePropagateQuestionText}
            />
          </div>

          <div className="flex flex-col items-end w-80">
            <button
              onClick={(ev) => void onCreateAnswerHandler(ev)}
              data-testid="create-answers-btn"
              className="qe-btn w-full mb-3"
              disabled={isSaveButtonDisabled}
            >
              Add Answers
            </button>
            <QuestionOptionsFormSection control={control} />
            {switchControls?.isFilters && <FilterOptionsFormSection control={control} isSubmitting={isSubmitting} />}
            {switchControls?.isGuideImages && (
              <GuideImagesSection control={control} isSubmitting={isSubmitting} watch={watch} setValue={setValue} trigger={trigger} />
            )}
            {switchControls?.isClientPreferences && (
              <ClientPreferencesFormSection control={control} isSubmitting={isSubmitting}></ClientPreferencesFormSection>
            )}
            {switchControls?.isMultipleResponses && (
              <button
                data-testid="group-answers-btn"
                className="qe-btn w-full fill mb-2"
                disabled={!answers?.length}
                onClick={(e) => {
                  e.preventDefault();
                  setShowMultipleResponsesModal(true);
                }}
              >
                Group Answers
              </button>
            )}
            {showMultipleResponsesModal && (
              <MultipleResponsesModal
                answers={answers}
                isOpen={showMultipleResponsesModal}
                onChange={(answers: Answer[]) => setValue('answers', answers, { shouldValidate: true, shouldDirty: true })}
                onClose={() => setShowMultipleResponsesModal(false)}
              />
            )}
          </div>

          <div data-testid="button-actions" className="w-full flex justify-end mt-3">
            <button type="reset" data-testid="cancel-btn" className="qe-btn w-20" onClick={onCancel}>
              Cancel
            </button>
          </div>
        </form>
      </>
    </Modal>
  );
};
