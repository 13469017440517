import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { ApplicationEnvironments, DeployFormDataType, DeployModalDataType } from '../queries/shared/types';

import { QueryKey } from '../constants';
import { deployForm } from '../queries/forms/forms.api';

type UseDeployFormReturn = Readonly<{
  mutate: (values: DeployModalDataType) => void;
  isLoading: boolean;
}>;

type UseDeployFormParam = {
  onCompleteRequest: () => void;
};

export const useDeployForm = ({ onCompleteRequest }: UseDeployFormParam): UseDeployFormReturn => {
  const queryClient = useQueryClient();

  const onRequestSuccess = () => {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_BY_ID_ALL_ENVS]);
    void queryClient.invalidateQueries([QueryKey.TEST_LATEST_VERSIONS]);
    onCompleteRequest();
    toast('Form was sucessfully deployed.', { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Failed to deploy form';
    onCompleteRequest();
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: DeployModalDataType) => {
      const requestPayload: DeployFormDataType = {
        env: values.deployEnvironment as ApplicationEnvironments,
        guid: values.formId,
        isTest: values.isTest ?? false,
        isLatest: values.isLatest ?? false,
        user: values.user,
      };
      return deployForm(requestPayload);
    },
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
