import type { UseGetFieldsConfig } from '../../../../common/types/FieldConfig';
import type { CreateEditAnswer } from '../../utils/schema';

import { useGetDamageDescriptionFieldConfig } from '../useGetDamageDescriptionFieldConfig/useGetDamageDescriptionFieldConfig';
import { useGetGradeFlagFieldConfig } from '../useGetGradeFlagFieldConfig/useGetGradeFlagFieldConfig';
import { useGetItemDescriptionFieldConfig } from '../useGetItemDescriptionFieldConfig/useGetItemDescriptionFieldConfig';
import { useGetSeverityDescFieldConfig } from '../useGetSeverityDescFieldConfig/useGetSeverityDescFieldConfig';
import { useGetTireDepthFieldConfig } from '../useGetTireDepthFieldConfig/useGetTireDepthFieldConfig';

export const useGetGradeAdjustFormFieldsConfig: UseGetFieldsConfig<CreateEditAnswer> = (params) => {
  const { trigger, ...defaultParams } = params;
  return {
    itemDescriptionConfig: useGetItemDescriptionFieldConfig(defaultParams),
    damageCodeConfig: useGetDamageDescriptionFieldConfig(defaultParams),
    severityCodeConfig: useGetSeverityDescFieldConfig({ ...defaultParams, trigger }),
    tireInfoConfig: useGetTireDepthFieldConfig({ ...defaultParams, trigger }),
    gradeFlagConfig: useGetGradeFlagFieldConfig({ ...defaultParams, trigger }),
  };
};
