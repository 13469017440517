export const deepCompare = <T, U>(obj1: T, obj2: U): boolean => {
  const stack: Array<[T | U, T | U]> = [[obj1, obj2]];

  while (stack.length) {
    const [currentObj1, currentObj2] = stack.pop()!;

    if (currentObj1 === currentObj2) {
      continue;
    }

    if (currentObj1 == null || currentObj2 == null || typeof currentObj1 !== 'object' || typeof currentObj2 !== 'object') {
      return false;
    }

    if (Array.isArray(currentObj1) && Array.isArray(currentObj2)) {
      if (currentObj1.length !== currentObj2.length) {
        return false;
      }
      for (let i = 0; i < currentObj1.length; i++) {
        stack.push([currentObj1[i] as T | U, currentObj2[i] as T | U]);
      }
    } else if (Array.isArray(currentObj1) || Array.isArray(currentObj2)) {
      return false;
    } else {
      const keys1 = Object.keys(currentObj1 as object);
      const keys2 = Object.keys(currentObj2 as object);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        if (!keys2.includes(key)) {
          return false;
        }
        stack.push([currentObj1[key as keyof typeof currentObj1] as T | U, currentObj2[key as keyof typeof currentObj2] as T | U]);
      }
    }
  }

  return true;
};
