import { useState } from 'react';

import type { AnswerGroupsMap } from '../../shared/types';

import { MAIN_CONTAINER_NAME } from '../../shared/constants';
import { getAnswerIdsWithoutAnswerGroupId, getAnswerIdWithoutAnswerGroupId } from '../../utils/answerGroups.utils';

type UseDragOverParams = { answersGroups: AnswerGroupsMap };

type UseDragOver = {
  overContainer: { containerId: string; canBeDropped: boolean } | null;
  onDragOver: (overContainerId: string, activeId: string) => void;
  setOverContainer: (overContainer: { containerId: string; canBeDropped: boolean } | null) => void;
};

export const useDragOver = ({ answersGroups }: UseDragOverParams): UseDragOver => {
  const [overContainer, setOverContainer] = useState<{ containerId: string; canBeDropped: boolean } | null>(null);

  const onDragOver = (overContainerId: string, activeId: string) => {
    const pureId = getAnswerIdWithoutAnswerGroupId(activeId);
    const canBeDropped =
      overContainerId !== MAIN_CONTAINER_NAME && !getAnswerIdsWithoutAnswerGroupId(answersGroups[overContainerId]).includes(pureId);
    setOverContainer({ containerId: overContainerId, canBeDropped });
  };

  return { overContainer, onDragOver, setOverContainer };
};
