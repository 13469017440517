import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import type { Question, QuestionsTableRowItem } from '../../shared/types';
import type { CommonQuestion } from '../../utils/schema';

import { copyQuestion } from '../../store/actions';
import { getNewId } from '../../utils/getNewId';
import { mapCopiedAnswersToState } from '../../utils/mapAnswerStateToFormState';
import { mapCreateEditFormStateToQuestionState } from '../../utils/mapQuestionStateToFormState';
import { useQuestionsContext } from '../useQuestionsContext/useQuestionsContext';

type UseCopyQuestionFormAction = {
  copyQuestion: (formData: CommonQuestion) => void;
};

type UseCopyFormActionProps = Readonly<{
  callback: (question: QuestionsTableRowItem) => void;
  questionToCopy?: QuestionsTableRowItem;
  isCopyAnswers?: boolean;
}>;

export const useCopyFormAction = ({ callback, questionToCopy, isCopyAnswers }: UseCopyFormActionProps): UseCopyQuestionFormAction => {
  const { state, dispatch } = useQuestionsContext();

  const _copyQuestion = (formData: CommonQuestion) => {
    const { artMapping } = formData;
    let copiedQuestion: Question = {
      ...mapCreateEditFormStateToQuestionState(formData),
      id: getNewId(state.questions),
      guid: uuidv4().toString(),
    };

    !isCopyAnswers && (copiedQuestion = { ...copiedQuestion, answers: [] });

    copiedQuestion = {
      ...copiedQuestion,
      answers: copiedQuestion.answers.map((answer) => mapCopiedAnswersToState(answer, { artMapping } ?? {})),
    };

    dispatch(copyQuestion({ copiedQuestion, insertNearId: questionToCopy?.id ?? '' }));
    toast(`Question copied successfully`, { type: 'success' });
    callback(copiedQuestion);
  };

  return { copyQuestion: _copyQuestion };
};
