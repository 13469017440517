import classnames from 'classnames';

type SelectClassNamesProps = {
  className?: string;
};
export const getSelectClassNamesConfig = (menuClassName?: string) =>
  menuClassName
    ? {
        menu: (props: SelectClassNamesProps) => classnames(menuClassName, props.className),
      }
    : {};
