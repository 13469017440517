import { useQuery } from '@tanstack/react-query';

import type { OptionType } from '../../../../common/types';

import { ArtProxyAction, QueryKey } from '../../../../api/constants';
import { getDamageOptions } from '../../../../api/queries/artProxy/artProxy.api';

type UseDamageData = Readonly<{
  data?: OptionType[] | undefined;
  isFetching: boolean;
}>;

type UseDamageDataParams = Readonly<{
  itemCode?: string;
  subItemCode?: string;
}>;

export const useDamageData = ({ itemCode, subItemCode }: UseDamageDataParams): UseDamageData => {
  const queryFn = async (): Promise<OptionType[]> => {
    const { items } = await getDamageOptions({ itemCode, subItemCode });
    return items.sort((a, b) => a.description.localeCompare(b.description)).map((item) => ({ value: item.code, label: item.description }));
  };

  return useQuery<OptionType[]>({
    queryKey: [QueryKey.ART_PROXY, ArtProxyAction.ITEM_DAMAGES, itemCode, subItemCode],
    queryFn,
    keepPreviousData: true,
    enabled: Boolean(itemCode && subItemCode),
  });
};
