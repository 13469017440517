import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './assets/index.css';
import 'react-toastify/dist/ReactToastify.css';

import type { AppConfiguration } from './modules/question-engine/shared/types';

import { App } from './App';
import { authConfig } from './common/config';
import { AuthProvider } from './modules/auth/components/AuthProvider';
import { AuthService } from './modules/auth/services/AuthService';

declare global {
  interface Window {
    QuestionsAndAnnouncements: {
      renderQuestions: (configuration: AppConfiguration) => void;
    };
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
});

const authService = AuthService.createInstance(authConfig);
void authService.onApplicationStart();

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <AuthProvider authService={authService}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            theme="light"
          />
        </AuthProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
