import { ArrowsUpDownIcon, EyeIcon, FilterIcon, GearIcon, PlusIcon, SearchIcon } from '@prism2/icons-react/prism';

import type { FC, ReactElement } from 'react';

type QuestionPageControlsProps = Readonly<{
  isEditMode: boolean;
  onToggleEditMode: () => void;
  onToggleFiltering: () => void;
  onCreateButtonClick: () => void;
  onEditButtonClick: () => void;
  onPreviewButtonClick: () => void;
  onSaveButtonCLick: () => void;
  onCancelButtonClick: () => void;
  onQuestionSetSettingsButtonClick: () => void;
  isEdited: boolean;
}>;

export const QuestionPageControls: FC<QuestionPageControlsProps> = ({
  isEditMode,
  onToggleEditMode,
  onToggleFiltering,
  onPreviewButtonClick,
  onSaveButtonCLick,
  onCreateButtonClick,
  onCancelButtonClick,
  onQuestionSetSettingsButtonClick,
  isEdited,
}: QuestionPageControlsProps): ReactElement => {
  const mode = isEditMode ? <ArrowsUpDownIcon /> : <SearchIcon />;

  return (
    <div className="flex justify-between h-10">
      <div className="flex h-10">
        <button
          data-testid="question-set-settings-btn"
          className="qe-btn mr-4"
          onClick={onQuestionSetSettingsButtonClick}
          title="Question Set Settings"
        >
          <GearIcon />
        </button>

        <button disabled={!isEditMode} data-testid="filter-btn" className="qe-btn mr-4" onClick={onToggleFiltering} title="Open filters">
          <FilterIcon />
        </button>

        <button data-testid="view-btn" className="qe-btn mr-4" onClick={onPreviewButtonClick} title="Open preview">
          <EyeIcon />
        </button>

        <button data-testid="mode-btn" className="qe-btn mr-4" onClick={onToggleEditMode} title="Change mode">
          {mode}
        </button>

        <button onClick={() => onCreateButtonClick()} data-testid="create-btn" className="qe-btn mr-4">
          <PlusIcon />
        </button>
      </div>

      {isEdited && (
        <div>
          <button disabled={!isEdited} data-testid="cancel-changes-btn" className="qe-btn mr-4 w-20" onClick={onCancelButtonClick}>
            Cancel
          </button>

          <button disabled={!isEdited} data-testid="save-btn" className="qe-btn fill w-20" onClick={onSaveButtonCLick}>
            Save
          </button>
        </div>
      )}
    </div>
  );
};
