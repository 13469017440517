import { ClipboardCheckedIcon, EditIcon, TrashIcon } from '@prism2/icons-react/prism';
import { type FC, type ReactElement, useCallback } from 'react';

import type { QuestionsTableRowItem } from '../../shared/types';
import type { CellContext } from '@tanstack/react-table';

type QuestionsTableRowActionButtons = Readonly<{
  info: CellContext<QuestionsTableRowItem, unknown>;
  openCreateEditQuestionModal: (questionData: QuestionsTableRowItem) => void;
  openDeleteQuestionModal: (questionData: QuestionsTableRowItem) => void;
  openCopyQuestionModal: (questionData: QuestionsTableRowItem) => void;
}>;

export const QuestionsTableRowActionButtons: FC<QuestionsTableRowActionButtons> = ({
  info,
  openCreateEditQuestionModal,
  openDeleteQuestionModal,
  openCopyQuestionModal,
}: QuestionsTableRowActionButtons): ReactElement => {
  const onEdit = useCallback(() => openCreateEditQuestionModal(info.row.original), [info]);
  const onDelete = useCallback(() => openDeleteQuestionModal(info.row.original), [info]);
  const onCopy = useCallback(() => openCopyQuestionModal(info.row.original), [info]);

  return (
    <div className="w-full flex flex-wrap justify-center gap-1">
      <button data-testid="edit-form-btn" className="qe-btn w-8" onClick={onEdit}>
        <EditIcon />
      </button>
      <button data-testid="copy-form-btn" className="qe-btn w-8" onClick={onCopy}>
        <ClipboardCheckedIcon />
      </button>
      <button data-testid="delete-form-btn" className="qe-btn w-8" onClick={onDelete}>
        <TrashIcon />
      </button>
    </div>
  );
};
