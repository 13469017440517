import type { Answer } from '../../../api/queries/forms/forms.types';

import { NestedFieldName } from '../shared/types';

type GetIsInitialNestedAndNestedType = {
  initialIsNestQuestion: boolean;
  nestingType?: NestedFieldName;
};

export const getIsInitialNestedAndNestingType = <T extends Answer>(answerToEdit?: T): GetIsInitialNestedAndNestedType => {
  if (!answerToEdit) {
    return {
      initialIsNestQuestion: false,
    };
  }

  const areThereNestedQuestions = Boolean(answerToEdit.nestedQuestions?.length);
  const areThereRequiredQuestions = Boolean(answerToEdit.requiredQuestions?.length);

  let nestingType: NestedFieldName | undefined = undefined;

  if (areThereNestedQuestions) {
    nestingType = NestedFieldName.nestedQuestions;
  } else if (areThereRequiredQuestions) {
    nestingType = NestedFieldName.requiredQuestions;
  }

  return {
    initialIsNestQuestion: areThereNestedQuestions || areThereRequiredQuestions,
    nestingType,
  };
};
