import { zodResolver } from '@hookform/resolvers/zod';
import { type FC, type ReactElement, useState } from 'react';
import { useForm } from 'react-hook-form';

import type { QuestionSetSettings } from '../../shared/types';
import type { SyntheticEvent } from 'react';

import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { Modal } from '../../../../components/Modal/Modal';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { Editor } from '../../../../modules/json-editor/components/Editor/Editor';
import { questionSetSettingsSchema } from '../../utils/schema';

const resolver = zodResolver(questionSetSettingsSchema);

type QuestionSetSettingsModalProps = Readonly<{
  isOpen: boolean;
  questionSetSettings: QuestionSetSettings;
  onSubmit: (quesitonSetSettings: QuestionSetSettings) => void;
  onClose: () => void;
}>;

export const QuestionSetSettingsModal: FC<QuestionSetSettingsModalProps> = ({
  isOpen,
  onSubmit,
  onClose,
  questionSetSettings,
}: QuestionSetSettingsModalProps): ReactElement => {
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const {
    formState: { isSubmitting, isDirty },
    control,
    handleSubmit,
    reset,
    setValue,
    watch,
    getValues,
  } = useForm<QuestionSetSettings>({
    resolver,
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      asIs: questionSetSettings.asIs ?? false,
      autoTag: questionSetSettings.autoTag ?? false,
      metaData: questionSetSettings.metaData as { [x: string]: object | undefined },
    },
    shouldFocusError: false,
  });

  const metaData = watch('metaData');

  const handleOnChange = (newData: Record<string, unknown>) => {
    setIsEdited(true);
    setValue('metaData', newData);
  };

  const onCloseHandler = (): void => {
    if (isEdited || isDirty) {
      setIsConfirmationModalOpen(true);
      return;
    }
    reset();
    onClose();
  };

  const onCancel = (e: SyntheticEvent) => {
    e.preventDefault();
    onCloseHandler();
  };

  const onDiscardCancel = () => {
    setIsConfirmationModalOpen(false);
  };

  const onDiscard = () => {
    setIsConfirmationModalOpen(false);
    reset();
    onClose();
  };

  const onSubmitHandler = () => {
    const values = getValues();
    reset(values);
    onSubmit(values);
  };

  return (
    <Modal modalTitle="Question Set Settings" isOpen={isOpen} onClose={onCloseHandler}>
      <>
        <DialogModal
          isOpen={isConfirmationModalOpen}
          onClose={onDiscardCancel}
          onCancelButton={onDiscardCancel}
          onSubmitButton={onDiscard}
          submitButtonText="Discard changes"
          cancelButtonText="Continue editing"
        >
          <p data-testid="confirmation-text" className="font-bold text-blue-800 p-5">
            You have unsaved changes. Do you want to continue editing?
          </p>
        </DialogModal>

        <form
          data-testid="question-set-settings-modal-form"
          onSubmit={(event) => {
            void handleSubmit(onSubmitHandler)(event);
          }}
        >
          <div data-testid="question-set-settings-modal" className="flex flex-col space-y-4">
            <div className="flex flex-col">
              <Editor className="w-full mb-2" value={metaData} onChange={handleOnChange} />
              <div className="flex">
                <FormControl
                  type="switch"
                  control={control}
                  name="asIs"
                  label="As Is"
                  showErrorMessage={true}
                  isSubmitting={isSubmitting}
                  required={false}
                />
                <FormControl
                  type="switch"
                  control={control}
                  name="autoTag"
                  label="Auto Tag"
                  showErrorMessage={true}
                  isSubmitting={isSubmitting}
                  required={false}
                />
              </div>
            </div>
            <div data-testid="button-actions" className="flex flex-wrap justify-end gap-4 mt-10">
              <button onClick={onCancel} type="reset" data-testid="cancel-btn" className="qe-btn w-20">
                Cancel
              </button>
              <button
                type="submit"
                data-testid="save-btn"
                className="qe-btn fill w-30 flex justify-center"
                disabled={!isEdited && !isDirty}
              >
                Save Settings
              </button>
            </div>
          </div>
        </form>
      </>
    </Modal>
  );
};
