import classnames from 'classnames';

import type { Answer } from '../../shared/types';
import type { ReactElement } from 'react';

import { DragHandle } from '../../../../modules/draggable';
import { answerGroupsNameAndColorMap } from '../../shared/constants';
import { getAnswersGroupsIdsFromAnswerId } from '../../utils/answerGroups.utils';

type AnswerEntryGroupProps = Readonly<{
  encodedAnswerId: string;
  answerById: Answer;
}>;

export const AnswerEntry = ({ answerById, encodedAnswerId }: AnswerEntryGroupProps): ReactElement => {
  const answerGroupsIds = getAnswersGroupsIdsFromAnswerId(encodedAnswerId);

  return (
    <div className="border-gray-300 border border-1 rounded-sm p-3 shadow-lg" data-testid={`drag-answer-item:${encodedAnswerId}`}>
      <div className="flex items-center">
        <DragHandle className="w-2 h-2 text-color-200 mr-2" />
        <div data-testid="answer">{answerById.value}</div>
      </div>
      <div className="flex flex-row h-3 gap-2">
        {answerGroupsIds.map((answerGroupId) => (
          <div key={answerGroupId} className="flex items-center gap-2">
            <div
              data-testid={`answer-group-color-code:${answerGroupId}`}
              className={classnames('w-3 h-3 rounded-full', answerGroupsNameAndColorMap[answerGroupId])}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};
