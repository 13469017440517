import { ZodArray, ZodNullable, ZodObject, ZodOptional } from 'zod';

import type { ZodType } from 'zod';

/**
 * Returns an array of property paths derived from a given schema.
 *
 * @param {ZodType | null} schema - The schema to derive property paths from.
 * @return {string[]} An array of names of property paths.
 */
export const getPropertyPaths = (schema?: ZodType | null): string[] => {
  if (!schema) {
    return [];
  }

  if (schema instanceof ZodNullable || schema instanceof ZodOptional) {
    return getPropertyPaths((schema as ZodNullable<never>).unwrap());
  }
  if (schema instanceof ZodArray) {
    return getPropertyPaths((schema as ZodArray<never>).element);
  }
  if (schema instanceof ZodObject) {
    const entries = Object.entries<ZodType>((schema as ZodObject<never>).shape);
    return entries.flatMap(([key, value]) => {
      const nested = getPropertyPaths(value).map((subKey) => `${key}.${subKey}`);
      return nested.length ? nested : key;
    });
  }
  return [];
};
