import type { CommonQuestion } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { Control } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';

type ClientPreferencesFormSectionProps = Readonly<{
  control: Control<CommonQuestion>;
  isSubmitting: boolean;
}>;

export const ClientPreferencesFormSection: FC<ClientPreferencesFormSectionProps> = ({
  control,
  isSubmitting,
}: ClientPreferencesFormSectionProps): ReactElement => {
  return (
    <ExpandableFormSection title="Client Preferences">
      <FormControl
        type="input"
        placeholder=""
        control={control}
        name="clientValue"
        label="Client Value"
        required={true}
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        controlClassName="flex flex-col w-full"
      />
      <FormControl
        type="input"
        placeholder=""
        control={control}
        name="clientAccountNumber"
        label="Client Account Number"
        required={true}
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        controlClassName="flex flex-col w-full"
      />
    </ExpandableFormSection>
  );
};
