export const clearNullishValues = <T extends object>(obj?: T): T => {
  if (!obj) {
    return {} as T;
  }
  const newObj: T = {} as T;
  Object.entries(obj).forEach(([key, value]) => {
    if (value !== null && value !== undefined) {
      newObj[key as keyof T] = value as T[keyof T];
    }
  });
  return newObj;
};
