import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import type { FC, ReactElement } from 'react';
import type { z } from 'zod';

import { useWatchFormErrors } from '../../../../common/hooks/useWatchFormErrors';
import { FormButtons } from '../../../../components/FormButtons/FormButtons';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { createFormTypeValidationSchema } from '../../utils/schema';

export type CreateFormType = z.infer<typeof createFormTypeValidationSchema>;

const defaultValues: CreateFormType = {
  name: '',
  description: '',
};

const resolver = zodResolver(createFormTypeValidationSchema);

type CreateFormTypeFormProps = Readonly<{
  onClose: () => void;
  onSubmit: (data: CreateFormType) => void;
}>;

export const CreateFormTypeForm: FC<CreateFormTypeFormProps> = ({ onClose, onSubmit }: CreateFormTypeFormProps): ReactElement => {
  const {
    formState: { errors, isValid, isSubmitting },
    control,
    handleSubmit,
    reset,
  } = useForm<CreateFormType>({
    resolver,
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues,
    shouldFocusError: false,
  });

  const onSubmitHandler = (data: CreateFormType): void => {
    reset(defaultValues);
    onSubmit(data);
  };

  const onCloseHandler = (): void => {
    reset(defaultValues);
    onClose();
  };

  useWatchFormErrors(errors);

  return (
    <form
      data-testid="create-form-type-modal-form"
      onSubmit={(event) => {
        void handleSubmit(onSubmitHandler)(event);
      }}
    >
      <div data-testid="create-form-type-modal" className="flex flex-col space-y-4">
        <div className="flex flex-col">
          <FormControl
            type="input"
            placeholder="Enter Form Name"
            control={control}
            name="name"
            label="Form Name"
            controlClassName="flex flex-col w-full"
            showErrorMessage={true}
            isSubmitting={isSubmitting}
            required={true}
          />
          <FormControl
            control={control}
            type="textarea"
            name="description"
            placeholder="Enter Description"
            label="Form Description"
            controlClassName="flex flex-col w-full"
            showErrorMessage={true}
            isSubmitting={isSubmitting}
            className="mt-2"
            required={true}
          />
        </div>
        <FormButtons isSaveButtonDisabled={!isValid} onCancelButtonClick={onCloseHandler} />
      </div>
    </form>
  );
};
