import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';

import type { Question, QuestionsTableRowItem } from '../../shared/types';
import type { CommonQuestion } from '../../utils/schema';

import { addQuestion, editQuestion } from '../../store/actions';
import { getNewId } from '../../utils/getNewId';
import { mapCreateEditFormStateToQuestionState } from '../../utils/mapQuestionStateToFormState';
import { useQuestionsContext } from '../useQuestionsContext/useQuestionsContext';

type UseCreateEditFormAction = {
  editQuestion: (formData: CommonQuestion) => void;
  addQuestion: (formData: CommonQuestion) => void;
};

type UseCreateEditFormActionProps = Readonly<{
  callback: (question: QuestionsTableRowItem) => void;
  questionToEdit?: QuestionsTableRowItem;
}>;

export const useCreateEditFormAction = ({ callback, questionToEdit }: UseCreateEditFormActionProps): UseCreateEditFormAction => {
  const { state, dispatch } = useQuestionsContext();

  const _editQuestion = (formData: CommonQuestion) => {
    const mappedQuestion = mapCreateEditFormStateToQuestionState(formData);
    const { parent, order, id, guid } = questionToEdit ?? {};

    const updatedQuestion = {
      ...mappedQuestion,
      parent,
      order,
      id,
      guid,
    } as Question;

    dispatch(editQuestion({ questionId: questionToEdit?.id ?? '', updatedQuestion: updatedQuestion }));

    toast(`Question edited successfully`, { type: 'success' });
    callback(updatedQuestion);
  };

  const _addQuestion = (formData: CommonQuestion) => {
    const mappedQuestion = mapCreateEditFormStateToQuestionState(formData);
    const createdQuestion = {
      ...mappedQuestion,
      id: getNewId(state.questions),
      guid: uuidv4().toString(),
      helpText: '',
    };

    dispatch(addQuestion({ createdQuestion }));
    toast(`Question added successfully`, { type: 'success' });
    callback(createdQuestion);
  };

  return { editQuestion: _editQuestion, addQuestion: _addQuestion };
};
