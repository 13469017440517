import { useNavigate } from 'react-router-dom';

import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import { config } from '../../common/config';

type HeaderProps = Readonly<{
  title: string;
  isEdited?: boolean;
  setIsConfirmationModalOpen?: Dispatch<SetStateAction<boolean>>;
  setPath?: Dispatch<React.SetStateAction<string | undefined>>;
}>;

export const Header: FC<HeaderProps> = ({ title, isEdited, setIsConfirmationModalOpen, setPath }: HeaderProps): ReactElement => {
  const navigate = useNavigate();

  const dataTestId = title.split(' ').join('-').toLocaleLowerCase();

  const logoHandler = () => {
    if (isEdited && setIsConfirmationModalOpen && setPath) {
      setIsConfirmationModalOpen(true);
      setPath(config.routes.FORM_TYPE);
      return;
    }
    navigate(config.routes.FORM_TYPE);
  };

  return (
    <div className="w-full flex flex-row px-10 py-2 white bg-blue-800 items-center justify-between" data-testid={`${dataTestId}-title`}>
      <div className="flex flex-row items-center gap-10">
        <div className="prism-heading-1 text-white border-r-1 cursor-pointer" onClick={logoHandler} data-testid="logo">
          Cox Automotive
        </div>
        <div className="min-h-[1.5em] w-px bg-white"></div>
        <h2 className="prism-heading-2 text-white">{title}</h2>
      </div>
    </div>
  );
};
