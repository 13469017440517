import type { Question } from '../shared/types';

const extractQuestionsReferencingTarget = (questions: Array<Question>, guid: string): Array<string> => {
  const references = new Set<string>();

  for (const question of questions) {
    if (question.guid !== guid && question.answers.length) {
      for (const answer of question.answers) {
        const hasReference = [...(answer.requiredQuestions || []), ...(answer.nestedQuestions || [])].some(
          (questionId) => questionId === guid,
        );

        if (hasReference) {
          references.add(question.guid);
        }
      }
    }
  }

  return Array.from(references);
};

export const deleteQuestionAndUpdateReferences = (questions: Array<Question>, guid: string): Array<Question> => {
  const references = extractQuestionsReferencingTarget(questions, guid);

  if (!references.length) {
    return questions.filter((q) => q.guid !== guid);
  }

  return questions
    .filter((q) => q.guid !== guid)
    .map((q) => {
      return {
        ...q,
        answers: q.answers.map(({ requiredQuestions, nestedQuestions, ...answer }) => {
          const _requiredQuestions = requiredQuestions?.filter((id) => id !== guid);
          const _nestedQuestions = nestedQuestions?.filter((id) => id !== guid);

          return {
            ...answer,
            ...(_requiredQuestions?.length && { requiredQuestions: _requiredQuestions }),
            ...(_nestedQuestions?.length && { nestedQuestions: _nestedQuestions }),
          };
        }),
      };
    });
};
