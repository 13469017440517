import type { FC, ReactNode } from 'react';

import { BlockScreenLoading } from '../Loaders/BlockScreenLoading/BlockScreenLoading';

type PageProps = Readonly<{
  id: string; // page unique identifier
  isLoading?: boolean;
  children?: ReactNode;
}>;

export const Page: FC<PageProps> = ({ id, isLoading, children }: PageProps): JSX.Element => {
  return (
    <div data-testid={`${id}-page`} className="w-full flex flex-col min-h-[calc(100vh-96px)]">
      {children}
      {isLoading && <BlockScreenLoading />}
    </div>
  );
};
