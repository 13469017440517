import { Switch } from '@headlessui/react';
import { CheckmarkIcon } from '@prism2/icons-react/prism';
import classnames from 'classnames';

import type { ColorTheme } from '../../common/types/ColorTheme';
import type { FC, ReactElement } from 'react';

import { ColorThemes } from '../../common/types/ColorTheme';

type SwitchFieldProps = Readonly<{
  name: string;
  labelClass?: string;
  classContainer?: string;
  colorTheme?: ColorTheme;
  disabled?: boolean;
  vertical?: boolean;
  value?: boolean;
  showSwitchText?: boolean;
  onChange?: (checked: boolean) => void;
  ref?: null;
  statusText?: { active: string; inactive: string };
}>;

export const SwitchField: FC<SwitchFieldProps> = (props: SwitchFieldProps): ReactElement => {
  const {
    name = 'test',
    disabled = false,
    vertical,
    showSwitchText = true,
    classContainer = 'justify-between',
    colorTheme = ColorThemes.PRIMARY,
    onChange,
    value,
    ref,
    statusText,
  } = props;
  const transition = 'transition duration-200 ease-in-out';

  const neutral = disabled || !value;

  const lineClassNames = classnames(
    'h-[24px] w-[55px] rounded-full',
    {
      'cursor-pointer': !disabled,
      'bg-neutral-300': neutral,
      'bg-blue-700': colorTheme === ColorThemes.PRIMARY && !neutral,
      'bg-green-600': colorTheme === ColorThemes.GREEN && !neutral,
    },
    transition,
  );

  const circleClassNames = [
    `border border-neutral-300 flex justify-center items-center`,
    `${value ? 'translate-x-6' : '-translate-x-0'} -translate-y-1`,
    disabled ? 'bg-neutral-400' : 'bg-white',
    transition,
    `p-2 inline-block h-[32px] w-[32px] rounded-full pointer-events-none shadow-lg`,
  ];

  const containerClassNames = classnames(classContainer, 'flex', {
    'flex-col': vertical,
    'items-center gap-1': !vertical,
  });

  const textClassName = classnames('w-7 ml-2', {
    'text-neutral-300': disabled,
    'text-blue-700': value && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': value && colorTheme === ColorThemes.GREEN,
    'text-black': !value && !disabled,
  });

  const checkMarkClassNames = classnames({
    'text-neutral-300': disabled,
    'text-blue-700': !disabled && colorTheme === ColorThemes.PRIMARY,
    'text-green-600': !disabled && colorTheme === ColorThemes.GREEN,
  });

  return (
    <div data-testid={`${name}-main-container`} className={containerClassNames}>
      <div data-testid={`${name}-field-container`} className={`flex items-center justify-left py-2 ${vertical ? '' : 'ml-2'}`}>
        <Switch data-testid={`${name}-switch`} disabled={disabled} checked={value} onChange={onChange} className={lineClassNames} ref={ref}>
          <div data-testid={`${name}-circle-container`} aria-hidden="true" className={circleClassNames.join(' ')}>
            {value && <CheckmarkIcon data-testid={`${name}-checkmark`} className={checkMarkClassNames} />}
          </div>
        </Switch>
        {showSwitchText && (
          <span data-testid={`${name}-text`} className={textClassName}>
            {statusText ? <>{value ? statusText.active : statusText.inactive}</> : <>{value ? 'YES' : 'NO'}</>}
          </span>
        )}
      </div>
    </div>
  );
};
