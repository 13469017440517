import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormTrigger, UseFormWatch } from 'react-hook-form';

import { useDamageSeverityData } from '../useDamageSeverityData/useDamageSeverityData';

type UseGetItemDescriptionFieldConfigParams = Readonly<{
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
}>;

export const useGetSeverityDescFieldConfig = (params: UseGetItemDescriptionFieldConfigParams): Field => {
  const { setValue, watch, trigger } = params;

  const itemCode = watch('metaData.artCode.itemCode');
  const itemSubCode = watch('metaData.artCode.itemSubCode');
  const damageCode = watch('metaData.artCode.damageCode');

  const onChange = (value: OptionType | null) => {
    setValue(`damages.severityDesc`, value?.label ?? '');
    if (!value) {
      setValue('metaData.artCode.severityCode', '');
      setValue('damages.severityDesc', '');
      return;
    }

    setValue('damages.severityDesc', value.label);
    void trigger();
  };

  const { data: options, isFetching } = useDamageSeverityData({ itemCode, subItemCode: itemSubCode, damageCode });

  return {
    disabled: !itemCode || !itemSubCode || !damageCode || isFetching,
    options,
    isFetching,
    isClearable: true,
    onChange,
  };
};
