import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useState } from 'react';

import type { BaseItem } from '../../shared/types';
import type { DragEndEvent, DragOverEvent, DragStartEvent } from '@dnd-kit/core';
import type { ReactElement, ReactNode } from 'react';

import { moveBetweenContainers } from '../../utils/moveBetweenContainers';
import { DraggableOverlay } from '../DraggableOverlay/DraggableOverlay';

type DraggableGroupProps<T extends BaseItem> = {
  onChange(items: Record<string, T[]>): void;
  onDragOver?(overContainer: string, activeContainer: string): void;
  onChangeItem?(overContainerId: string, item: string, activeContainerId: string, activeIndex: number): void;
  onDragStart?(id: string): void;
  children?: ReactNode;
  renderActiveItem(item: T): ReactNode;
  containers: Record<string, Array<string>>;
};

export type SortableContainer = {
  current: {
    sortable: {
      containerId: string;
      index: number;
    };
  };
};

/*
  It was designed to use with multiple containers for drop
  and one container for drag when items at the main container
  are "refilled" after each drop. Probably it shouldn't be used
  for other cases.
*/
export const SimpleMultipleContainers = <T extends BaseItem>({
  onChange,
  children,
  renderActiveItem,
  onDragOver,
  containers,
  onChangeItem,
}: DraggableGroupProps<T>): ReactElement => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [active, setActive] = useState<string | null>(null);

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!over) {
      setActive(null);
      return;
    }
    const overContainer = (over?.data as SortableContainer).current?.sortable?.containerId || (over.id as string);

    const activeContainer = (active?.data as SortableContainer)?.current?.sortable?.containerId;
    const activeIndex = (active.data as SortableContainer).current?.sortable?.index;

    if (activeContainer && activeContainer !== overContainer) {
      return;
    }

    const overIndex =
      over.id in containers ? containers[overContainer].length + 1 : (over?.data as SortableContainer).current?.sortable.index;

    if (!containers[overContainer]) {
      return;
    }

    let newItems;
    if (activeContainer === overContainer) {
      newItems = {
        ...containers,
        [overContainer]: arrayMove(containers[overContainer], activeIndex, overIndex),
      };
    } else {
      newItems = moveBetweenContainers({
        containers,
        activeContainer,
        activeIndex,
        overContainer,
        overIndex,
        item: String(active.id),
      });
    }
    onChange(newItems as Record<string, T[]>);
    onChangeItem?.(overContainer, active.id as T, activeContainer, activeIndex);
    setActive(null);
  };

  const handleDragStart = ({ active }: DragStartEvent): void => {
    setActive(String(active.id));
  };

  const handleDragOver = ({ over, active }: DragOverEvent): void => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const overContainer = (over.data as SortableContainer).current?.sortable?.containerId || (overId as string);

    if (!overContainer) {
      return;
    }

    onDragOver?.(overContainer, active.id as string);

    return;
  };

  return (
    <>
      <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
        {children}
        <DraggableOverlay>{active ? renderActiveItem(active as T) : null}</DraggableOverlay>
      </DndContext>
    </>
  );
};
