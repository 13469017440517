import type { AnswerMetaData } from '../../../api/queries/forms/forms.types';
import type { Metadata } from '../shared/types';

import { clearNullishValues } from '../../../common/utils/clearNullValues';

export const mergeMetaDataFields = (
  metaData: Metadata | undefined,
  answerMetadata: Metadata | undefined,
  prohibitedKeys: string[],
): Metadata => {
  const mappedMetaData = {} as Metadata;

  for (const key in metaData) {
    if (!prohibitedKeys.includes(key as keyof AnswerMetaData)) {
      mappedMetaData[key] = metaData?.[key as keyof AnswerMetaData];
    }
  }

  for (const key in answerMetadata) {
    if (prohibitedKeys.includes(key as keyof AnswerMetaData)) {
      mappedMetaData[key] = answerMetadata?.[key as keyof AnswerMetaData];
    }
  }

  return clearNullishValues(mappedMetaData);
};
