import type { CommonQuestion, CreateEditAnswer } from './schema';

import { onSetIsAnnouncementsHandler } from './onSetIsAnnouncementsHandler';
import { onSetIsClientPreferencesHandler } from './onSetIsClientPreferencesHandler';
import { onSetIsCopyAnswersHandler } from './onSetIsCopyAnswersHandler';
import { onSetIsFiltersHandler } from './onSetIsFiltersHandler';
import { onSetIsFreeFormTextHandler } from './onSetIsFreeFormTextHandler';
import { onSetIsGuideImagesHandler } from './onSetIsGuideImagesHandler';
import { onSetIsMultipleResponsesHandler } from './onSetIsMultipleResponsesHandler';
import { onSetIsQuestionClientPreferencesHandler } from './onSetIsQuestionClientPreferencesHandler';
import { onSetIsQuestionFiltersHandler } from './onSetIsQuestionFiltersHandler';

export const CREATE_ANSWER_INIT_FORM_VALUES: CreateEditAnswer = {
  value: '',
  helpText: '',
  switchControls: {
    isRequiresPhoto: false,
    isNestQuestion: false,
    isGradeAdjust: false,
    isFreeFormText: false,
    isClientPreferences: false,
    isFilters: false,
    isDefaultAnswer: false,
    isAnnouncements: false,
  },
  hideBuyerDisplay: false,
  connotation: 0,
  metaData: {
    takePhoto: {
      type: '',
      title: '',
      description: '',
    },
    artCode: {
      itemCode: '',
      itemSubCode: '',
      damageCode: '',
      severityCode: '',
      itemSearch: '',
    },
  },
  chooseQuantity: false,
  tireInfo: undefined,

  maxQuantity: 0,
  tags: [],
};

export const CREATE_QUESTION_INIT_FORM_VALUES: CommonQuestion = {
  switchControls: {
    isClientPreferences: false,
    isFilters: false,
    isGuideImages: false,
    isMultipleResponses: false,
  },
  text: '',
  section: '',
  subSection: '',
  buyerTranslation: '',
  helpText: '',
  answers: [],
  type: 'single' as const,
  guideImages: {
    van: [],
    car: [],
    truck: [],
    shared: [],
    suv: [],
  },
  tags: [],
  clientAccountNumber: '',
  clientValue: '',
};

export const COPY_QUESTION_INIT_FORM_VALUES: CommonQuestion = {
  switchControls: {
    isClientPreferences: false,
    isFilters: false,
    isGuideImages: false,
    isMultipleResponses: false,
    isCopyAnswers: false,
  },
  text: '',
  section: '',
  subSection: '',
  buyerTranslation: '',
  helpText: '',
  answers: [],
  type: 'single' as const,
  guideImages: {
    van: [],
    car: [],
    truck: [],
    shared: [],
    suv: [],
  },
  tags: [],
  clientAccountNumber: '',
  clientValue: '',
  artMapping: { artCode: undefined },
};

export const QUESTION_HANDLERS_MAP = {
  'switchControls.isGuideImages': onSetIsGuideImagesHandler,
  'switchControls.isFilters': onSetIsQuestionFiltersHandler,
  'switchControls.isClientPreferences': onSetIsQuestionClientPreferencesHandler,
  'switchControls.isMultipleResponses': onSetIsMultipleResponsesHandler,
};

export const COPY_QUESTION_HANDLERS_MAP = {
  'switchControls.isGuideImages': onSetIsGuideImagesHandler,
  'switchControls.isFilters': onSetIsQuestionFiltersHandler,
  'switchControls.isClientPreferences': onSetIsQuestionClientPreferencesHandler,
  'switchControls.isMultipleResponses': onSetIsMultipleResponsesHandler,
  'switchControls.osCopyAnswers': onSetIsCopyAnswersHandler,
};

export const ANSWER_HANDLERS_MAP = {
  'switchControls.isClientPreferences': onSetIsClientPreferencesHandler,
  'switchControls.isFilters': onSetIsFiltersHandler,
  'switchControls.isFreeFormText': onSetIsFreeFormTextHandler,
  'switchControls.isAnnouncements': onSetIsAnnouncementsHandler,
};
