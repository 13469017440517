import type { CommonQuestion } from './schema';
import type { Question, QuestionsTableRowItem } from '../shared/types';

import { mapGuideImages, parseGuideImages } from './guideImages';
import { mapTags, parseTags } from './parseTags';

export const mapQuestionStateToFormState = (
  questionToEdit: QuestionsTableRowItem,
  switchControlsToSet: Partial<CommonQuestion['switchControls']> = {},
): CommonQuestion => {
  const isMultipleResponses = questionToEdit?.type === 'multi';
  const isGuideImages = Boolean(questionToEdit?.guideImages?.length);
  const isClientPreferences = Boolean(parseTags(questionToEdit?.tags).clientPreferencesValues[0].length);
  const isFilters = Boolean(
    (isClientPreferences && questionToEdit?.tags && questionToEdit.tags.length > 1) ||
      (!isClientPreferences && questionToEdit?.tags?.length),
  );

  return {
    switchControls: {
      isMultipleResponses,
      isFilters,
      isGuideImages,
      isClientPreferences,
      ...switchControlsToSet,
    },
    defaultAnswers: questionToEdit?.defaultAnswers,
    text: questionToEdit?.text || '',
    section: questionToEdit?.section || '',
    subSection: questionToEdit?.subSection || '',
    buyerTranslation: questionToEdit?.buyerTranslation || '',
    helpText: questionToEdit?.helpText || '',
    answers: questionToEdit?.answers || [],
    type: questionToEdit?.type || ('single' as const),
    guideImages: parseGuideImages(questionToEdit?.guideImages),
    tags: parseTags(questionToEdit?.tags).tagsValues,
    clientAccountNumber: parseTags(questionToEdit?.tags).clientPreferencesValues[0],
    clientValue: parseTags(questionToEdit?.tags).clientPreferencesValues[1],
  };
};

export const mapCreateEditFormStateToQuestionState = (question: CommonQuestion): Omit<Question, 'id' | 'guid'> => {
  const guideImages = mapGuideImages(question);
  const tags = mapTags(question);

  return {
    text: question.text,
    section: question.section,
    subSection: question.subSection,
    buyerTranslation: question.buyerTranslation,
    helpText: question.helpText,
    answers: question.answers || [],
    type: question.type,
    ...(question.defaultAnswers?.length ? { defaultAnswers: question.defaultAnswers } : {}),
    ...guideImages,
    ...tags,
  };
};
