import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { CopyFormTypeParams } from '../queries/formTypes/formTypes.types';

import { QueryKey } from '../constants';
import { copyFormType } from '../queries/formTypes/formTypes.api';

type UseCopyFormTypeParams = Readonly<{
  onSuccess: () => void;
}>;

type UseCopyFormType = Readonly<{
  mutate: (values: CopyFormTypeParams) => void;
  isLoading: boolean;
}>;

export const useCopyFormType = ({ onSuccess }: UseCopyFormTypeParams): UseCopyFormType => {
  const queryClient = useQueryClient();

  const onRequestSuccess = () => {
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_TYPE]);
    toast(`Successfully copied`, { type: 'success' });
    onSuccess();
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || `Failed to copy`;
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (values: CopyFormTypeParams) => copyFormType(values),
    onError: onErrorRequest,
    onSuccess: onRequestSuccess,
  });

  return {
    mutate,
    isLoading,
  };
};
