import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import type { FormType } from '../../api/queries/formTypes/formTypes.types';
import type { FC, ReactElement } from 'react';

import { CopyFormTypeModalWidget } from './components/CopyFormTypeModalWidget/CopyFormTypeModalWidget';
import { CreateFormTypeModalWidget } from './components/CreateFormTypeModalWidget/CreateFormTypeModalWidget';
import { DeleteFormTypeModal } from './components/DeleteFormTypeModal/DeleteFormTypeModal';
import { useFormTypeTable } from './hooks/useFormTypeTable';
import { QueryKey } from '../../api/constants';
import { useFormTypes } from '../../api/hooks/useFormTypes';
import { useInfiniteScroll } from '../../common/hooks/useInfiniteScroll';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { Header } from '../../components/Header/Header';
import { Page } from '../../components/Page/Page';
import { useModalState } from '../../modules/form/hooks/useModalState';

export const FormTypePage: FC = (): ReactElement => {
  const queryClient = useQueryClient();

  const [nextId, setNextId] = useState<string>('');
  const { isFetching, data } = useFormTypes({
    id: nextId,
    next: Boolean(nextId),
  });

  const { accumulatedData, resetData } = useInfiniteScroll({
    isFetching,
    setNextId,
    data,
  });

  const { open: deleteOpen, close: deleteClose, isOpen: deleteIsOpen, modalData: deleteModalData } = useModalState<FormType>();
  const { open: copyOpen, close: copyClose, isOpen: copyIsOpen, modalData: copyModalData } = useModalState<FormType>();

  const table = useFormTypeTable({ formTypeData: accumulatedData, onRemoveFormType: deleteOpen, onCopyFormType: copyOpen });
  const crumbs = [{ label: 'Forms' }];

  const closeDeleteFormTypeModal = () => {
    resetData();
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_TYPE]);
    deleteClose();
  };

  const closeCopyFormTypeModal = () => {
    resetData();
    void queryClient.invalidateQueries([QueryKey.FETCH_FORM_TYPE]);
    copyClose();
  };

  return (
    <Page id="form-type" isLoading={isFetching}>
      <Header title="Forms" />
      <div className="px-8 mt-8">
        <DeleteFormTypeModal
          isOpen={deleteIsOpen}
          onSubmitClose={closeDeleteFormTypeModal}
          onCancel={deleteClose}
          formTypeToDelete={deleteModalData}
        />

        <div data-testid="form-type-table" className="w-full md:w-9/10 lg:w-4/5 xl:w-[1300px] mx-auto flex flex-col gap-y-2">
          <Breadcrumb crumbs={crumbs} />

          <div className="w-full flex sm:justify-end">
            <CreateFormTypeModalWidget />
          </div>

          <div className="w-full flex sm:justify-end">
            <CopyFormTypeModalWidget
              isOpen={copyIsOpen}
              onSubmitClose={closeCopyFormTypeModal}
              onCancel={copyClose}
              formType={copyModalData}
            />
          </div>

          <CommonTable table={table} />
        </div>
      </div>
    </Page>
  );
};
