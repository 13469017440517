import type { CommonQuestion } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

export const onSetIsMultipleResponsesHandler = ({
  watch,
  name,
  setValue,
}: Omit<UseFormObserverProps<CommonQuestion>, 'handlersMap'>): void => {
  const newNameValue = watch(name!);
  const newTypeValue = newNameValue ? 'multi' : 'single';

  setValue('type', newTypeValue);
};
