import classnames from 'classnames';

import type { ReactNode } from 'react';

type FieldState = {
  error?: {
    message?: string;
    root?: {
      message?: string;
    };
  };
  isTouched?: boolean;
  isDirty?: boolean;
};

type FormErrorNotificationProps<T extends FieldState> = {
  fieldState: T;
  isSubmitting?: boolean;
  disabled?: boolean;
};

export const FormErrorNotification = <T extends FieldState>({
  fieldState,
  isSubmitting,
  disabled,
}: FormErrorNotificationProps<T>): ReactNode => {
  const visible = fieldState.error && !disabled && (fieldState.isTouched || isSubmitting || fieldState.isDirty);
  const message = fieldState?.error?.message || fieldState?.error?.root?.message || ' ';

  return (
    <div
      data-testid="error-msg"
      className={classnames('text-red-500 h-4 mt-1 text-xs leading-3 min-w-4', { 'opacity-100': visible, 'opacity-0': !visible })}
    >
      {message}
    </div>
  );
};
