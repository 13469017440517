import type { CommonQuestion } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

export const onSetIsCopyAnswersHandler = ({ watch, name, setValue }: Omit<UseFormObserverProps<CommonQuestion>, 'handlersMap'>): void => {
  const newNameValue = watch(name!);

  if (!newNameValue) {
    setValue('artMapping', {});
  }
};
