import type { CommonQuestion } from './schema';
import type { UseFormObserverProps } from '../hooks/useFormObserver/useFormObserver';

export const onSetIsQuestionClientPreferencesHandler = ({
  watch,
  name,
  setValue,
}: Omit<UseFormObserverProps<CommonQuestion>, 'handlersMap'>): void => {
  const newNameValue = watch(name!);

  if (!newNameValue) {
    setValue('clientValue', '');
    setValue('clientAccountNumber', '');
  }
};
