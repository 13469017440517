const KEY = 'questionSet';

export const removePersistedEntry = (guid: string): void => {
  window.localStorage.removeItem(`${KEY}.${guid}`);
};

export const getPersistedEntry = <T>(guid: string): T | null => {
  const entry = window.localStorage.getItem(`${KEY}.${guid}`);
  let result: T | null;

  try {
    result = JSON.parse(entry || '') as T;
  } catch (e) {
    result = null;
  }
  return result;
};

export const persistEntry = <T>(guid: string, entry: T): void => {
  const stringifiedEntry = JSON.stringify(entry);

  window.localStorage.setItem(`${KEY}.${guid}`, stringifiedEntry);
};
