import classnames from 'classnames';
import { useState } from 'react';

import type { ReactElement } from 'react';

type IconTooltipProps = Readonly<{
  text: string;
  children: ReactElement;
  tooltipClassName?: string;
}>;

const tolltipBaseClassName = 'absolute inline-block rounded px-5 py-2 z-20 shadow w-60 text-center bg-blue-800 text-white text-xs';

/**
 * Shows an information tip text when the info icon is hovered
 */

export const IconTooltip = ({ text, tooltipClassName, children }: IconTooltipProps) => {
  const [show, setShow] = useState<boolean>(false);

  const onMouseLeave = (): void => setShow(false);
  const onMouseEnter = (): void => setShow(true);

  return (
    <div onMouseLeave={onMouseLeave} onMouseEnter={onMouseEnter}>
      {children}
      {show && <div className={classnames(tolltipBaseClassName, tooltipClassName)}>{text}</div>}
    </div>
  );
};
