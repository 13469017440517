import Select from 'react-select';

import type { OptionType } from '../../../../common/types';
import type { Dispatch, FC, ReactElement, SetStateAction } from 'react';

import { QUESTION_ENGINE_FILTER_OPTIONS } from '../../shared/constants';

type QuestionEngineFilterProps = Readonly<{
  filters: Array<OptionType>;
  setFilters: Dispatch<SetStateAction<Array<OptionType>>>;
}>;

export const QuestionEngineFilter: FC<QuestionEngineFilterProps> = ({ filters, setFilters }: QuestionEngineFilterProps): ReactElement => {
  return (
    <Select
      isMulti
      isClearable={false}
      options={QUESTION_ENGINE_FILTER_OPTIONS}
      value={filters}
      classNamePrefix="multi-select"
      onChange={(values) => setFilters(values as Array<OptionType>)}
      placeholder="Select"
      components={{ IndicatorSeparator: () => null }}
      className="min-h-[40px] focus:border-blue-200 rounded text-black font-normal"
    />
  );
};
