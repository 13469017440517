import { ClipboardCheckedIcon, EditIcon, TrashIcon } from '@prism2/icons-react/prism';

import type { FC, ReactElement } from 'react';

type ActionButtonsProps = Readonly<{
  onEdit: () => void;
  omitBtn?: 'edit' | 'copy' | 'remove';
  disabled?: boolean;
  onCopy?: () => void;
  onRemove?: () => void;
}>;

export const ActionButtons: FC<ActionButtonsProps> = ({
  onEdit,
  onCopy,
  onRemove,
  omitBtn,
  disabled,
}: ActionButtonsProps): ReactElement => (
  <div>
    <div className="w-full flex flex-wrap justify-center gap-1">
      {omitBtn !== 'edit' && (
        <button disabled={disabled} data-testid="edit-form-btn" className="qe-btn w-8" onClick={onEdit}>
          <EditIcon />
        </button>
      )}
      {omitBtn !== 'copy' && (
        <button disabled={disabled} data-testid="copy-form-btn" className="qe-btn w-8" onClick={onCopy}>
          <ClipboardCheckedIcon />
        </button>
      )}
      {omitBtn !== 'remove' && (
        <button disabled={disabled} data-testid="remove-form-btn" className="qe-btn w-8" onClick={onRemove}>
          <TrashIcon />
        </button>
      )}
    </div>
  </div>
);
