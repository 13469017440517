import classnames from 'classnames';

import type { Answer } from '../../shared/types';
import type { FC } from 'react';
import type { ReactElement } from 'react';

import { DraggableList } from '../../../../modules/draggable';
import { Droppable } from '../../../../modules/draggable/components/Droppable/Droppable';
import { answerGroupsNameAndColorMap } from '../../shared/constants';
import { getAnswerById } from '../../utils/answerGroups.utils';
import { GroupedAnswer } from '../GroupedAnswer/GroupedAnswer';

type AnswerGroupProps = Readonly<{
  name: string;
  answers: Array<Answer>;
  answersIds: Array<string>;
  className?: string;
  onRemoveAnswerFromAnswerGroup: (answerId: string, name: string) => void;
}>;

export const AnswerGroup: FC<AnswerGroupProps> = ({
  name,
  onRemoveAnswerFromAnswerGroup,
  answers,
  answersIds,
  className,
}: AnswerGroupProps): ReactElement => {
  return (
    <div data-testid={`answer-group-${name}`}>
      <div className="flex items-center gap-2" data-testid="answer-group-title">
        <div className={classnames(answerGroupsNameAndColorMap[name], 'rounded-full w-4 h-4')}></div>
        <p>Group</p>
        <span>{name}</span>
      </div>
      <Droppable
        items={answersIds}
        id={name}
        className={className}
        key={name}
        renderItem={(answerId) => (
          <DraggableList.Item as="div" dragControl id={answerId} key={answerId}>
            <GroupedAnswer answer={getAnswerById(answerId, answers)} onRemoveClick={() => onRemoveAnswerFromAnswerGroup(answerId, name)} />
          </DraggableList.Item>
        )}
      />
    </div>
  );
};
