import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { FetchTestLatestVersionsResponse } from '../queries/forms/forms.types';

import { QueryKey } from '../constants';
import { fetchTestLatestVersions } from '../queries/forms/forms.api';

type TestLatestFormVersionsParams = Readonly<{
  formType?: string;
  enabled?: boolean;
}>;

type TestLatestFormVersions = Readonly<{
  data?: FetchTestLatestVersionsResponse;
  isFetching: boolean;
  error?: Error | null;
}>;

export const useTestLatestFormVersions = ({ formType = '', enabled }: TestLatestFormVersionsParams): TestLatestFormVersions => {
  const queryFn = async (): Promise<FetchTestLatestVersionsResponse> => {
    return await fetchTestLatestVersions({ formType });
  };

  const { data, isFetching, error } = useQuery<FetchTestLatestVersionsResponse, Error>({
    queryKey: [QueryKey.TEST_LATEST_VERSIONS, { formType }],
    queryFn,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    // TODO: refactor as  https://ghe.coxautoinc.com/MAN-Vintage/questions-engine-frontend/issues/40
    onError: (e) => {
      toast(e.message, { type: 'error' });
    },
  });

  return { isFetching, data, error };
};
