import React from 'react';

import type { Header } from '@tanstack/react-table';
import type { ChangeEvent } from 'react';

type TextFilterProps<TD, TV> = Readonly<{
  header: Header<TD, TV>;
}>;

export const TextFilter = <TD, TV>({ header }: TextFilterProps<TD, TV>): JSX.Element => {
  const onFilterChange = (e: ChangeEvent<HTMLInputElement>): void => header.column.setFilterValue(e.target.value);
  const filterValue = header.column.getFilterValue() ?? '';

  return (
    <input
      className="h-7 w-4/5 rounded text-black font-normal"
      name={header.column.id}
      value={String(filterValue)}
      onChange={onFilterChange}
      data-testid={`${header.column.id}-input`}
      type="text"
    />
  );
};
