import type { CreateEditAnswer } from '../../utils/schema';
import type { Control } from 'react-hook-form';
import type { UseFormWatch } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useQuestionsContext } from '../../hooks/useQuestionsContext/useQuestionsContext';
import { NESTED_QUESTION_OPTIONS, NESTED_QUESTIONS_FORM_SUB_GROUP_NAME } from '../../shared/constants';
import { NestedFieldName } from '../../shared/types';
import { excludeQuestions } from '../../utils/excludeQuestions';

type NestedQuestionsFormSectionParams = Readonly<{
  control: Control<CreateEditAnswer>;
  questionGUID: string;
  watch: UseFormWatch<CreateEditAnswer>;
}>;

const MENU_CLASSNAME = 'w-full sm:right-0 sm:w-[480px]';

export const NestedQuestionsFormSection = ({ control, questionGUID, watch }: NestedQuestionsFormSectionParams) => {
  const { state } = useQuestionsContext();
  const nestingType = watch(`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.nestingType`);

  const { questions } = state;
  const questionOptions = excludeQuestions({ questions, excludeId: questionGUID }).map((question) => ({
    label: question.text,
    value: question.guid,
  }));

  return (
    <ExpandableFormSection title="Nesting / Workflow">
      <FormControl
        options={NESTED_QUESTION_OPTIONS}
        type="select"
        name={`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.nestingType`}
        label="Nesting Type"
        control={control}
        required
      />
      {nestingType === NestedFieldName.requiredQuestions && (
        <FormControl
          type="multi-select"
          label="Nest Question"
          controlClassName="flex flex-col"
          menuClassName={MENU_CLASSNAME}
          name={`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.${nestingType}`}
          control={control}
          className="mt-2"
          placeholder="Select One..."
          isClearable={false}
          options={questionOptions}
          isSearchable
          required
        />
      )}
      {nestingType === NestedFieldName.nestedQuestions && (
        <FormControl
          type="select"
          label="Nest Question"
          controlClassName="flex flex-col"
          menuClassName={MENU_CLASSNAME}
          name={`${NESTED_QUESTIONS_FORM_SUB_GROUP_NAME}.${nestingType}`}
          control={control}
          placeholder="Select One..."
          className="mt-2"
          options={questionOptions}
          required
        />
      )}
    </ExpandableFormSection>
  );
};
