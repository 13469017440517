import type { CreateEditAnswer } from '../../utils/schema';
import type { Control, UseFormSetValue } from 'react-hook-form';
import type { UseFormTrigger, UseFormWatch } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { useGetGradeAdjustFormFieldsConfig } from '../../hooks/useGetFieldsConfig/useGetGradeAdjustFormFieldsConfig';

type NestedQuestionsFormSectionParams = Readonly<{
  control: Control<CreateEditAnswer>;
  questionGUID: string;
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
  trigger: UseFormTrigger<CreateEditAnswer>;
}>;

export const GradeAdjustFormSection = ({ control, watch, setValue, trigger }: NestedQuestionsFormSectionParams) => {
  const { itemDescriptionConfig, damageCodeConfig, severityCodeConfig, tireInfoConfig, gradeFlagConfig } =
    useGetGradeAdjustFormFieldsConfig({
      watch,
      setValue,
      trigger,
    });

  return (
    <ExpandableFormSection title="ART Mapping">
      <FormControl
        type="select"
        name="metaData.artCode.itemCode"
        label="ART Item Description"
        fieldConfig={itemDescriptionConfig}
        control={control}
        showErrorMessage={true}
        required
      />
      <FormControl
        type="select"
        name="metaData.artCode.damageCode"
        label="ART Damage Description"
        fieldConfig={damageCodeConfig}
        control={control}
        showErrorMessage={true}
        className="mt-2"
        required
      />
      <FormControl
        type="select"
        name="metaData.artCode.severityCode"
        label="ART Severity Description"
        fieldConfig={severityCodeConfig}
        control={control}
        showErrorMessage={true}
        className="mt-2"
        required
      />

      <FormControl
        type="select"
        control={control}
        name="tireInfo"
        label="Tire Depth"
        fieldConfig={tireInfoConfig}
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
      />

      <FormControl
        type="select"
        control={control}
        name="gradeFlag"
        label="Grade Flag"
        fieldConfig={gradeFlagConfig}
        controlClassName="flex flex-col w-72"
        showErrorMessage={true}
      />
    </ExpandableFormSection>
  );
};
