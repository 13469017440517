import { useController } from 'react-hook-form';

import type { CreateEditAnswer } from '../../utils/schema';
import type { Control } from 'react-hook-form';

type UseConnotationField = Readonly<{
  value: number;
  onConnotationChange: (checked: boolean) => void;
}>;

export const useConnotationField = (control: Control<CreateEditAnswer>): UseConnotationField => {
  const {
    field: { value, onChange },
  } = useController({
    name: 'connotation',
    control,
  });

  const onConnotationChange = (checked: boolean) => {
    onChange(checked ? -1 : 0);
  };

  return { value: value as number, onConnotationChange };
};
