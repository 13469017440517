import { FileTextIcon, TrashIcon } from '@prism2/icons-react/prism';
import { Link } from 'react-router-dom';

import type { FormType } from '../../../api/queries/formTypes/formTypes.types';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import type { ReactElement } from 'react';

import { getNavigationLink } from '../../../modules/navigation/utils/getNavigationLink';

const renderCellToFormLink = (info: CellContext<FormType, unknown>): ReactElement => {
  const name = info.getValue() as string;
  return (
    <span className="decoration-2 cursor-pointer text-primary-dark">
      <Link to={getNavigationLink('FORM', { form: name })}>{name}</Link>
    </span>
  );
};

const renderCellOfActionBtns = (
  info: CellContext<FormType, unknown>,
  onRemoveFormType: (formType: FormType) => void,
  onCopyFormType: (formType: FormType) => void,
): ReactElement | null =>
  info.row.original.currentVersion ? (
    <div className="w-full flex flex-wrap justify-center gap-1">
      <button data-testid="copy-form-btn" className="qe-btn w-8" onClick={() => onCopyFormType(info.row.original)} aria-label="Copy form">
        <FileTextIcon />
      </button>
      <button
        data-testid="remove-form-btn"
        className="qe-btn w-8"
        onClick={() => onRemoveFormType(info.row.original)}
        aria-label="Remove form"
      >
        <TrashIcon />
      </button>
    </div>
  ) : null;

export const formTypeColumns = (
  onRemoveFormType: (formType: FormType) => void,
  onCopyFormType: (formType: FormType) => void,
): ColumnDef<FormType>[] => {
  return [
    {
      accessorKey: 'name',
      header: 'Name',
      cell: (info) => renderCellToFormLink(info),
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 150,
      meta: { headerClass: 'rounded-l text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'version',
      header: 'Current Version',
      cell: (info) => info.row.original.currentVersion?.version ?? '',
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 150,
      meta: { headerClass: 'text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'updatedOn',
      header: 'Last Updated',
      cell: (info) => new Date(info.getValue() as string).toLocaleString(),
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 150,
      meta: { headerClass: 'text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'actions',
      header: '',
      minSize: 70,
      maxSize: 70,
      cell: (info) => renderCellOfActionBtns(info, onRemoveFormType, onCopyFormType),
      meta: { headerClass: 'rounded-r text-center', cellClass: 'text-center' },
    },
  ];
};
