import type { EnvironmentType, StatusType } from './types';
import type { ColumnDef } from '@tanstack/react-table';

export const deploymentManagerColumns = (): ColumnDef<EnvironmentType>[] => {
  return [
    {
      accessorKey: 'environment',
      header: 'Environment',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: { headerClass: 'environment-table-headers text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'testVersion',
      header: 'Is Test Version',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: { headerClass: 'environment-table-headers text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'latestVersion',
      header: 'Is Latest Version',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      meta: { headerClass: 'environment-table-headers text-left', cellClass: 'text-center' },
      minSize: 170,
      maxSize: 180,
    },
  ];
};

export const deploymentManagerStatusColumns = (): ColumnDef<StatusType>[] => {
  return [
    {
      accessorKey: 'status',
      header: 'QA Status',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 90,
      meta: { headerClass: 'status-table-headers text-left text-xs', cellClass: 'text-left text-sm' },
    },
    {
      accessorKey: 'highestEnvironment',
      header: 'Highest Environment',
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      minSize: 90,
      maxSize: 110,
      meta: { headerClass: 'status-table-headers text-left text-xs', cellClass: 'text-left text-sm' },
    },
  ];
};
