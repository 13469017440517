import type { AuthService } from '../services/AuthService';
import type { ReactElement, ReactNode } from 'react';

import { AuthContext } from './AuthContext';

type AuthProviderProps = Readonly<{
  children: ReactNode;
  authService: AuthService;
}>;

export const AuthProvider = (props: AuthProviderProps): ReactElement => {
  const { authService, children } = props;

  return <AuthContext.Provider value={{ authService }}>{children}</AuthContext.Provider>;
};
