import type { CreateEditAnswer } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { Control } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';
import { MODEL_FILTER_OPTIONS } from '../../shared/constants';

type ClientPreferencesAnswerSectionProps = Readonly<{
  control: Control<CreateEditAnswer>;
  isSubmitting: boolean;
}>;

export const ClientPreferencesAnswerSection: FC<ClientPreferencesAnswerSectionProps> = ({
  control,
  isSubmitting,
}: ClientPreferencesAnswerSectionProps): ReactElement => {
  return (
    <ExpandableFormSection title="Client Preferences">
      <FormControl
        type="input"
        placeholder=""
        control={control}
        name="clientDamageItem"
        label="Client Damage Item"
        required={false}
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        controlClassName="flex flex-col w-full"
      />
      <FormControl
        options={MODEL_FILTER_OPTIONS}
        type="select"
        name="modelFilter"
        label="Model Filter"
        control={control}
        required={false}
      />
    </ExpandableFormSection>
  );
};
