import { useEffect, useReducer, useRef } from 'react';

import type { Reducer } from 'react';

export type MiddlewareFn<State, Action> = (state: State, action: Action) => void;

type UseReducerWithMiddleware<State, Action> = [State, (action: Action) => void];

export const useReducerWithMiddleware = <State, Action>(
  reducer: Reducer<State, Action>,
  initialState: State,
  middleWare?: MiddlewareFn<State, Action>,
): UseReducerWithMiddleware<State, Action> => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actionRef = useRef<Action | null>(null);

  const dispatchFn = (action: Action) => {
    actionRef.current = action;
    dispatch(action);
  };

  useEffect(() => {
    if (!actionRef.current || !middleWare) {
      return;
    }
    middleWare(state, actionRef.current);
    actionRef.current = null;
  }, [middleWare, state]);

  return [state, dispatchFn];
};
