import { useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import type { Announcement, FetchAnnouncementsResponse } from '../queries/announcementsProxy/announcements.types';

import { QueryKey } from '../constants';
import { fetchAnnouncements } from '../queries/announcementsProxy/announcements.api';

type UseAnnouncements = Readonly<{
  data?: Array<Announcement>;
  isFetching: boolean;
  error?: Error | null;
}>;

export const useAnnouncements = (): UseAnnouncements => {
  const queryFn = async (): Promise<FetchAnnouncementsResponse> => {
    return await fetchAnnouncements();
  };

  const { data, isFetching, error } = useQuery<FetchAnnouncementsResponse, Error>({
    queryKey: [QueryKey.FETCH_ANNOUNCEMENTS],
    queryFn,
    enabled: true,
    retry: false,
    refetchOnWindowFocus: false,
    onError: (e) => {
      toast(e.message, { type: 'error' });
    },
  });

  return { isFetching, data: data?.items ?? [], error };
};
