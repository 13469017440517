import type { CreateEditAnswer } from './schema';
import type { Answer } from '../../../api/queries/forms/forms.types';

import { MODEL_FILTER_OPTIONS } from '../shared/constants';

type GetClientPreferences = {
  clientDamageItem?: CreateEditAnswer['clientDamageItem'];
  modelFilter?: CreateEditAnswer['modelFilter'];
};

const MODEL_FILTER_OPTION_INDEX = 1;

export const getClientPreferences = (answer: Answer): GetClientPreferences | undefined => {
  const tag = answer?.tags?.[0];

  if (!tag) {
    return undefined;
  }

  const parts = tag.split(':');
  const [, , thirdPart, fourthPart] = parts;

  let clientDamageItem: string | undefined;
  let modelFilter: string | undefined;

  switch (parts.length) {
    case 4:
      clientDamageItem = thirdPart;
      modelFilter = fourthPart;
      break;
    case 3:
      thirdPart.includes('series') ? (modelFilter = thirdPart) : (clientDamageItem = thirdPart);
      break;
  }

  return { clientDamageItem, modelFilter };
};

export const isClientPreferences = (tag?: string): boolean => {
  if (!tag) {
    return false;
  }

  const splittedTag = tag.split(':');

  if (splittedTag.length > 2 || splittedTag.length === 1) {
    return true;
  }

  return MODEL_FILTER_OPTIONS.some((option) => option.value === splittedTag[MODEL_FILTER_OPTION_INDEX]);
};

export const isClientPreference = (tag: string) => {
  const isNumber = /^\d+$/.test(tag);

  const parts = tag.split(':');
  const areAllPartsNumbers = parts.every((part) => /^\d+$/.test(part));

  const containsSeries = tag.includes('series');

  return isNumber || areAllPartsNumbers || containsSeries;
};
