import { useEffect, useState } from 'react';

import type { Answer } from '../../shared/types';

import { usePreviousValue } from '../../../../common/hooks/usePreviousValue';

type UseShowAnswersGroupModal = Readonly<{
  showMultipleResponsesModal: boolean;
  setShowMultipleResponsesModal: (value: boolean) => void;
}>;

export const useShowAnswersGroupModal = (multipleResponses: boolean, answers?: Array<Answer>): UseShowAnswersGroupModal => {
  const prev = usePreviousValue(multipleResponses);

  const [showMultipleResponsesModal, setShowMultipleResponsesModal] = useState(false);

  useEffect(() => {
    if (prev === multipleResponses || !answers?.length) {
      return;
    }

    setShowMultipleResponsesModal(multipleResponses);
  }, [multipleResponses]);

  return { showMultipleResponsesModal, setShowMultipleResponsesModal };
};
