import { flexRender } from '@tanstack/react-table';
import classNames from 'classnames';
import { Fragment } from 'react';

import type { Table } from '@tanstack/react-table';
import type { ReactElement, FC } from 'react';

import { HeaderCell } from './HeaderCell/HeaderCell';

type CommonTableProps<T> = Readonly<{
  table: Table<T>;
  isFilter?: boolean;
  ExpandedComponent?: FC<{ initialValues?: T }>;
  dataTestId?: string;
}>;

export const CommonTable = <T,>({ table, isFilter, ExpandedComponent, dataTestId }: CommonTableProps<T>): ReactElement => {
  return (
    <table data-testid={dataTestId || 'common-table'} className="prism-table w-full table-fixed border-separate border-spacing-0 relative">
      <thead data-testid="table-head">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} data-testid="titles-th">
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                data-testid="title-th"
                colSpan={header.colSpan}
                style={{ width: header.getSize() }}
                className={`${(header.column.columnDef.meta as Record<string, string>)?.headerClass}`}
              >
                <HeaderCell header={header} isFilter={isFilter} />
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody data-testid="table-body">
        {table.getRowModel().rows.map((row, index) => (
          <Fragment key={`${row.id}_${index}`}>
            <tr className="hover:bg-neutral-100 transition-colors duration-75 rounded">
              {row.getVisibleCells().map((cell) => {
                return (
                  <td
                    key={cell.id}
                    data-testid={`${cell.id}-cell`}
                    className={classNames(
                      'border-t-0 border-b border-l-0 border-r-0',
                      (cell.column.columnDef.meta as Record<string, string>)?.cellClass,
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell as string, cell.getContext())}
                  </td>
                );
              })}
            </tr>
            {row.getIsExpanded() && ExpandedComponent && <ExpandedComponent initialValues={row.original} />}
          </Fragment>
        ))}
      </tbody>
    </table>
  );
};
