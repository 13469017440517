import { ChevronDownIcon, ChevronUpIcon } from '@prism2/icons-react/prism';
import { useState } from 'react';

import type { FC, ReactElement, ReactNode } from 'react';

type ExpandableFormSectionProps = Readonly<{
  title: string;
  children: ReactNode;
}>;

export const ExpandableFormSection: FC<ExpandableFormSectionProps> = ({ title, children }): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div data-testid={`${title.toLowerCase().replace(/ /g, '-')}-section`} className="w-80 border rounded mb-2">
      <div className="bg-blue-800 text-white flex justify-between p-3 cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
        {title}
        {isExpanded ? (
          <ChevronUpIcon className="w-5" data-testid="expand-up-icon" />
        ) : (
          <ChevronDownIcon className="w-5" data-testid="expand-down-icon" />
        )}
      </div>
      {isExpanded && <div className="p-3 flex flex-col">{children}</div>}
    </div>
  );
};
