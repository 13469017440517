import classnames from 'classnames';

import type { FormField, InputFormControlConfig } from '../../shared/types';
import type { ChangeEvent, ReactElement } from 'react';
import type { FieldValues } from 'react-hook-form';

type InputFormControlProps<TValues extends FieldValues> = InputFormControlConfig<TValues> & FormField<TValues>;

export const InputFormControl = <TValues extends FieldValues>({
  field,
  name,
  placeholder,
  controlClassName,
  readOnly,
  maxLength,
}: InputFormControlProps<TValues>): ReactElement => {
  const readOnlyStyles = readOnly ? 'text-gray-500 bg-gray-50 border-gray-300' : '';

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (maxLength && e.target.value.length > maxLength) return;
    field?.onChange(e);
  };

  return (
    <input
      {...field}
      onChange={onInputChange}
      id={name}
      data-testid={`${name}-input`}
      placeholder={placeholder}
      autoComplete="off"
      readOnly={readOnly ?? false}
      className={classnames('h-10 px-2 focus:border-blue-200 rounded text-black font-normal', controlClassName, readOnlyStyles)}
    />
  );
};
