import { NotificationCircleIcon } from '@prism2/icons-react/prism';

import type { QuestionsTableRowItem } from '../../shared/types';
import type { CellContext } from '@tanstack/react-table';
import type { ReactElement } from 'react';

import { IconTooltip } from '../../../../components/InfoTip/IconTooltip';

export const TextCell = (info: CellContext<QuestionsTableRowItem, unknown>): ReactElement => {
  const allowMultipleResponse = info.row.original.multipleResponses;
  const isAnswersGrouped = info.row.original.answers.some((answer) => !answer.answerGroups?.length);
  return (
    <div data-testid={`${info.row.original.guid}-text-cell`} className="flex items-center">
      {allowMultipleResponse && isAnswersGrouped && (
        <IconTooltip text="No Answer Group" tooltipClassName="bg-red-600">
          <NotificationCircleIcon
            data-testid={`${info.row.original.guid}-alert-icon`}
            className="text-red-600 min-w-[17px] w-[17px] mr-3"
          />
        </IconTooltip>
      )}
      <span id={info.row.original.guid}>{info.getValue() as string}</span>
    </div>
  );
};
