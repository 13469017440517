import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { syncFormsData } from '../queries/forms/forms.api';

type UseSyncFormsData = Readonly<{
  mutate: (params: { formId: string; highestEnvironment: string }) => void;
  isLoading: boolean;
}>;

export const useSyncFormsData = (): UseSyncFormsData => {
  const onRequestSuccess = () => {
    toast('Successfully Sync', { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Sync failed';
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (params: { formId: string; highestEnvironment: string }) => syncFormsData(params.formId, params.highestEnvironment),
    onSuccess: onRequestSuccess,
    onError: onErrorRequest,
  });

  return {
    mutate,
    isLoading,
  };
};
