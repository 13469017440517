import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { UTCDate } from '@date-fns/utc';
import { set, addDays, isBefore, format } from 'date-fns';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

import type { ScheduleModalDataType } from '../shared/types';
import type { FC, ReactElement } from 'react';

import { Modal } from '../../../components/Modal/Modal';
import { SwitchField } from '../../../components/SwitchField/SwitchField';

type ScheduleFormModalModalProps = Readonly<{
  isOpen: boolean;
  closeModal: () => void;
  onChange: (ScheduleModalDataType: ScheduleModalDataType) => void;
  onCancel: () => void;
  formName: string;
  formVersion: string;
  selectedDate?: Date;
}>;

export const ScheduleFormModal: FC<ScheduleFormModalModalProps> = ({
  isOpen,
  closeModal,
  onChange,
  onCancel,
  formName,
  formVersion,
  selectedDate: date,
}): ReactElement | null => {
  const currentDate = new UTCDate();
  const minDate = isBefore(set(currentDate, { hours: 22, minutes: 55 }), currentDate) ? addDays(currentDate, 1) : currentDate;
  const [isLatest, updateIsLatest] = useState<boolean>(true);
  const [isTest, updateIsTest] = useState<boolean>(true);
  const [deployDate, setDeployDate] = useState<Date>(date ?? minDate);
  const statusText = { active: 'ON', inactive: 'OFF' };

  const reset = () => {
    updateIsLatest(true);
    updateIsTest(true);
    setDeployDate(minDate);
  };

  const submitChange = () => {
    onChange({
      isLatest,
      isTest,
      deployDate,
    });
    reset();
    closeModal();
  };

  const cancel = () => {
    onCancel();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      modalTitle={`${formName} ${formVersion} - ${date ? 'Reschedule' : 'Schedule'} Production Deployment`}
    >
      <>
        <div className="grid grid-cols-auto gap-4" data-testid="schedule-modal-form">
          <div className="col-start-1 col-span-5">
            <div className="flex flex-row">
              <div className="basis-2/3 py-3 px-3">Is Test</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isTestField"
                  name="isTestField"
                  value={isLatest}
                  onChange={(value) => updateIsLatest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>
          <div className="col-start-6 col-span-4">
            <div className="flex flex-row">
              <div className="basis-2/3 py-2 px-2">Is Latest</div>
              <div className="basis-1/4">
                <SwitchField
                  data-testid="isLatestField"
                  name="isLatestField"
                  value={isTest}
                  onChange={(value) => updateIsTest(value)}
                  statusText={statusText}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid mt-10">
          <div className="justify-self-center">
            <DatePicker minDate={minDate} selected={deployDate} onChange={(date) => date && setDeployDate(date)} inline />
          </div>
          <div className="text-center pt-3">
            <p>Note: Deployment time is set at 7:00 PM EST on {date ? format(date, 'MM/dd/yyyy') : 'selected date'}</p>
          </div>
        </div>
        <div className="grid grid-cols-auto gap-4 mt-20">
          <div className="col-start-1 col-span-5 p-2">
            <button className="qe-btn" onClick={cancel}>
              CANCEL DEPLOYMENT
            </button>
          </div>
          <div className="col-start-7 col-span-3 p-2">
            <button className="qe-btn fill w-full" type="button" onClick={submitChange}>
              {date ? 'RE' : ''}SCHEDULE DEPLOYMENT
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};
