export enum QueryKey {
  FETCH_FORM_TYPE = 'form-type',
  FETCH_FORM_BY_ID_ALL_ENVS = 'form-by-id-all-envs',
  FETCH_FORMS = 'forms',
  FETCH_FORM = 'form',
  ART_PROXY = 'art-proxy',
  FETCH_ANNOUNCEMENTS = 'announcements',
  TEST_LATEST_VERSIONS = 'test-latest-versions',
}

export enum ArtProxyAction {
  ITEM_SEARCH = 'itemSearch',
  ITEM_DAMAGES = 'itemDamages',
  ITEM_DAMAGE_SEVERITIES = 'itemDamageSeverities',
  ITEM_BY_ID = 'itemById',
}
