import { createContext } from 'react';

import type { DraggableAttributes, DraggableSyntheticListeners } from '@dnd-kit/core';

type Context = {
  attributes: DraggableAttributes;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
};

export const DraggableItemContext = createContext<Context>({
  attributes: {} as DraggableAttributes,
  listeners: undefined,
  ref: () => ({}),
});
