import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { deleteFormType } from '../queries/formTypes/formTypes.api';

type UseDeleteFormTypeParams = Readonly<{
  onSuccess: () => void;
}>;

type UseDeleteFormType = Readonly<{
  mutate: (formId: string) => void;
  isLoading: boolean;
}>;

export const useDeleteFormType = ({ onSuccess }: UseDeleteFormTypeParams): UseDeleteFormType => {
  const onRequestSuccess = () => {
    onSuccess();
    toast('Successfully deleted', { type: 'success' });
  };

  const onErrorRequest = (e: Error) => {
    const msg = e.message || 'Deletion failed';
    toast(msg, { type: 'error' });
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: (formId: string) => deleteFormType(formId),
    onSuccess: onRequestSuccess,
    onError: onErrorRequest,
  });

  return {
    mutate,
    isLoading,
  };
};
