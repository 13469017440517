import type { Field, OptionType } from '../../../../common/types';
import type { CreateEditAnswer } from '../../utils/schema';
import type { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { setValues } from '../../../../modules/form/utils/setValues';
import { useDamageData } from '../useDamageData/useDamageData';

type UseGetItemDescriptionFieldConfigParams = Readonly<{
  watch: UseFormWatch<CreateEditAnswer>;
  setValue: UseFormSetValue<CreateEditAnswer>;
}>;

export const useGetDamageDescriptionFieldConfig = (params: UseGetItemDescriptionFieldConfigParams): Field => {
  const { watch, setValue } = params;

  const itemCode = watch('metaData.artCode.itemCode');
  const itemSubCode = watch('metaData.artCode.itemSubCode');

  const { data, isFetching } = useDamageData({ itemCode, subItemCode: itemSubCode });

  const onChange = (option: OptionType | null) => {
    setValues(setValue, {
      'metaData.artCode.damageCode': option?.value ?? '',
      'damages.damageDesc': option?.label ?? '',
      'metaData.artCode.severityCode': '',
      'damages.severityDesc': '',
    });
  };

  return {
    disabled: !itemCode || !itemSubCode || isFetching,
    options: data,
    onChange,
    isFetching,
    isClearable: true,
  };
};
