import { getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import type { EnvironmentType } from '../shared/types';
import type { ColumnDef, SortingState, Table } from '@tanstack/react-table';

import { deploymentManagerColumns } from '../shared/columns';

export const useDeploymentManagerTable = (data: Array<EnvironmentType>): Table<EnvironmentType> => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo<ColumnDef<EnvironmentType>[]>(() => deploymentManagerColumns(), []);

  return useReactTable<EnvironmentType>({
    data,
    columns: columns,
    state: { sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
};
