import { CrossSmallIcon } from '@prism2/icons-react/prism';

import type { Answer } from '../../shared/types';
import type { ReactElement } from 'react';

import { DragHandle } from '../../../../modules/draggable';

type GroupedAnswerProps = Readonly<{ answer: Answer; onRemoveClick?: () => void }>;

export const GroupedAnswer = ({ answer, onRemoveClick }: GroupedAnswerProps): ReactElement => (
  <div className="flex items-center relative p-2 bg-gray-100 rounded border border-1" data-testid={`drag-answer-item:${answer.id}`}>
    <DragHandle className="w-2 h-2 text-color-200 mr-2" />
    <div className="w-4/5" data-testid="answer">
      {answer?.value}
    </div>
    {onRemoveClick && (
      <CrossSmallIcon
        data-testid="rmv-answer-from-group-btn"
        className="w-5 h-5 absolute right-1 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
        onClick={onRemoveClick}
      />
    )}
  </div>
);
