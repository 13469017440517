import { GridSmallIcon } from '@prism2/icons-react/prism';
import React from 'react';

import type { ReactElement } from 'react';

import { useDraggableItemContext } from '../../hooks/useDraggableItemContext';

const DEFAULT_HANDLE_CLASS = 'w-5 h-5 text-gray-400';

type DragHandleProps = Readonly<{ className?: string; disabled?: boolean }>;

export const DragHandle = ({ className = DEFAULT_HANDLE_CLASS, disabled }: DragHandleProps): ReactElement => {
  const { attributes, listeners, ...restContext } = useDraggableItemContext();

  return (
    <button
      {...attributes}
      {...listeners}
      disabled={disabled}
      data-testid="drag-handle"
      className={className}
      ref={(elem) => restContext.ref(elem)}
    >
      <GridSmallIcon />
    </button>
  );
};
