import { useQuery } from '@tanstack/react-query';

import type { GetFormTypeResponse } from '../queries/formTypes/formTypes.types';

import { QueryKey } from '../constants';
import { fetchFormTypes } from '../queries/formTypes/formTypes.api';

export type UseFormTypes = Readonly<{
  data?: GetFormTypeResponse;
  isFetching: boolean;
}>;

type UseFormTypesParams = Readonly<{
  formTypeName?: string;
  id?: string;
  next?: boolean;
}>;

export const useFormTypes = ({ formTypeName, next, id }: UseFormTypesParams = {}): UseFormTypes => {
  const queryFn = async (): Promise<GetFormTypeResponse> => {
    return await fetchFormTypes({ formTypeName, id, next, limit: 20, nextLimit: 10 });
  };

  const { data, isFetching } = useQuery<GetFormTypeResponse, Error>({
    queryKey: [QueryKey.FETCH_FORM_TYPE, { formTypeName, id }],
    queryFn,
    cacheTime: 0,
    staleTime: 0,
    enabled: true,
  });

  return { isFetching, data };
};
