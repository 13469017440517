import { ArrowDownIcon, ArrowUpIcon } from '@prism2/icons-react/prism';
import { type FC, type ReactElement } from 'react';

import type { MoveDirection, QuestionsTableRowItem } from '../../shared/types';
import type { CellContext } from '@tanstack/react-table';

type QuestionsTableOrderCellProps = Readonly<{
  info: CellContext<QuestionsTableRowItem, unknown>;
  onMovingRow: (id: string, direction: MoveDirection) => void;
  isEditMode: boolean;
}>;

export const QuestionsTableOrderCell: FC<QuestionsTableOrderCellProps> = ({
  info,
  onMovingRow,
  isEditMode,
}: QuestionsTableOrderCellProps): ReactElement => {
  const onMovingDownClick = () => onMovingRow(info.row.original.id, 'down');
  const onMovingUpClick = () => onMovingRow(info.row.original.id, 'up');

  return (
    <div className="w-full flex flex-wrap justify-center gap-1">
      <button
        disabled={isEditMode}
        data-testid={`${info.row.original.id}-order-down-btn`}
        className="qe-btn w-8"
        onClick={onMovingDownClick}
      >
        <ArrowDownIcon />
      </button>
      <button data-testid={`${info.row.original.id}-order-up-btn`} className="qe-btn w-8" disabled={isEditMode} onClick={onMovingUpClick}>
        <ArrowUpIcon />
      </button>
    </div>
  );
};
