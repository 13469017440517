import { InfoIcon } from '@prism2/icons-react/prism';

import type { FC, ReactElement } from 'react';

import { IconTooltip } from '../../../../components/InfoTip/IconTooltip';

export const QuestionsTableParentHeader: FC = (): ReactElement => {
  return (
    <div className="relative w-full flex flex-row items-center justify-center">
      <div>Parent</div>
      <IconTooltip
        text="A parent question is the main or primary question under which one or several additional nested questions are placed."
        tooltipClassName="right-0"
      >
        <InfoIcon data-testid="info-tip-icon" className="text-white h-4 cursor-pointer ml-2" />
      </IconTooltip>
    </div>
  );
};
