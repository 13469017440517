import type { FC, ReactElement } from 'react';

type ModalTitleProps = Readonly<{
  modalTitle: string;
}>;
export const ModalTitle: FC<ModalTitleProps> = ({ modalTitle }: ModalTitleProps): ReactElement => (
  <h2 data-testid="modal-title" className="text-xl font-bold text-blue-800 my-4">
    {modalTitle}
  </h2>
);
