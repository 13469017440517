import type { Question } from '../shared/types';

type ExcludeQuestionsParams = { questions: Array<Question>; excludeId: string };

type IsTransitiveParams = {
  startQuestionGuid: string;
  questions: Array<Question>;
  questionGUID: string;
};

const isTransitive = ({ startQuestionGuid, questions, questionGUID }: IsTransitiveParams): boolean => {
  const questionMap = new Map<string, Question>();
  questions.forEach((q) => questionMap.set(q.guid, q));

  const queue: string[] = [questionGUID];

  while (queue.length > 0) {
    const currentGuid = queue.shift();
    if (!currentGuid) continue;
    const currentQuestion = questionMap.get(currentGuid);

    if (!currentQuestion) continue;

    for (const answer of currentQuestion.answers) {
      if (answer.requiredQuestions?.includes(startQuestionGuid) || answer.nestedQuestions?.includes(startQuestionGuid)) {
        return true;
      }

      queue.push(...(answer.requiredQuestions || []));
      queue.push(...(answer.nestedQuestions || []));
    }
  }

  return false;
};

export const excludeQuestions = ({ questions, excludeId }: ExcludeQuestionsParams): Array<Question> => {
  const excludeQuestionGuid = questions.find((question) => question.id === excludeId)?.guid;
  if (!excludeQuestionGuid) return [] as Array<Question>;

  return questions.filter(
    (question) =>
      !isTransitive({
        startQuestionGuid: excludeQuestionGuid,
        questions: questions,
        questionGUID: question.guid,
      }) && question.guid !== excludeQuestionGuid,
  );
};
