import { useMemo } from 'react';

import type { Question, QuestionsTableRowItem } from '../../shared/types';
import type { Row, Table } from '@tanstack/react-table';
import type { ReactElement } from 'react';

import { DraggableList } from '../../../../modules/draggable';
import { useQuestionsContext } from '../../hooks/useQuestionsContext/useQuestionsContext';
import { changeQuestionOrder } from '../../store/actions';
import { QuestionRow } from '../QuestionRow/QuestionRow';

type DraggableRowsProps = Readonly<{
  table: Table<QuestionsTableRowItem>;
  isEditMode: boolean;
}>;

export const DraggableRows = ({ table, isEditMode }: DraggableRowsProps): ReactElement => {
  const { dispatch } = useQuestionsContext();

  const items = useMemo(() => table.getRowModel().rows.map((row) => row.original.id), [table.getRowModel().rows]);

  const findRow = (originalId: string) => {
    return table.getRowModel().rows.find((row) => row.original.id === originalId);
  };

  return (
    <DraggableList
      className="grid grid-cols-1 gap-x-4 w-full"
      items={items}
      onDragStart={() => table.toggleAllRowsExpanded(false)}
      as="div"
      onChange={(newQuestionsOrderIds) => dispatch(changeQuestionOrder({ newQuestionsOrderIds }))}
      renderItem={(questionId) => <QuestionRow isEditMode={isEditMode} row={findRow(questionId) as Row<Question>} />}
    />
  );
};
