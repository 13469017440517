import type { FC, ReactElement, ReactNode } from 'react';

import { Modal } from '../Modal/Modal';

export type DialogModalProps = Readonly<{
  isOpen: boolean;
  onClose: () => void;
  submitButtonText?: string;
  onSubmitButton?: (...args: unknown[]) => void;
  cancelButtonText?: string;
  onCancelButton?: () => void;
  children: ReactNode;
  name?: string;
}>;

export const DialogModal: FC<DialogModalProps> = (props: DialogModalProps): ReactElement => {
  const {
    isOpen,
    onClose,
    submitButtonText = 'Submit',
    name,
    onSubmitButton,
    cancelButtonText = 'Cancel',
    onCancelButton,
    children,
  } = props;

  const onCancelHandler = (): void => {
    if (onCancelButton) onCancelButton();
    onClose();
  };

  const onSubmitHandler = (): void => {
    if (onSubmitButton) onSubmitButton();
    onClose();
  };

  const prefix = name ? `${name}-` : '';

  return (
    <Modal isOpen={isOpen} onClose={onClose} sizeClass="max-w-sm w-auto">
      <div data-testid={`${prefix}${'dialog-modal'}`} className="flex flex-col">
        <div data-testid="dialog-modal-content" className="flex flex-col">
          {children}
        </div>
        <div data-testid="dialog-modal-buttons" className="flex gap-4 items-center justify-end">
          <button data-testid="dialog-modal-cancel-btn" className="qe-btn" onClick={onCancelHandler}>
            {cancelButtonText}
          </button>
          <button data-testid="dialog-modal-submit-btn" className="qe-btn fill" onClick={onSubmitHandler}>
            {submitButtonText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
