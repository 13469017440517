import classnames from 'classnames';
import { type ChangeEvent, type ReactElement } from 'react';

import type { FormField, NumberInputFormControlConfig } from '../../shared/types';
import type { FieldValues } from 'react-hook-form';

type NumberInputFormControlProps<TValues extends FieldValues> = NumberInputFormControlConfig<TValues> & FormField<TValues>;

export const NumberInputFormControl = <TValues extends FieldValues>({
  field,
  name,
  placeholder,
  controlClassName,
  readOnly,
}: NumberInputFormControlProps<TValues>): ReactElement => {
  const readOnlyStyles = readOnly ? 'text-gray-500 bg-gray-50 border-gray-300' : '';
  const inputStyles = '[&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none';

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    field?.onChange(Number(e.target.value));
  };

  return (
    <input
      {...field}
      onChange={onInputChange}
      id={name}
      data-testid={`${name}-input`}
      placeholder={placeholder}
      autoComplete="off"
      readOnly={readOnly ?? false}
      type="number"
      className={classnames(
        'h-10 px-2 focus:border-blue-200 rounded text-black font-normal',
        controlClassName,
        readOnlyStyles,
        inputStyles,
      )}
    />
  );
};
