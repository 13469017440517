import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useMemo } from 'react';

import type { UniqueIdentifier } from '@dnd-kit/core';
import type { CSSProperties, ReactElement, ReactNode } from 'react';

import { DraggableItemContext } from '../../shared/DraggableItemContext';

type DraggableItemProps = Readonly<{
  id: UniqueIdentifier;
  as?: 'li' | 'div' | 'tr' | 'td'; // keyof ReactHTML add more if you need
  className?: string;
  dragControl?: boolean;
  children?: ReactNode;
}>;

export const DraggableItem = ({ children, id, as: Component = 'li', className, dragControl }: DraggableItemProps): ReactElement => {
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const style: CSSProperties = {
    opacity: isDragging ? 0.6 : undefined,
    transform: CSS.Translate.toString(transform),
    transition: transition ?? undefined,
  };

  const appliedListeners = dragControl ? undefined : listeners;

  return (
    <DraggableItemContext.Provider value={context}>
      <Component ref={setNodeRef} className={className} style={style} {...appliedListeners}>
        {children}
      </Component>
    </DraggableItemContext.Provider>
  );
};
