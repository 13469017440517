import { Link, useLocation } from 'react-router-dom';

import type { Answer, QuestionsTableRowItem } from '../../shared/types';
import type { FC, ReactElement } from 'react';

import { DialogModal } from '../../../../components/DialogModal/DialogModal';
import { DraggableList, DragHandle } from '../../../../modules/draggable';
import { useModalState } from '../../../../modules/form/hooks/useModalState';
import { useQuestionsContext } from '../../hooks/useQuestionsContext/useQuestionsContext';
import { deleteAnswer } from '../../store/actions';
import { getQuestionByGUID } from '../../utils/getQuestionByGUID';
import { ActionButtons } from '../ActionButtons/ActionButtons';

type AnswerRowProps = Readonly<{
  question: QuestionsTableRowItem;
  answer: Answer;
  onEdit: (answer: Answer) => void;
  isEditMode: boolean;
}>;

export const AnswerRow: FC<AnswerRowProps> = ({ question, answer, isEditMode, onEdit }: AnswerRowProps): ReactElement => {
  const { state, dispatch } = useQuestionsContext();
  const { pathname } = useLocation();

  const { open, close, isOpen } = useModalState();

  const onConfirmDeleteClick = () => {
    dispatch(deleteAnswer({ answerId: answer.id }));
    close();
  };

  const nestedQuestions = answer.requiredQuestions?.length ? answer.requiredQuestions : answer.nestedQuestions;

  return (
    <DraggableList.Item
      key={answer.id}
      className="answers-grid bg-gray-100 hover:bg-gray-200 transition-colors duration-75 p-2 border-b border-gray-300"
      as="div"
      dragControl
      id={answer.id}
    >
      <div />
      <div className="flex items-center">{!isEditMode && <DragHandle />}</div>
      <div />
      <div className="w-full flex justify-left items-center" data-testid={`${answer.id}-answer-cell`}>
        {answer.value}
      </div>
      <div className="w-full flex flex-col justify-left items-center" data-testid={`${answer.id}-required-questions-cell`}>
        {nestedQuestions?.map((questionGUId) => (
          <Link
            className="text-blue-600 underline w-52 text-ellipsis overflow-hidden whitespace-nowrap"
            key={questionGUId}
            to={`${pathname}#${questionGUId}`}
          >
            {getQuestionByGUID(state.questions, questionGUId)?.text || ''}
          </Link>
        ))}
      </div>
      {answer.answerGroups ? (
        <div className=" w-full flex justify-left items-center" data-testid={`${answer.id}-answer-group-cell`}>
          Answer Group {answer.answerGroups.join(', ')}
        </div>
      ) : (
        <div data-testid="empty-answer-group" />
      )}
      <div className="w-full flex justify-left items-center justify-center" data-testid={`${answer.id}-actions-cell`}>
        <ActionButtons onEdit={() => onEdit(answer)} omitBtn="copy" onRemove={open} />
      </div>

      <DialogModal onClose={close} onSubmitButton={onConfirmDeleteClick} isOpen={isOpen}>
        <p className="font-bold text-blue-800 p-5">
          Delete Answer from "{question.text}": "{answer.value}"?
        </p>
      </DialogModal>
    </DraggableList.Item>
  );
};
