import type { CreateEditAnswer } from '../../utils/schema';
import type { FC, ReactElement } from 'react';
import type { Control } from 'react-hook-form/dist/types';

import { ExpandableFormSection } from '../../../../components/ExpandableFormSection/ExpandableFormSection';
import { FormControl } from '../../../../modules/form/components/FormControl/FormControl';

type FreeFormTextSectionProps = Readonly<{
  control: Control<CreateEditAnswer>;
  isSubmitting: boolean;
}>;

export const FreeFormTextSection: FC<FreeFormTextSectionProps> = ({ control, isSubmitting }: FreeFormTextSectionProps): ReactElement => {
  return (
    <ExpandableFormSection title="Freeform Text">
      <FormControl
        control={control}
        type="textarea"
        name="notesLabel"
        placeholder=""
        label="Allow user input text"
        showErrorMessage={true}
        isSubmitting={isSubmitting}
        className="mt-2"
        controlClassName="flex flex-col w-full"
        required={false}
      />
    </ExpandableFormSection>
  );
};
