import type { OptionType } from '../../../common/types';

export const getSelectValue = (
  options: OptionType[] | undefined,
  fieldValue: string | Record<string, string | boolean> | undefined,
  isFetching?: boolean,
): OptionType | null => {
  const valueFromOptions = options?.find((option) => option.value === fieldValue);

  if (valueFromOptions) {
    return valueFromOptions;
  }

  if (fieldValue && !(typeof fieldValue === 'object')) {
    return {
      value: fieldValue,
      label: fieldValue,
    };
  }

  if (isFetching) {
    return null;
  }

  return null;
};
